import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";

import { AccessControlService } from "../../services/access-control.service";

@Directive({
	selector: "[afAccessControl]"
})
export class AccessControlDirective implements OnInit {
	@Input() afAccessControl: {
		modules: string;
		settings: string;
		components: string;
		fields: string;
		permission: string;
		roles: string;
	};

	constructor(
		private accessControlService: AccessControlService,
		private authService: AuthService,
		private viewContainerRef: ViewContainerRef,
		private templateRef: TemplateRef<any>
	) {}

	async ngOnInit() {
		this.hasAccess(Object.keys(this.afAccessControl)[0]);
	}

	// if it has access to module / component
	async hasAccess(type: string) {
		if (await this.accessControlService.checkAccess(type, this.afAccessControl[type], this.afAccessControl.permission))
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		else this.viewContainerRef.clear();
	}
}
