import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
    ContactCommunicationField,
    IContactCommunicationDto,
    IContactCommunicationResponse
} from "../models/contact-communication.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class ContactCommunicationApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addContactCommunication(contactCommunication: IContactCommunicationDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/contactcommunication`, contactCommunication);
    }

    editContactCommunication(contactCommunication: IContactCommunicationDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/contactcommunication/${contactCommunication.contact_communication_id}`, contactCommunication);
    }

    getContactCommunication(contactCommunicationId: string, fields: ContactCommunicationField[] = []): Observable<any> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<any>(`${this.environmentService.apiUrl}/contactcommunication/${contactCommunicationId}`, { params: httpParams });
    }

    getContactCommunications(page = 1, fields: ContactCommunicationField[] = [], filter: string[] = [], sort: string[] = []): Observable<IContactCommunicationResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        if (filter.length) 
            httpParams = httpParams.set("filter", filter.join(","));
        
        if (sort.length) 
            httpParams = httpParams.set("sort", sort.join(","));
        

        return this.httpClient.get<any>(`${this.environmentService.apiUrl}/contactcommunication`, { params: httpParams });
    }

    removeContactCommunication(contactCommunicationId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/contactcommunication/${contactCommunicationId}`);
    }
}
