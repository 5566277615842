import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";

import { IOrdersToEmployeeDto, IOrdersToEmployeeResponse, OrdersToEmployeeField } from "../models/orders-to-employee.model";
import { EnvironmentService } from "../services/environment.service";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root"
})
export class OrdersToEmployeeApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService, private httpClient: HttpClient, private domSanitizer: DomSanitizer) {}

    getOrdersToEmployee(page: number, fields: OrdersToEmployeeField[], filter?: string, sort?: string): Observable<IOrdersToEmployeeResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        

        return this.httpClient.get<IOrdersToEmployeeResponse>(`${this.environmentService.apiUrl}/orderstoemployee`, { params: httpParams });
    }

    getOrdersToEmployeByOrderId(fields: OrdersToEmployeeField[], filter?: string): Observable<IOrdersToEmployeeResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        return this.httpClient.get<IOrdersToEmployeeResponse>(`${this.environmentService.apiUrl}/orderstoemployee`, { params: httpParams });
    }

    addOrderToEmployee(ordersToEmployee: IOrdersToEmployeeDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/orderstoemployee`, ordersToEmployee);
    }

    deleteOrderToEmployee(ordersToEmployeeId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/orderstoemployee/${ordersToEmployeeId}`);
    }
}
