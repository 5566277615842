import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import * as moment from "moment";
import { ArticleQueryService } from "src/app/articles/shared/article-query.service";
import { ContactQueryService } from "src/app/contacts/shared/contact-query.service";
import { OrganizationApiService } from "src/app/core/api/organization-api.service";
import { ArticleFilterType } from "src/app/core/models/article.model";
import { ContactFilterType } from "src/app/core/models/contact.model";
import { OrganizationField } from "src/app/core/models/organization.model";
import { EmployeeFilterType } from "src/app/core/models/employee.model";
import { VehicleFilterType } from "src/app/core/models/vehicle.model";
import { WorkorderFilterType } from "src/app/core/models/workorder.model";
import { StorageService } from "src/app/core/services/storage.service";
import { EmployeeQueryService } from "src/app/employee/shared/employee-query.service";
import { VehicleQueryService } from "src/app/vehicles/shared/vehicle-query.service";
import { WorkorderQueryService } from "src/app/workorders/shared/workorder-query.service";
import { NotificationQueryService } from "src/app/core/services/notification-query.service";
import { NotificationFilterReadType, NotificationFilterType } from "src/app/core/models/notification.model";

@Component({
	selector: "af-filter-search",
	templateUrl: "./filter-search.component.html",
	styleUrls: ["./filter-search.component.scss"]
})
export class FilterSearchComponent implements OnInit, OnChanges {
	@Input() query: any;
	@Input() module: string;
	settings: any;
	@Input() showSearch = true;

	queryService: any;

	filterChip = [];

	searchValue: string;

	constructor(private injector: Injector, private storageService: StorageService, private organizationApiService: OrganizationApiService) {}

	async ngOnInit() {
		if (this.module === "workorder") this.queryService = this.injector.get(WorkorderQueryService);
		else if (this.module === "contact") this.queryService = this.injector.get(ContactQueryService);
		else if (this.module === "article") this.queryService = this.injector.get(ArticleQueryService);
		else if (this.module === "vehicle") this.queryService = this.injector.get(VehicleQueryService);
		else if (this.module === "notification") this.queryService = this.injector.get(NotificationQueryService);
		else if (this.module === "employee") this.queryService = this.injector.get(EmployeeQueryService);

		await this.queryService.init();
		this.searchValue = await this.getSearchStorage();
		const filterswitchcase = await this.filterSwitchCase(this.query, this.module);
		this.filterChip = filterswitchcase.length > 0 ? filterswitchcase : null;
		await this.getQuery();
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes) {
			if (changes.query.currentValue) {
				this.query = changes.query.currentValue;
				if (this.module !== "workorder" && this.query.filter !== WorkorderFilterType.All) 
					this.showSearch = true;
				 else if(this.module === "workorder"){
					switch (this.query.filter) {
						case 1:
							this.showSearch = false;
							break;
						case 2:
							this.showSearch = false;
							break;
						case 3:
							this.showSearch = false;
							break;
						case 5:
							this.showSearch = false;
							break;
					}
				}
				const filterswitchcase = await this.filterSwitchCase(this.query, this.module);
				this.filterChip = filterswitchcase.length > 0 ? filterswitchcase : null;
				await this.setSearchStorage(this.query.search);
				await this.getQuery();
			}
		}
	}

	async getQuery() {
		switch (this.module) {
			case "workorder":
				this.query = await this.storageService.get("settings.workorders.query");
				break;
			case "contact":
				this.query = await this.storageService.get("settings.contacts.query");
				break;
			case "article":
				this.query = await this.storageService.get("settings.articles.query");
				break;
			case "vehicle":
				this.query = await this.storageService.get("settings.vehicles.query");
				break;
			case "employee":
				this.query = await this.storageService.get("settings.employees.query");
				break;
		}
	}

	/**
	 * Get a list of filters to display as "filterChips"
	 */
	async filterSwitchCase(query: any, module: string, organization?: string): Promise<[]> {
		switch (module) {
			case "workorder":
				return await this.workorderSwitchCase(query.filter, query.organization ? query.organization : null);
			case "article":
				return await this.articleSwitchCase(query.filter, query.organization ? query.organization : null);
			case "contact":
				return await this.contactSwitchCase(query.filter, query.organization ? query.organization : null);
			case "vehicle":
				return await this.vehicleSwitchCase(query.filter, query.organization ? query.organization : null);
			case "notification":
				return await this.notificationSwitchCase(query.filter, query.filterRead, query.organization ? query.organization : null)
			case "employee":
				return await this.employeeSwitchCase(query.filter, query.organization ? query.organization : null);
			default:
				return [];
		}
	}

	async articleSwitchCase(filter: number, organization: string): Promise<[]> {
		const result = [];
		if (organization) {
			const foundOrganization = await this.organizationApiService
				.getOrganization(organization, [OrganizationField.OrganizationId, OrganizationField.Name])
				.toPromise();
			if (foundOrganization.data.length > 0 && foundOrganization.data[0].name) result.push({ key: "organization", value: foundOrganization.data[0].name });
		}
		switch (filter) {
			case ArticleFilterType.All:
				break;
			case ArticleFilterType.LastUsed:
				result.push({ key: "filter", value: "Laatst gebruikt" });
				break;
			case ArticleFilterType.Handlings:
				result.push({ key: "filter", value: "Verrichtingen" });
				break;
			case ArticleFilterType.Archived:
				result.push({ key: "filter", value: "Gearchiveerd" });
				break;
			case ArticleFilterType.Blocked:
				result.push({ key: "filter", value: "Geblokkeerd" });
				break;
			default:
				break;
		}
		return result as [];
	}

	async contactSwitchCase(filter: number, organization: string): Promise<[]> {
		const result = [];
		if (organization) {
			const foundOrganization = await this.organizationApiService
				.getOrganization(organization, [OrganizationField.OrganizationId, OrganizationField.Name])
				.toPromise();
			if (foundOrganization.data.length > 0 && foundOrganization.data[0].name) result.push({ key: "organization", value: foundOrganization.data[0].name });
		}
		switch (filter) {
			case ContactFilterType.All:
				break;
			case ContactFilterType.Blocked:
				result.push({ key: "filter", value: "Geblokkeerd" });
				break;
			case ContactFilterType.Archived:
				result.push({ key: "filter", value: "Gearchiveerd" });
				break;
			case ContactFilterType.Company:
				result.push({ key: "filter", value: "Bedrijven" });
				break;
			case ContactFilterType.PrivateCustomer:
				result.push({ key: "filter", value: "Particulieren" });
				break;
			case ContactFilterType.Provider:
				result.push({ key: "filter", value: "Leveranciers" });
				break;
			case ContactFilterType.LastUsed:
				result.push({ key: "filter", value: "Laatst gebruikt" });
				break;

			default:
				break;
		}
		return result as [];
	}

	async vehicleSwitchCase(filter: number, organization: string): Promise<[]> {
		const result = [];
		if (organization) {
			const foundOrganization = await this.organizationApiService
				.getOrganization(organization, [OrganizationField.OrganizationId, OrganizationField.Name])
				.toPromise();
			if (foundOrganization.data.length > 0 && foundOrganization.data[0].name) result.push({ key: "organization", value: foundOrganization.data[0].name });
		}

		switch (filter) {
			case VehicleFilterType.All:
				break;
			case VehicleFilterType.Occasion:
				result.push({ key: "filter", value: "Occasion" });
				break;
			case VehicleFilterType.Published:
				result.push({ key: "filter", value: "Gepubliceerd" });
				break;
			case VehicleFilterType.CompanyStock:
				result.push({ key: "filter", value: "Bedrijfsvoorraad" });
				break;
			case VehicleFilterType.LastUsed:
				result.push({ key: "filter", value: "Laatst gebruikt" });
				break;
			case VehicleFilterType.IsBlocked:
				result.push({ key: "filter", value: "Geblokkeerd" });
				break;
			case VehicleFilterType.IsArchived:
				result.push({ key: "filter", value: "Gearchiveerd" });
				break;
			default:
				break;
		}

		return result as [];
	}

	async notificationSwitchCase(filter: NotificationFilterType, filterRead: NotificationFilterReadType, organization: string): Promise<[]> {
		const result = [];
		if (organization) {
			const foundOrganization = await this.organizationApiService
				.getOrganization(organization, [OrganizationField.OrganizationId, OrganizationField.Name])
				.toPromise();
			if (foundOrganization.data.length > 0 && foundOrganization.data[0].name) result.push({ key: "organization", value: foundOrganization.data[0].name });
		}

		switch (filter) {
			case NotificationFilterType.All:
				break;
			case NotificationFilterType.Custom:
				result.push({ key: "notificationFilter", value: "Custom" });
				break;
			case NotificationFilterType.Helpdesk:
				result.push({ key: "notificationFilter", value: "Helpdesk" });
				break;
			case NotificationFilterType.Orders:
				result.push({ key: "notificationFilter", value: "Orders" });
				break;
			case NotificationFilterType.RepairAdvices:
				result.push({ key: "notificationFilter", value: "Repareer advies" });
				break;
			case NotificationFilterType.System:
				result.push({ key: "notificationFilter", value: "Systeem" });
				break;
			case NotificationFilterType.Users:
				result.push({ key: "notificationFilter", value: "Gebruikers" });
				break;
			default:
				break;
		}

		switch (filterRead) {
			case NotificationFilterReadType.All:
				break;
			case NotificationFilterReadType.Read:
				result.push({ key: "notificationFilterRead", value: "Gelezen" });
				break;
			case NotificationFilterReadType.Unread:
				result.push({ key: "notificationFilterRead", value: "Ongelezen" });
				break;
			default:
				break;
		}

		return result as [];
	}

	async workorderSwitchCase(filter: number, organization: string): Promise<[]> {
		const result = [];
		if (organization) {
			const foundOrganization = await this.organizationApiService
				.getOrganization(organization, [OrganizationField.OrganizationId, OrganizationField.Name])
				.toPromise();
			if (foundOrganization.data.length > 0 && foundOrganization.data[0].name) result.push({ key: "organization", value: foundOrganization.data[0].name });
		}

		switch (filter) {
			case WorkorderFilterType.All:
				break;
			case WorkorderFilterType.Yesterday:
				result.push({ key: "filter", value: "Gisteren" });
				break;
			case WorkorderFilterType.Today:
				result.push({ key: "filter", value: "Vandaag" });
				break;
			case WorkorderFilterType.Tomorrow:
				result.push({ key: "filter", value: "Morgen" });
				break;
			case WorkorderFilterType.ThisWeek:
				result.push({ key: "filter", value: "Deze week" });
				break;
			case WorkorderFilterType.Date:
				const date = moment(moment(this.query.filterDate).toISOString()).locale("nl").format("dd, DD-MM-YYYY");
				result.push({ key: "filterDate", value: date.charAt(0).toUpperCase() + date.substr(1) });
				break;
			default:
				break;
		}
		return result as [];
	}

	async employeeSwitchCase(filter: number, organization: string): Promise<[]> {
		const result = [];
		if (organization) {
			const foundOrganization = await this.organizationApiService
				.getOrganization(organization, [OrganizationField.OrganizationId, OrganizationField.Name])
				.toPromise();
			if (foundOrganization.data.length > 0 && foundOrganization.data[0].name) result.push({ key: "organization", value: foundOrganization.data[0].name });
		}

		switch (filter) {
			case EmployeeFilterType.All:
				break;
			default:
				break;
		}

		return result as [];
	}

	async clearFilter(value: any) {
		const query = this.query;
		if (value === "filter") query.filter = 0;

		if (value === "organization") query.organization = "";

		if (value === "filterDate") {
			query.filterDate = null;
			query.filter = 0;
		}

		if (value === "notificationFilter")
			query.filter = NotificationFilterType.All
		if (value === "notificationFilterRead")
			query.filterRead = NotificationFilterReadType.All

		query.page = 1;
		const filterswitchcase = await this.filterSwitchCase(this.query, this.module);
		this.filterChip = filterswitchcase.length > 0 ? filterswitchcase : null;
		this.queryService.setQuery(query);
		this.settings = await this.getQuery();
	}

	search(event) {
		this.queryService.setQuery({
			...this.query,
			page: 1,
			search: event.target.value
		});
	}

	setSearchStorage(searchValue: string) {
		return this.storageService.set(this.module + ".searchValue", searchValue);
	}

	getSearchStorage() {
		return this.storageService.get(this.module + ".searchValue");
	}
}
