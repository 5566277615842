import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Camera, CameraResultType } from "@capacitor/camera";
import { Device } from "@capacitor/device";
import { ModalController, Platform } from "@ionic/angular";
import { finalize } from "rxjs/operators";
import { AuthApiService } from "src/app/core/api/auth-api.service";
import { EmployeeApiService } from "src/app/core/api/employee-api.service";
import { SettingApiService } from "src/app/core/api/setting-api.service";
import { EmployeeField } from "src/app/core/models/employee.model";
import { SettingField } from "src/app/core/models/setting.model";

import { ServiceDeskApiService } from "../../../core/api/service-desk-api.service";
import { IFeedback } from "../../../core/models/service-desk.model";
import { EnvironmentService } from "../../../core/services/environment.service";
import { ToastService } from "../../../core/services/toast.service";

@Component({
	selector: "af-feedback-modal",
	templateUrl: "./feedback-modal.component.html"
})
export class FeedbackModalComponent implements OnInit {
	form: FormGroup;
	isSubmitting = false;
	activeEmployee: string;
	settingOrganization: string;
	deviceInfo: any;
	currentRoute: string;
	activeModules = "";
	@Input() screenshot: string;

	constructor(
		private environmentService: EnvironmentService,
		private modalController: ModalController,
		private serviceDeskApiService: ServiceDeskApiService,
		private toastService: ToastService,
		private employeeApiService: EmployeeApiService,
		private settingApiService: SettingApiService,
		private platform: Platform,
		private router: Router,
		private authApiService: AuthApiService
	) {}

	async ngOnInit() {
		this.form = new FormGroup({
			type: new FormControl("", Validators.required),
			subject: new FormControl("", Validators.required),
			body: new FormControl("", Validators.required)
		});
		this.getActiveEmployee();
		this.getOrganization();
		this.getActiveModules();
		this.deviceInfo = await Device.getInfo();

		this.currentRoute = this.router.url;
	}

	dismiss() {
		this.modalController.dismiss();
	}

	async addScreenshot() {
		try {
			const photo = await Camera.getPhoto({
				quality: 92,
				resultType: CameraResultType.DataUrl
			});
			this.screenshot = photo.dataUrl;
		} catch (error) {}
	}

	removeScreenshot() {
		this.screenshot = null;
	}

	getActiveEmployee() {
		this.employeeApiService.getActiveEmployee([EmployeeField.Fullname]).subscribe((res) => {
			this.activeEmployee = res.data[0].v_display_name;
		});
	}

	getOrganization() {
		this.settingApiService.getSetting([SettingField.NameOwnCompany]).subscribe((settingResponse) => {
			this.settingOrganization = settingResponse.data[0].name_own_company;
		});
	}

	getActiveModules() {
		let seperator = "";
		this.authApiService.getModules().subscribe((modules) => {
			modules.modules.data.forEach((module) => {
				this.activeModules += seperator + module.name;
				seperator = ", ";
			});
		});
	}

	submit() {
		if (this.form.invalid) return;

		this.isSubmitting = true;

		const feedback: IFeedback = {
			body:
				this.form.value.body +
				" \n-------------\nRouting: " +
				this.currentRoute +
				" \n-------------\nEmployee: " +
				this.activeEmployee +
				" | Organization:  " +
				this.settingOrganization +
				" | Modules: " +
				this.activeModules +
				" \n-------------\nPlatforms: " +
				this.platform.platforms().join(", ") +
				" | Device info: " +
				"Model: " +
				this.deviceInfo.model +
				" | Operating system: " +
				this.deviceInfo.operatingSystem +
				" | OS Version: " +
				this.deviceInfo.osVersion,
			environment: this.environmentService.environment,
			screenshot: this.screenshot ? this.screenshot.split(",")[1] : "",
			subject: "[APP] FEEDBACK " + this.form.value.subject,
			type: this.form.value.type,
			version: this.environmentService.version
		};

		this.serviceDeskApiService
			.addFeedback(feedback)
			.pipe(finalize(() => (this.isSubmitting = false)))
			.subscribe(
				() => {
					this.modalController.dismiss();
					this.toastService.presentSuccessToast("Feedback verzonden");
				},
				(error) => {
					this.toastService.presentFailureToast(error.error.errorMessage);
				}
			);
	}
}
