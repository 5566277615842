import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ITurnoverGroupResponse, ITurnoverGroupsResponse, TurnoverGroupField } from "../models/turnovergroup.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class TurnoverGroupApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getTurnoverGroup(id: string, fields: string[]): Observable<ITurnoverGroupResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<ITurnoverGroupResponse>(`${this.environmentService.apiUrl}/turnovergroup/${id}`, { params: httpParams });
    }

    getTurnoverGroups(page: number, fields: TurnoverGroupField[], filter?: string, sort?: string): Observable<ITurnoverGroupsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        

        return this.httpClient.get<ITurnoverGroupsResponse>(`${this.environmentService.apiUrl}/turnovergroup`, { params: httpParams });
    }
}
