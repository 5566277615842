import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ILookupResponse, ILookupsResponse, LookupField } from "../models/lookup.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class LookupApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getItems(page: number, fields: LookupField[], lookupValue: string, filter?: string, sort?: string, search?: string): Observable<ILookupsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (search) 
            httpParams = httpParams.set("search", search);
        
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        return this.httpClient.get<ILookupsResponse>(`${this.environmentService.apiUrl}/${lookupValue}`, { params: httpParams });
    }

    getItem(id: string, fields: string[], lookupValue: string): Observable<ILookupResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<ILookupResponse>(`${this.environmentService.apiUrl}/${lookupValue}/${id}`, { params: httpParams });
    }
}
