import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IModelResponse, ModelType } from "../models/model.model";
import { EnvironmentService } from "../services/environment.service";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root"
})
export class UtilApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getLists(listIds: string[]): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("list", listIds.join(","));

        return this.httpClient.get<any>(`${this.environmentService.apiUrl}/util/list`, { params: httpParams });
    }

    getModel(modelId: ModelType): Observable<IModelResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("model", modelId);

        return this.httpClient.get<IModelResponse>(`${this.environmentService.apiUrl}/util/model`, { params: httpParams });
    }

    getWiki(wikiId?: string): Observable<any> {
        let httpParams = new HttpParams();
        if (wikiId) 
            httpParams = httpParams.set("wikiid", wikiId);
        

        return this.httpClient.get<any>(`${this.apiService.apiUrl}/util/wiki`, { params: httpParams });
    }
}
