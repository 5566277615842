import { Component, Input, OnInit } from "@angular/core";
import { UserApiService } from "src/app/core/api/user-api.service";
import { IOrganizationDto, IUserOrganizationDto } from "src/app/core/models/organization.model";
import { IUserDto } from "src/app/core/models/user.model";
import { AuthService } from "src/app/core/services/auth.service";
import { LoadingService } from "src/app/core/services/loading.service";
import { ActionSheetService } from "../action-sheet/action-sheet.service";

@Component({
	selector: "af-organization-action-sheet",
	templateUrl: "./organization-action-sheet.component.html"
})
export class OrganizationActionSheetComponent implements OnInit {
	@Input() userOrganizationIds: IUserOrganizationDto[];
	@Input() organizations: IOrganizationDto[];
	@Input() currentOrganization: IOrganizationDto;
	userId: string;

	constructor(
		private userApiService: UserApiService,
		private authService: AuthService,
		private actionSheetService: ActionSheetService,
		private loadingService: LoadingService
	) {}

	async ngOnInit() {}

	relatedOrganizationData(organizationId: string): string {
		return this.organizations.find((x) => x.organization_id === organizationId).name;
	}

	async selectOrganization(selectedOrganizationId: string) {
		const user: IUserDto = {
			last_used_organization_id: selectedOrganizationId
		};
		this.loadingService.present("Een moment...").then(async () => {
			this.actionSheetService.dismiss("", "orgsheet");
			this.userId = await this.authService.getUserId();

			this.userApiService.editEmployee(this.userId, user).subscribe(async (res) => {
				const promises: Promise<void>[] = [this.authService.refreshTokenOrg(selectedOrganizationId)];
				await Promise.all(promises);
				// this.router.navigate(['/home']);
				await this.loadingService.dismiss();
			});
		});
	}
}
