<ion-app>
	<ion-split-pane contentId="main-content">
		<ion-menu *ngIf="isLoggedIn" contentId="main-content" type="overlay">
			<ion-header>
				<ion-toolbar color="primary" mode="md">
					<ion-title>Menu</ion-title>
				</ion-toolbar>
			</ion-header>
			<ion-content>
				<ion-menu-toggle auto-hide="false">
					<ion-list class="ion-no-margin ion-no-padding">
						<ion-item [routerLink]="['/home']" data-test="home-link" detail routerDirection="root">
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'home']" slot="start"></fa-icon>
							<ion-label>Home</ion-label>
						</ion-item>
						<ion-item
							*ngIf="this.activeEmployee && this.activeEmployee.is_hide_relations_in_app !== 1"
							[routerLink]="['/contacts']"
							data-test="contacts-link"
							detail
							routerDirection="root"
						>
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'users']" slot="start"></fa-icon>
							<ion-label>Relaties</ion-label>
						</ion-item>
						<ion-item [routerLink]="['/vehicles']" data-test="vehicles-link" detail routerDirection="root">
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'car']" slot="start"></fa-icon>
							<ion-label>Voertuigen</ion-label>
						</ion-item>
						<ion-item
							*afAccessControl="{ modules: 'workshop', permission: 'read' }"
							[routerLink]="['/workorders']"
							data-test="workorders-link"
							detail
							routerDirection="root"
						>
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'clipboard-list']" slot="start"></fa-icon>
							<ion-label>Werkorders</ion-label>
						</ion-item>
						<ion-item [routerLink]="['/articles']" data-test="articles-link" detail routerDirection="root">
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'dolly-flatbed']" slot="start"></fa-icon>
							<ion-label>Onderdelen</ion-label>
						</ion-item>
						<ion-item
							*afAccessControl="{ modules: 'workflow', permission: 'read' }"
							[routerLink]="['/workflow']"
							data-test="workflow-link"
							detail
							routerDirection="root"
						>
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'table']" slot="start"></fa-icon>
							<ion-label>Workflow</ion-label>
						</ion-item>
						<ion-item
							*afAccessControl="{ modules: 'workflow', permission: 'read' }"
							[routerLink]="['/timesheet']"
							data-test="timesheet-link"
							detail
							routerDirection="root"
						>
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'clock']" slot="start"></fa-icon>
							<ion-label>Klokken</ion-label>
						</ion-item>
						<!-- NOTIFICATION FUNCTIONALITY -->
						<span *ngIf="ionicEnvironment.notificationsEnabled">
							<ion-item
								*afAccessControl="{ modules: 'workflow', permission: 'read' }"
								[routerLink]="['/notification']"
								data-test="notification-link"
								detail
								routerDirection="root"
							>
								<fa-icon [fixedWidth]="true" [icon]="['fal', 'bell']" slot="start"></fa-icon>
								<ion-label> Berichten </ion-label>
								<af-notification-badge></af-notification-badge>
							</ion-item>
						</span>
					</ion-list>
				</ion-menu-toggle>
			</ion-content>
			<ion-footer>
				<ion-menu-toggle auto-hide="false">
					<ion-list class="ion-no-margin ion-no-padding">
						<ion-item (click)="presentOrganizationModal()" button data-test="feedback-button">
							<fa-icon [fixedWidth]="true" [icon]="['far', 'car-building']" slot="start"></fa-icon>
							<ion-label>Vestiging: {{ this.currentOrganizationName }}</ion-label>
						</ion-item>

						<ion-item (click)="presentHelpModal()" button data-test="help-link">
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'question-circle']" slot="start"></fa-icon>
							<ion-label>Help</ion-label>
						</ion-item>
						<ion-item [routerLink]="['/settings']" data-test="settings-link" routerDirection="root">
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'cog']" slot="start"></fa-icon>
							<ion-label>Instellingen</ion-label>
						</ion-item>
						<ion-item (click)="presentLogoutAlert()" button data-test="logout-button">
							<fa-icon [fixedWidth]="true" [icon]="['fal', 'sign-out']" slot="start"></fa-icon>
							<ion-label>Uitloggen</ion-label>
						</ion-item>
						<span class="profile-menu-item">
							<div *ngIf="showProfile" style="width: 60px" class="profile-menu-avatar">
								<af-avatar [employeeId]="profile[0].employee_id"></af-avatar>
							</div>
							<div *ngIf="!showProfile" #other_profile>
								<fa-icon [fixedWidth]="true" [icon]="['fal', 'address-card']" slot="start"></fa-icon>
							</div>

							<ion-item [routerLink]="['/profile']" data-test="profile-link" routerDirection="root">
								<div style="display: contents">
									<span *ngIf="showProfile">
										<ion-label>
											{{ profile[0].v_display_name }}
											<p class="profile-menu-role dark-mode-subtext">{{ this.activeRole }}</p>
										</ion-label>
									</span>
									<span *ngIf="!showProfile">
										<ion-label> Profiel </ion-label>
									</span>
								</div>
							</ion-item>
						</span>
					</ion-list>
				</ion-menu-toggle>
			</ion-footer>
		</ion-menu>

		<ion-router-outlet id="main-content"></ion-router-outlet>
	</ion-split-pane>
</ion-app>
