import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";

import { IWorkorderQuery, WorkorderField, WorkorderFilterType, WorkorderOrderType, WorkorderSortType } from "../../core/models/workorder.model";
import { StorageService } from "../../core/services/storage.service";

@Injectable()
export class WorkorderQueryService {
	queryChanges$ = new Subject<IWorkorderQuery>();

	initialQuery: IWorkorderQuery = {
		filter: WorkorderFilterType.Today,
		page: 1,
		order: WorkorderOrderType.Ascending,
		search: "",
		sort: WorkorderSortType.PlanningStartDate,
		filterDate: moment().toISOString(),
		organization: ""
	};
	private query: IWorkorderQuery;

	constructor(private storageService: StorageService) {}

	getFilter(): string {
		const filter = [];
		filter.push("order_type[eq]:1");
		filter.push("(is_collect[eq]:0,-is_collect[eq]:null)");
		filter.push("(is_ci_printed[eq]:0,-is_ci_printed[eq]:null)");
		if (this.query.filter === WorkorderFilterType.All) filter.push('is_invoiced[eq]:0');
		else if (this.query.filter === WorkorderFilterType.Yesterday) {
			filter.push(
				`planning_start_date[le]:"${moment().utc(true).subtract(1, "days").endOf("day").toISOString()}"`,
				`planning_ready_date[ge]:"${moment().utc(true).subtract(1, "days").startOf("day").toISOString()}",is_invoiced[eq]:0`
			);
		} else if (this.query.filter === WorkorderFilterType.Today) {
			filter.push(
				`planning_start_date[le]:"${moment().utc(true).add(0, "days").endOf("day").toISOString()}"`,
				`planning_ready_date[ge]:"${moment().utc(true).add(0, "days").startOf("day").toISOString()}",is_invoiced[eq]:0`
			);
		} else if (this.query.filter === WorkorderFilterType.Tomorrow) {
			filter.push(
				`planning_start_date[le]:"${moment().utc(true).add(1, "days").endOf("day").toISOString()}"`,
				`planning_ready_date[ge]:"${moment().utc(true).add(1, "days").startOf("day").toISOString()}",is_invoiced[eq]:0`
			);
		} else if (this.query.filter === WorkorderFilterType.ThisWeek) {
			filter.push(
				`planning_start_date[le]:"${moment().utc(true).endOf("week").toISOString()}"`,
				`planning_ready_date[ge]:"${moment().utc(true).startOf("week").toISOString()}",is_invoiced[eq]:0`
			);
		} else if (this.query.filter === WorkorderFilterType.Date) {
			filter.push(
				`planning_start_date[le]:"${moment(this.query.filterDate).utc(true).endOf("day").toISOString()}"`,
				`planning_ready_date[ge]:"${moment(this.query.filterDate).utc(true).startOf("day").toISOString()}",is_invoiced[eq]:0`
			);
		}

		if (this.query.organization) filter.push(`organization_id[eq]:${this.query.organization}`);

		return filter.join(",");
	}

	getSort(): string {
		const order = this.query.order === WorkorderOrderType.Ascending ? "" : "-";
		if (this.query.sort === WorkorderSortType.PlanningStartDate) return `${order}${WorkorderField.PlanningStartDate},${order}${WorkorderField.OrderNumber}`;

		if (this.query.sort === WorkorderSortType.OrderNumber) return `${order}${WorkorderField.OrderNumber}`;

		return `${order}${WorkorderField.OrderNumber}`;
	}

	getSearch(): string {
		return this.query.search;
	}

	async init() {
		const query = this.initialQuery;
		if (query) {
			this.query = {
				filter: query.filter,
				page: this.initialQuery.page,
				order: query.order,
				search: this.initialQuery.search,
				sort: query.sort,
				filterDate: moment(query.filterDate).toISOString(),
				organization: query.organization
			};
			this.queryChanges$.next(this.query);
		} else this.setQuery(this.initialQuery);
	}

	setQuery(query: IWorkorderQuery) {
		this.storageService.set("settings.workorders.query", {
			filter: query.filter,
			order: query.order,
			sort: query.sort,
			search: query.search,
			filterDate: moment(query.filterDate).toISOString(),
			organization: query.organization
		});

		this.query = query;
		this.queryChanges$.next(query);
		return query;
	}
}
