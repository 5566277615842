import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IVehicleRolesResponse, VehicleRoleField } from "../models/vehicle-role.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class VehicleRoleApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getVehicleRoles(page: number, fields: VehicleRoleField[], filter?: string, sort?: string): Observable<IVehicleRolesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        

        return this.httpClient.get<IVehicleRolesResponse>(`${this.environmentService.apiUrl}/vehiclerole`, { params: httpParams });
    }
}
