import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { fromEvent, Observable, throwError } from "rxjs";
import { mapTo, retryWhen, switchMap } from "rxjs/operators";

@Injectable()
export class OfflineInterceptor {
    private onlineChanges$ = fromEvent(window, "online").pipe(mapTo(true));

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen((errors) => {
                if (navigator.onLine) 
                    return errors.pipe(switchMap((error) => throwError(error)));
                

                return this.onlineChanges$;
            })
        );
    }
}
