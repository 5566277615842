import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { UtilApiService } from "../api/util-api.service";
import { FieldType, IField, IGroup, IModel, IModelItem, IModelResponse, IModels, ModelType } from "../models/model.model";
import { AccessControlService } from "src/app/shared/services/access-control.service";

@Injectable({
	providedIn: "root"
})
export class ModelService {
	private cache: IModels = {};
	readOnly: boolean;
	postgres: boolean;

	constructor(private utilApiService: UtilApiService, private accessControlService: AccessControlService) {}

	getFieldIds(groups: IGroup[]): string[] {
		const fieldIds: string[] = [];
		groups.forEach((group: IGroup) => {
			group.fields.forEach(async (field: IField) => {
				if (field.fieldType && field.fieldType.startsWith("checkmoduleactive(")) {
					const module = field.fieldType.slice(field.fieldType.indexOf("(") + 1, field.fieldType.indexOf(")"));
					await this.accessControlService.checkAccess("modules", module, "read").then((result) => {
						if (result !== true) field.visible = false;
					});
				}
			});
			group.fields.forEach(async (field: IField) => {
				if (field.postgres) fieldIds.push(field.id);
			});
		});
		return fieldIds;
	}

	getCustomFields(model: IModel, filter: any): IField[] {
		const fields: IField[] = [];
		model.keys.forEach((modelItemKey: string) => {
			const modelItem = model.object[modelItemKey];
			if (modelItem.is_postgres === 0 && filter(modelItem)) fields.push(this.getField(model.object[modelItemKey]));
		});
		return fields;
	}

	getFieldsByName(model: IModel, fieldIds: string[]): IField[] {
		const fields: IField[] = [];
		model.keys.forEach((modelItemKey: string) => {
			const modelItem = model.object[modelItemKey];
			fieldIds.forEach((fieldId: string) => {
				if (modelItem.field === fieldId) fields.push(this.getField(model.object[modelItemKey]));
			});
		});
		return fields;
	}

	getFields(model: IModel, filter: any): IField[] {
		const fields: IField[] = [];
		model.keys.forEach((modelItemKey: string) => {
			const modelItem = model.object[modelItemKey];
			if (modelItem.is_app === 0 || !filter(modelItem)) return;

			fields.push(this.getField(model.object[modelItemKey]));
		});
		return fields;
	}

	getGroups(model: IModel, filter: any): IGroup[] {
		const groups: IGroup[] = [];
		model.keys.forEach(async (modelItemKey: string) => {
			const modelItem = model.object[modelItemKey];
			if (modelItem.is_app === 0 || !filter(modelItem)) return;

			let currentGroup = groups.find((group: IGroup) => group.name === modelItem.groupname);
			if (currentGroup === undefined) {
				currentGroup = {
					fields: [],
					name: modelItem.groupname,
					sort: modelItem.groupsort
				};
				const nextGroupIndex = groups.findIndex((group: IGroup) => group.sort > currentGroup.sort);
				if (nextGroupIndex === -1) groups.push(currentGroup);
				else groups.splice(nextGroupIndex, 0, currentGroup);
			}

			const currentField = this.getField(modelItem);
			const nextFieldIndex = currentGroup.fields.findIndex((field: IField) => field.sort > currentField.sort);
			if (nextFieldIndex === -1) currentGroup.fields.push(currentField);
			else currentGroup.fields.splice(nextFieldIndex, 0, currentField);
		});
		return groups;
	}

	getGroupsWithValues(groups: IGroup[], object: any): IGroup[] {
		return groups
			.filter((group: IGroup) => this.containsFieldWithValue(group.fields, object))
			.map((group: IGroup) => ({
				...group,
				fields: this.getFieldsWithValues(group.fields, object).map((field) => ({ ...field }))
			}));
	}

	getListIdsByFields(fields: IField[]): string[] {
		return fields.map((field: IField) => field.listId).filter((listId: string) => listId);
	}

	getListIdsByGroups(groups: IGroup[]): string[] {
		const listIds: string[] = [];
		groups.forEach((group: IGroup) => {
			group.fields.forEach((field: IField) => {
				if (field.listId !== null && listIds.find((listId: string) => listId === field.listId) === undefined) listIds.push(field.listId);
			});
		});
		return listIds;
	}

	getModel(modelId: ModelType): Observable<IModel> {
		if (this.cache[modelId]) return of(this.cache[modelId]);

		return this.utilApiService.getModel(modelId).pipe(
			map((modelResponse: IModelResponse) => {
				this.cache[modelId] = this.normalizeModel(modelResponse);
				return this.cache[modelId];
			})
		);
	}

	private containsFieldWithValue(fields: IField[], object: any): boolean {
		return fields.find((field: IField) => object[field.id] !== undefined && object[field.id] !== null && object[field.id] !== "") !== undefined;
	}

	getField(modelItem: IModelItem): IField {
		return {
			displayValue: "",
			fieldType: modelItem.fieldtype,
			format: modelItem.format,
			id: modelItem.field,
			isRequired: modelItem.is_required === 1,
			length: modelItem.length,
			listId: this.getFieldListId(modelItem),
			lookupValue: this.getLookupValue(modelItem),
			multiply: modelItem.multiply,
			name: modelItem.label_alt ? modelItem.label_alt : modelItem.label,
			postfix: modelItem.postfix,
			prefix: modelItem.prefix,
			sort: modelItem.fieldsort,
			type: this.getFieldType(modelItem),
			readonly: this.getReadMode(modelItem),
			visible: true,
			postgres: modelItem.is_postgres ? true : false
		};
	}

	private getLookupValue(modelItem: IModelItem): string {
		if (modelItem.fieldtype !== undefined && modelItem.fieldtype.startsWith("lookup")) {
			const lookupValue = modelItem.fieldtype.slice(modelItem.fieldtype.indexOf(",") + 1, modelItem.fieldtype.indexOf("("));

			return lookupValue;
		}
		return null;
	}

	private getFieldListId(modelItem: IModelItem): string {
		if (modelItem.fieldtype !== undefined && (modelItem.fieldtype.startsWith("radio") || modelItem.fieldtype.startsWith("select"))) {
			let fieldListId;
			if (modelItem.fieldtype.endsWith("(readonly)")) fieldListId = modelItem.fieldtype.split(",")[1].replace("(readonly)", "");
			else fieldListId = modelItem.fieldtype.split(",")[1];

			if (fieldListId === "tax_type") return "taxtype";

			return fieldListId;
		}
		return null;
	}

	private getFieldType(modelItem: IModelItem): FieldType {
		let fieldtype;
		if (modelItem.fieldtype) {
			fieldtype = modelItem.fieldtype;

			if (fieldtype.endsWith("(readonly)")) fieldtype = fieldtype.replace("(readonly)", "");
			if (fieldtype && fieldtype.startsWith("checkmoduleactive(")) fieldtype = fieldtype.slice(fieldtype.indexOf("),") + 2);
		}

		if (modelItem.fieldtype === undefined || modelItem.fieldtype === null) {
			if (modelItem.datatype.endsWith("timestamp")) return FieldType.Date;

			if (modelItem.datatype.endsWith("int4")) return FieldType.Int4;

			if (modelItem.datatype.endsWith("float8")) return FieldType.Decimal;
		}
		if (fieldtype !== undefined) {
			if (fieldtype === "checkbox") return FieldType.Checkbox;

			if (fieldtype === "field_large") return FieldType.Textarea;

			if (fieldtype === "toggle") return FieldType.Toggle;

			if (fieldtype === "currency") return FieldType.Currency;

			if (fieldtype === "decimal") return FieldType.Decimal;

			if (fieldtype === "numeric") return FieldType.NumberField;

			if (fieldtype.startsWith("quantity") && modelItem.datatype.endsWith("float8")) return FieldType.QuantityFloat;

			if (fieldtype.startsWith("quantity") && modelItem.datatype.endsWith("int4")) return FieldType.QuantityInt;

			if (fieldtype.startsWith("radio")) return FieldType.Radio;

			if (fieldtype.startsWith("select")) return FieldType.Select;

			if (fieldtype.startsWith("lookup")) return FieldType.Lookup;

			if (fieldtype.startsWith("switch")) return FieldType.Switch;

			if (fieldtype === "date+time") return FieldType.Datetime;

			if (fieldtype === "time") return FieldType.Time;

			if (fieldtype === "date" || modelItem.datatype.endsWith("timestamp")) return FieldType.Date;

			if (fieldtype.startsWith("display")) return FieldType.Display;

			if (fieldtype.startsWith("input,")) return FieldType.Input;
		}
		return FieldType.Input;
	}

	getReadMode(modelItem: IModelItem) {
		if (modelItem.fieldtype) {
			if (modelItem.fieldtype.includes("readonly")) return true;
			else return false;
		}
	}
	// getIsPostgres(modelItem: IModelItem) {
	//     if (modelItem.is_postgres) {
	//         if (modelItem.is_postgres === 0) {
	//             return false;
	//         } else {
	//             return true;
	//         }
	//     }
	// }

	private getFieldsWithValues(fields: IField[], object: any): IField[] {
		return fields.filter((field: IField) => object[field.id] !== undefined && object[field.id] !== "");
	}

	private normalizeModel(modelResponse: IModelResponse): IModel {
		const modelAsObject = {};

		modelResponse.data.forEach((modelItem: IModelItem) => {
			modelAsObject[modelItem.field] = modelItem;
		});

		return {
			keys: Object.keys(modelAsObject),
			object: modelAsObject
		};
	}
}
