import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
    IAddVehicleRoleLineDto,
    IVehicleRoleLineResponse,
    IVehicleRoleLinesResponse,
    VehicleRoleLineField
} from "../models/vehicle-role-line.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class VehicleRoleLineApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addVehicleRoleLine(vehicleRoleLine: IAddVehicleRoleLineDto): Observable<any> {
        return this.httpClient.post<any>(`${this.environmentService.apiUrl}/vehicleroleline`, vehicleRoleLine);
    }

    getVehicleRoleLine(vehicleRoleLineId: string): Observable<IVehicleRoleLineResponse> {
        return this.httpClient.get<IVehicleRoleLineResponse>(`${this.environmentService.apiUrl}/vehicleroleline/${vehicleRoleLineId}`);
    }

    getVehicleRoleLines(page: number, fields: VehicleRoleLineField[], filter?: string, sort?: string): Observable<IVehicleRoleLinesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        

        return this.httpClient.get<IVehicleRoleLinesResponse>(`${this.environmentService.apiUrl}/vehicleroleline`, { params: httpParams });
    }

    removeVehicleRoleLine(vehicleRoleLineId: string): Observable<any> {
        return this.httpClient.delete<any>(`${this.environmentService.apiUrl}/vehicleroleline/${vehicleRoleLineId}`);
    }
}
