import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IUserDeviceAddRequest, IUserDeviceResponse, IUserDto, IUserRepsonse, UserDeviceField, UserField } from "../models/user.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class UserApiService {
    constructor(
        private environmentService: EnvironmentService,
        private httpClient: HttpClient
    ) {}

    getUser(id: string, fields: UserField[]): Observable<IUserRepsonse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IUserRepsonse>(`${this.environmentService.apiUrl}/user/${id}`, { params: httpParams });
    }

    editEmployee(id: string, user: IUserDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/user/${id}`, user);
    }

    getUserDeviceList(page: number, filter: string) {
        const userDeviceFiels: UserDeviceField[] = [
            UserDeviceField.Description,
            UserDeviceField.DeviceKey,
            UserDeviceField.DeviceType,
            UserDeviceField.DeviceUserAgent,
            UserDeviceField.UserId,
            UserDeviceField.UserDeviceId,
            UserDeviceField.PushToken
        ]

        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", userDeviceFiels.join(","));
        httpParams = httpParams.set("page", page + "");

        if (filter)
            httpParams = httpParams.set("filter", filter);

        return this.httpClient.get<IUserDeviceResponse>(`${this.environmentService.apiUrl}/userdevice`, { params: httpParams });
    }    

    /**
     * Insert a userDevice.
     * 
     * Does not check for an existing userDevice! Only one userDevice per user per device_key should exist.
     * Please check beforehand.
     */
    addUserDevice(userDevice: IUserDeviceAddRequest) {
        console.log("ADDING DEVICE:", userDevice)
        return this.httpClient.post(`${this.environmentService.apiUrl}/userdevice`, userDevice)
    }

    /**
     * Update an existing userDevice by user_device_id.
     */
    updateUserDevice(userDeviceId: string, userDevice: IUserDeviceAddRequest) {
        console.log("UPDATING DEVICE:", userDevice)
        return this.httpClient.put(`${this.environmentService.apiUrl}/userdevice/${userDeviceId}`, userDevice)
    }

    /**
     * Delete an existing userDevice by user_device_id.
     */
    deleteUserDevice(userDeviceId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/userdevice/${userDeviceId}`)
    }
}
