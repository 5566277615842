import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

@Injectable({
	providedIn: "root"
})
export class StorageService {
	constructor(private storage: Storage) {
		this.storage.create();
	}

	clearStorage() {
		if (this.storage) return this.storage.clear();
	}

	async get(keysAsString: string): Promise<any> {
		const keysAsArray = keysAsString.split(".");
		const value = await this.storage.get(keysAsArray[0]);
		if (value === null) return undefined;

		if (keysAsArray.length === 1) return value;

		return keysAsArray.slice(1).reduce((object, key) => object && object[key], value);
	}

	async removeOrgFromAllQuerys(name: string): Promise<any> {
		(await this.storage.keys()).forEach(async (type) => {
			if (type.startsWith(name)) {
				const settings = await this.storage.get(type);
				Object.entries(settings).forEach((setting: any) => {
					const settingString = type + "." + setting[0] + ".query";
					const query = setting[1].query;
					if (query.organization) query.organization = "";

					this.set(settingString, query);
				});
			}
		});
	}

	async remove(keysAsString: string) {
		const keysAsArray = keysAsString.split(".");
		if (keysAsArray.length === 1) await this.storage.remove(keysAsArray[0]);
		else {
			const object = await this.get(keysAsArray.slice(0, -1).join());
			if (object !== undefined) delete object[keysAsArray[keysAsArray.length - 1]];
		}
	}

	async set(keysAsString: string, value: any) {
		const keysAsArray = keysAsString.split(".");
		if (keysAsArray.length === 1) await this.storage.set(keysAsArray[0], value);
		else {
			const oldObject = await this.storage.get(keysAsArray[0]);
			const newObject = {};
			keysAsArray.slice(1).reduce((accumulator, currentValue, index, array) => {
				if (index === array.length - 1) return (accumulator[currentValue] = value);

				return (accumulator[currentValue] = {});
			}, newObject);
			await this.storage.set(keysAsArray[0], { ...oldObject, ...newObject });
		}
	}
}
