<div class="ion-margin-top">
    <ion-list-header color="medium">
        <ion-label>Uit te voeren door</ion-label>
        <div>
            <ion-button
                fill="solid"
                color="primary"
                size="default"
                *ngIf="usedInView === 'edit' && employees.length < 3"
                data-test="addcontact-button"
                (click)="addOrdersToEmployee()"
                button
                class="ion-margin-bottom"
            >
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'plus']" slot="icon-only"></fa-icon>
            </ion-button>
            <ion-button
                fill="solid"
                color="primary"
                size="default"
                *ngIf="usedInView === 'add' && selectedEmployees.length < 3"
                data-test="addcontact-button"
                (click)="addEmployeeWithoutOrderId()"
                button
                class="ion-margin-bottom"
            >
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'plus']" slot="icon-only"></fa-icon>
            </ion-button>
        </div>
    </ion-list-header>
    <div class="af-workorder-mechanic-item">
        <ion-list
            *ngIf="
                (usedInView === 'edit' && ordersToEmployee && ordersToEmployee.length === 0) ||
                (usedInView === 'add' && ordersToEmployee && ordersToEmployee.length === 0) ||
                (usedInView === 'detail' && ordersToEmployee && ordersToEmployee.length === 0)
            "
        >
            <ion-item lines="none"><ion-label>Geen medewerker gekoppeld aan deze werkorder</ion-label></ion-item>
        </ion-list>

        <!-- if add view this block is being used -->
        <div *ngIf="usedInView === 'add'">
            <ion-item *ngFor="let employee of selectedEmployees">
                <ion-item *ngIf="selectedEmployees.length > 0" class="item-wrapper ion-no-margin" lines="none">
                    <af-avatar class="avatar" *ngIf="employee.employee_id" [employeeId]="employee.employee_id"></af-avatar>
                    <ion-label> {{ employee.v_display_name }} </ion-label>
                </ion-item>
                <fa-icon
                    [fixedWidth]="true"
                    [icon]="['fal', 'trash-alt']"
                    class="trash-icon"
                    color="danger"
                    data-test="unlink-item"
                    (click)="deleteEmployeeWithoutOrderId(employee.employee_id)"
                ></fa-icon>
            </ion-item>
        </div>

        <!-- if edit view this block is beeing used -->
        <div *ngIf="usedInView === 'edit'">
            <ion-item *ngFor="let employee of employees">
                <ion-item *ngIf="employees.length > 0" class="item-wrapper ion-no-margin" lines="none">
                    <af-avatar class="avatar" *ngIf="employee.employee_id" [employeeId]="employee.employee_id"></af-avatar>
                    <ion-label> {{ employee.v_display_name }} </ion-label>
                </ion-item>
                <fa-icon
                    [fixedWidth]="true"
                    [icon]="['fal', 'trash-alt']"
                    class="trash-icon"
                    color="danger"
                    data-test="unlink-item"
                    (click)="deleteOrdersToEmployee(employee.employee_id)"
                ></fa-icon>
            </ion-item>
        </div>

        <!-- if detail view this block is beeing used -->
        <div *ngIf="usedInView === 'detail'">
            <ion-list *ngFor="let employee of employees" class="ion-no-padding ion-no-margin">
                <ion-item *ngIf="employees.length > 0" class="item-wrapper ion-no-margin" lines="full">
                    <af-avatar class="avatar" *ngIf="employee.employee_id" [employeeId]="employee.employee_id"></af-avatar>
                    <ion-label> {{ employee.v_display_name }} </ion-label>
                </ion-item>
            </ion-list>
        </div>
    </div>
</div>
