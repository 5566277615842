import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { EmployeeApiService } from "src/app/core/api/employee-api.service";
import { OrdersToEmployeeApiService } from "src/app/core/api/orders-to-employee-api.service";
import { WorkorderApiService } from "src/app/core/api/workorder-api.service";
import { EmployeeField, IEmployeeDto, IEmployeesResponse } from "src/app/core/models/employee.model";
import { ILists } from "src/app/core/models/list.model";
import { IOrdersToEmployeeDto, IOrdersToEmployeeResponse, OrdersToEmployeeField } from "src/app/core/models/orders-to-employee.model";
import { ToastService } from "src/app/core/services/toast.service";
import { EmployeeSelectMechanicModalComponent } from "src/app/shared/components/employee-select-mechanic-modal/employee-select-mechanic-modal.component";

import { IWorkorderDto, IWorkorderResponse } from "../../../core/models/workorder.model";

@Component({
	selector: "af-workorder-mechanic-item",
	templateUrl: "./workorder-mechanic-item.component.html",
	styleUrls: ["./workorder-mechanic-item.component.scss"]
})
export class WorkorderMechanicItemComponent implements OnInit {
	constructor(
		private ordersToEmployeeApiService: OrdersToEmployeeApiService,
		private employeeApiService: EmployeeApiService,
		private toastService: ToastService,
		private modalController: ModalController,
		private workorderApiService: WorkorderApiService
	) {}
	@Input() lists: ILists;
	@Input() workorder: IWorkorderDto;
	@Input() usedInView: string;
	@Input() selectedEmployees: IEmployeeDto[];
	@Output() selectedEmployeesChange = new EventEmitter<IEmployeeDto[]>();
	ordersToEmployee: IOrdersToEmployeeDto[];
	@Input() ordersToEmployeeDelay: IOrdersToEmployeeDto[] = [];

	private ordersToEmployeeFields: OrdersToEmployeeField[] = [
		OrdersToEmployeeField.EmployeeId,
		OrdersToEmployeeField.OdersToEmployeeId,
		OrdersToEmployeeField.OrderId
	];
	private employeeFields: EmployeeField[] = [
		EmployeeField.Emailaddress,
		EmployeeField.EmployeeId,
		EmployeeField.Fullname,
		EmployeeField.HourTypeIdEmployee,
		EmployeeField.IsShowMoreEmployeesInWork,
		EmployeeField.IsChiefWorkshop,
		EmployeeField.IsOwner,
		EmployeeField.NumberInPlanning
	];

	employees: IEmployeeDto[] = [];

	async ngOnInit() {
		if (this.workorder) await this.getOrdersToEmployees(this.workorder.order_id);
	}

	getEmployeesWithoutOrderId() {
		// zonder filter worden alle monteurs geladen, misschien later kijken naar een mooiere oplossing.
		const filterEmployees = ["-employee_id[eq]:1"];
		this.selectedEmployees.forEach((employee) => {
			filterEmployees.push(`-employee_id[eq]:"${employee}"`);
		});
		this.employeeApiService
			.getEmployees(1, this.employeeFields, filterEmployees.join(","), EmployeeField.NumberInPlanning)
			.subscribe((employeesResponse: IEmployeesResponse) => {
				this.employees = employeesResponse.data;
			});
	}

	async addEmployeeWithoutOrderId() {
		const modal = await this.modalController.create({
			component: EmployeeSelectMechanicModalComponent,
			componentProps: {
				selectedEmployees: this.selectedEmployees,
				usedInView: this.usedInView
			}
		});
		if (modal) {
			await modal.present();
			modal.onDidDismiss().then((returnData) => {
				const data: any = returnData.data;

				if (data) {
					this.selectedEmployees.push(data[0]);
					this.ordersToEmployeeDelay.push(data);
					this.getEmployeesWithoutOrderId();
				}
			});
		}
	}

	deleteEmployeeWithoutOrderId(employeeId: string) {
		const selectedEmpIndex = this.selectedEmployees.findIndex((emp) => emp.employee_id === employeeId);
		const empIndex = this.ordersToEmployeeDelay.findIndex((emp) => emp.employee_id === employeeId);
		this.selectedEmployees.splice(selectedEmpIndex, 1);
		this.ordersToEmployeeDelay.splice(empIndex, 1);
		this.getEmployeesWithoutOrderId();
		this.toastService.presentSuccessToast("Medewerker zal niet meer gekoppeld worden aan de werkorder");
	}

	async getOrdersToEmployees(orderId: string) {
		return this.ordersToEmployeeApiService
			.getOrdersToEmployee(1, this.ordersToEmployeeFields, `order_id[eq]:${orderId}`)
			.toPromise()
			.then(async (ordersToEmployeeResponse: IOrdersToEmployeeResponse) => {
				this.ordersToEmployee = ordersToEmployeeResponse.data;
				await this.getEmployees();
			});
	}

	async getEmployees() {
		const employeeIds = this.ordersToEmployee.map((orderToEmployee) => orderToEmployee.employee_id).join(";");

		if (employeeIds.length) {
			const filterString = `employee_id[in]:${employeeIds}`;

			await this.employeeApiService
				.getEmployees(1, this.employeeFields, filterString, EmployeeField.NumberInPlanning)
				.toPromise()
				.then((employeesResponse: IEmployeesResponse) => {
					this.employees = employeesResponse.data;
				});
		} else this.employees = [];
	}

	deleteOrdersToEmployee(employeeId: string, ordersToEmployee?: IOrdersToEmployeeDto[], employeesParent?: IEmployeeDto[]) {
		if (ordersToEmployee) this.ordersToEmployee = ordersToEmployee;

		if (employeesParent) this.employees = employeesParent;

		const foundOrdersToEmployee = this.ordersToEmployee.find(
			(orderToEmployee) => orderToEmployee.order_id === this.workorder.order_id && orderToEmployee.employee_id === employeeId
		);

		if (foundOrdersToEmployee) {
			this.ordersToEmployeeApiService.deleteOrderToEmployee(foundOrdersToEmployee.orders_to_employee_id).subscribe(
				() => {
					const indexOrdersToEmployee = this.ordersToEmployee.findIndex(
						(orderToEmployee) => orderToEmployee.order_id === this.workorder.order_id && orderToEmployee.employee_id === employeeId
					);
					const indexEmployees = this.employees.findIndex((employee) => employee.employee_id === employeeId);
					if (indexOrdersToEmployee >= 0) this.ordersToEmployee.splice(indexOrdersToEmployee, 1);

					if (indexEmployees >= 0) this.employees.splice(indexEmployees, 1);

					this.toastService.presentSuccessToast("Medewerker is niet meer gekoppeld aan de order");
				},
				(error) => {
					this.toastService.presentFailureToast(error.error.errorMessage);
				}
			);
		}
	}

	checkIfLastOrdersToEmployee() {
		if (this.ordersToEmployee.length < 1) {
			if (this.workorder.employee_id_assigned_to !== null) {
				this.workorder.employee_id_assigned_to = null;
				const workorder: IWorkorderDto = { order_id: this.workorder.order_id, employee_id_assigned_to: null };
				this.workorderApiService.editWorkorder(this.workorder.order_id, workorder).subscribe((workorderResponse: IWorkorderResponse) => {
					this.workorder = workorderResponse.data;
				});
			}
		}
	}

	async addOrdersToEmployee() {
		this.ordersToEmployee = (
			await this.ordersToEmployeeApiService.getOrdersToEmployeByOrderId(this.ordersToEmployeeFields, `order_id[eq]:"${this.workorder.order_id}"`).toPromise()
		).data;
		const modal = await this.modalController.create({
			component: EmployeeSelectMechanicModalComponent,
			componentProps: {
				workorder: this.workorder,
				orderId: this.workorder.order_id,
				deleteOrdersToEmployee: this.deleteOrdersToEmployee,
				ordersToEmployee: this.ordersToEmployee,
				selectedEmployees: this.ordersToEmployee,
				employeesParent: this.employees,
				usedInView: this.usedInView
			}
		});

		if (modal) {
			await modal.present();
			modal.onDidDismiss().then((returnData) => {
				const data = returnData.data as IOrdersToEmployeeDto;
				if (data) {
					this.ordersToEmployee.push(data);
					this.checkIfLastOrdersToEmployee();
					this.getEmployees();
				}
			});
		}
	}
}
