export interface IOrganizationResponse {
	currentPage: number;
	data: IOrganizationDto[];
	maxPages: number;
}

export interface IOrganizationDto {
	organization_id?: string;
	owner_id?: string;
	name?: string;
	modification_date?: string;
	is_example_administration?: boolean;
	is_demo_administration?: boolean;
	deletion_date?: string;
	admin_empty?: string;
}

export enum OrganizationField {
	OrganizationId = "organization_id",
	OwnerId = "owner_id",
	Name = "name",
	ModifactionDate = "modification_date",
	IsExampleAdmistration = "is_example_administration",
	IsDemoAdministration = "is_demo_administration",
	DeletionDate = "deletion_date",
	AdminEmpty = "admin_empty"
}

export interface IUserOrganizationResponse {
	currentPage: number;
	data: IUserOrganizationDto[];
	maxPages: number;
}

export interface IUserOrganizationDto {
	user_org_id?: string;
	user_id?: string;
	organization_id?: string;
	modification_date?: string;
	deletion_date?: string;
}

export enum UserOrganizationField {
	UserOrgId = "user_org_id",
	UserId = "user_id",
	OrganizationId = "organization_id"
}
