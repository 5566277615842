export enum EmployeeField {
	Emailaddress = "emailaddress",
	Fullname = "v_display_name",
	EmployeeId = "employee_id",
	OrganizationId = "organization_id",
	HourTypeIdEmployee = "hour_type_id_employee",
	IsStagiair = "is_intern",
	IsMechanic = "is_mechanic",
	IsChiefWorkshop = "is_chief_workshop",
	IsWareHousemanager = "is_warehouse_manager",
	IsSalesman = "is_salesman",
	IsApkInspector = "is_apk_inspector",
	IsPurchaser = "is_purchaser",
	IsAdministration = "is_administrator",
	IsOwner = "is_owner",
	CardNumber = "card_number",
	CardValidUntil = "card_valid_untill",
	IsShowMoreEmployeesInWork = "is_show_more_employees_in_work",
	IsChoiceHourCategoryReq = "is_choice_hour_category_req",
	isHelpdeskAccount = "is_helpdesk_account",
	isArchived = "is_archived",
	isBlocked = "is_blocked",
	isShowAllOrdersInApp = "is_show_all_orders_in_app",
	NumberInPlanning = "number_in_planning",
	SvyUserId = "svy_user_id",
	IsAccessToApp = "is_access_to_app",
	IsHideRelationsInApp = "is_hide_relations_in_app",
	Firstname = "firstname",
	Middlename = "middlename",
	Lastname = "lastname"
}

export interface IEmployeeEmailResponse {
	data: [
		{
			email: string;
			user_id: string;
			employee_id: string;
		}
	];
}

export enum EmployeeFilterType {
	All
}

export enum EmployeeOrderType {
	Ascending,
	Descending
}

export enum EmployeeSortType {
	Fullname,
	NumberInPlanning,
	isMechanic
}

export interface IEmployeeQuery {
	filter: EmployeeFilterType;
	page: number;
	order: EmployeeOrderType;
	search: string;
	sort: EmployeeSortType;
}

export interface IEmployeeDto {
	[EmployeeField.Emailaddress]?: string;
	[EmployeeField.Fullname]?: string;
	[EmployeeField.EmployeeId]?: string;
	[EmployeeField.HourTypeIdEmployee]?: string;
	[EmployeeField.IsStagiair]?: number;
	[EmployeeField.IsMechanic]?: number;
	[EmployeeField.IsChiefWorkshop]?: number;
	[EmployeeField.IsWareHousemanager]?: number;
	[EmployeeField.IsApkInspector]?: number;
	[EmployeeField.IsSalesman]?: number;
	[EmployeeField.IsPurchaser]?: number;
	[EmployeeField.IsAdministration]?: number;
	[EmployeeField.IsOwner]?: number;
	[EmployeeField.CardNumber]?: string;
	[EmployeeField.CardValidUntil]?: Date;
	[EmployeeField.isShowAllOrdersInApp]?: number;
	[EmployeeField.NumberInPlanning]?: number;
	[EmployeeField.SvyUserId]?: string;
	[EmployeeField.IsAccessToApp]?: number;
	[EmployeeField.IsHideRelationsInApp]?: number;
	[EmployeeField.isHelpdeskAccount]?: number;
	[key: string]: any;
}

export interface IEmployeeResponse {
	data: IEmployeeDto[];
}

export interface IEmployeesResponse {
	currentPage: number;
	data: IEmployeeDto[];
	maxPages: number;
}

export interface IEmployeeEmailResponse {
	data: [
		{
			email: string;
			user_id: string;
			employee_id: string;
		}
	];
}
