import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";

import { HeaderPopoverComponent } from "../header-popover/header-popover.component";
import { environment as ionicEnvironment } from "src/environments/environment";

@Component({
	selector: "af-header-popover-button",
	templateUrl: "./header-popover-button.component.html",
	styleUrls: ["./header-popover-button.component.scss"]
})
export class HeaderPopoverButtonComponent {
	ionicEnvironment = ionicEnvironment;
	@Input() componentName: string;
	@Input() disableNotificationButton: boolean;
	constructor(private popoverController: PopoverController) {}

	async presentHeaderPopover(event: Event) {
		const popover = await this.popoverController.create({
			component: HeaderPopoverComponent,
			componentProps: {
				componentName: this.componentName,
				disableNotificationButton: this.disableNotificationButton
			},
			event
		});
		await popover.present();
	}
}
