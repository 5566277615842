import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, forkJoin } from "rxjs";
import { isNil, get, omit } from "lodash-es"

import { EnvironmentService } from "../services/environment.service";
import {
    INotificationAddRequest,
    INotificationToUserAddRequest,
    INotificationToUserListResponse,
    NotificationField,
    NotificationToUserField,
    INotificationListResponse,
    INotificationByUserListResponse,
    NotificationByUserField,
    INotificationToUserUpdateRequest,
    INotificationByUserDto
} from "../models/notification.model"
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: "root"
})
export class NotificationApiService {
    public unreadNotificationCountObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0)

    constructor(
        private environmentService: EnvironmentService,
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        this.authService.getUserId().then(userId => {
            this.getUnreadNotifications(userId)
        });
    }

    addNotification(notification: INotificationAddRequest) {
        return this.httpClient.post<INotificationListResponse>(
            `${this.environmentService.apiUrl}/notification`,
            notification
        );
    }

    addNotificationToUser(notificationToUser: INotificationToUserAddRequest) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/notificationtouser`, {
            ...notificationToUser,
            [NotificationToUserField.IsRead]: 0
        });
    }

    /**
     * Refreshes the unreadNotificationCountObservable
     */
    async getUnreadNotifications(userId: string): Promise<INotificationByUserDto[]> {
        const notificationListResult = await this.getNotificationByUserList(userId, 1, [NotificationByUserField.NotificationId, NotificationByUserField.RmNotificationToUserId], "", "-created_date", false)
        const result = notificationListResult.data

        this.unreadNotificationCountObservable.next(result.length)
        return result
    }

    getNotification(id: string, fields: string[]): Promise<INotificationListResponse> {
        let httpParams = new HttpParams();
        if (fields.length) httpParams = httpParams.set("fields", fields.join(","));

        return new Promise<INotificationListResponse>((resolve) =>
            this.httpClient.get<INotificationListResponse>(`${this.environmentService.apiUrl}/notification/${id}`, { params: httpParams })
                .subscribe(result => {
                    resolve(result)
                })
        )
    }

    getNotificationList(page: number, fields: NotificationField[], filter?: string, sort?: string): Promise<INotificationListResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter)
            httpParams = httpParams.set("filter", filter);

        if (sort)
            httpParams = httpParams.set("sort", sort);

        return new Promise<INotificationListResponse>((resolve) =>
            this.httpClient.get<INotificationListResponse>(`${this.environmentService.apiUrl}/notification`, { params: httpParams })
                .subscribe(result => {
                    resolve(result)
                })
        )
    }

    getNotificationByUserList(userId: string, page: number, fields: NotificationByUserField[], filter?: string, sort?: string, isRead?: boolean): Promise<INotificationByUserListResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("user_id", userId)
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));

        if (!isNil(isRead))
            httpParams = httpParams.set("is_read", isRead ? 1 : 0)

        if (filter)
            httpParams = httpParams.set("filter", filter);

        if (sort)
            httpParams = httpParams.set("sort", sort);

        return new Promise<INotificationByUserListResponse>((resolve) =>
            this.httpClient.get<INotificationByUserListResponse>(`${this.environmentService.apiUrl}/notification/user`, { params: httpParams })
                .subscribe(result => {
                    resolve(result)
                })
        )
    }

    getNotificationToUserList(page: number, fields: NotificationToUserField[], filter?: string, sort?: string): Observable<INotificationToUserListResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter)
            httpParams = httpParams.set("filter", filter);

        if (sort)
            httpParams = httpParams.set("sort", sort);

        return this.httpClient.get<INotificationToUserListResponse>(`${this.environmentService.apiUrl}/notificationtouser`, { params: httpParams });
    }

    updateNotificationToUser(notificationToUserId: string, notificationToUser: INotificationToUserUpdateRequest) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/notificationtouser/${notificationToUserId}`, notificationToUser)
    }

    batchUpdateNotificationToUserIsRead(userId: string, notificationToUserIds: string[], isRead: boolean) {
        return new Promise((resolve => {
            if (notificationToUserIds.length < 1) {
                // Nothing to do
                return
            }

            const updateNotificationToUserMap = notificationToUserIds.map((notificationToUserId: string) =>
                this.updateNotificationToUser(notificationToUserId, { [NotificationToUserField.IsRead]: isRead ? 1 : 0 })
            )

            forkJoin(updateNotificationToUserMap).subscribe(() => {
                // Refresh the unread notification count
                // Necessary until we've implemented websockets
                this.getUnreadNotifications(userId)
            })

            resolve(undefined)
        }))
    }
}
