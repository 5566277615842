import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonContent, IonInfiniteScroll, ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { EmployeeApiService } from "src/app/core/api/employee-api.service";
import { OrdersToEmployeeApiService } from "src/app/core/api/orders-to-employee-api.service";
import { remove as removeDiacritics } from "diacritics";
import { filter as _filter } from "lodash-es";
import { EmployeeField, EmployeeOrderType, IEmployeeDto, IEmployeeQuery, IEmployeesResponse, EmployeeSortType } from "src/app/core/models/employee.model";
import { IOrdersToEmployeeDto, OrdersToEmployeeField } from "src/app/core/models/orders-to-employee.model";
import { IWorkorderDto } from "src/app/core/models/workorder.model";

import { ToastService } from "../../../core/services/toast.service";
import { EmployeeQueryService } from "../../../employee/shared/employee-query.service";
import { ITimesheetDto } from "src/app/core/models/timesheet.model";
import { StorageService } from "src/app/core/services/storage.service";
import { IOrganizationDto } from "src/app/core/models/organization.model";

/**
 * Based on EmployeeSelectMechanicModalComponent
 */
@Component({
	selector: "af-notification-select-employee-modal",
	templateUrl: "./notification-select-employee-modal.component.html",
	styleUrls: ["./notification-select-employee-modal.component.scss"]
})
export class NotificationSelectEmployeeModalComponent implements OnInit, OnDestroy {
	EmployeeOrderType = EmployeeOrderType;
	employees: IEmployeeDto[] = [];
	isLoading = true;
	isScrollToTopButtonVisible = false;
	query: IEmployeeQuery;
	@Input() workorder: IWorkorderDto;
	@Input() orderId: string;
	@Input() selectedEmployeesInput: IEmployeeDto[] = [];
	selectedEmployees: IEmployeeDto[] = [];
	@Input() usedInView: string;
	@Input() activeTabName: string;
	@Input() tabs: string[];
	@Input() timesheet: ITimesheetDto;

	@Input() ordersToEmployee: IOrdersToEmployeeDto[];
	@Input() employeesParent: IEmployeeDto[];
	viewPortEmployees = [];
	EmployeeSortType = EmployeeSortType;

	@ViewChild(IonInfiniteScroll)
	infiniteScroll: IonInfiniteScroll;
	@ViewChild(IonContent) content: IonContent;

	private ordersToEmployeeFields: OrdersToEmployeeField[] = [
		OrdersToEmployeeField.EmployeeId,
		OrdersToEmployeeField.OdersToEmployeeId,
		OrdersToEmployeeField.OrderId
	];

	private fields: EmployeeField[] = [
		EmployeeField.Emailaddress,
		EmployeeField.EmployeeId,
		EmployeeField.Fullname,
		EmployeeField.HourTypeIdEmployee,
		EmployeeField.IsShowMoreEmployeesInWork,
		EmployeeField.IsChiefWorkshop,
		EmployeeField.IsOwner,
		EmployeeField.IsMechanic,
		EmployeeField.NumberInPlanning,
		EmployeeField.HourTypeIdEmployee,
		EmployeeField.IsChoiceHourCategoryReq,
		EmployeeField.isHelpdeskAccount,
		EmployeeField.Firstname,
		EmployeeField.Middlename,
		EmployeeField.Lastname,
		EmployeeField.SvyUserId,
		EmployeeField.OrganizationId
	];

	allOrganizations: IOrganizationDto[];

	isLastPage = false;

	private unsubscribe$ = new Subject();
	private unsubscribeDataService$ = new Subject();

	constructor(
		private employeeApiService: EmployeeApiService,
		private employeeQueryService: EmployeeQueryService,
		private toastService: ToastService,
		private modalController: ModalController,
		private ordersToEmployeeApiService: OrdersToEmployeeApiService,
		// private workorderApiService: WorkorderApiService,
		private storageService: StorageService
	) {}

	async ngOnInit() {
		this.selectedEmployees = [...this.selectedEmployeesInput];

		this.handleQueryChanges();
		this.allOrganizations = (await this.storageService.get("allOrganizations")) as IOrganizationDto[];
	}

	// async getWorkorder(workorderId: string) {
	// 	return this.workorderApiService.getWorkorder(workorderId, ["rm_orders_to_employees_employee_id"]).toPromise();
	// }

	getAllOrganizations(id: string) {
		if (this.allOrganizations) {
			const index = this.allOrganizations.findIndex((org) => org.organization_id === id);
			return this.allOrganizations[index];
		} else return null;
	}

	ionViewWillLeave() {
		this.unsubscribeDataService$.next(undefined);
		this.unsubscribeDataService$.complete();
	}

	ngOnDestroy() {
		this.unsubscribe$.next(undefined);
		this.unsubscribe$.complete();
		this.unsubscribeDataService$.next(undefined);
		this.unsubscribeDataService$.complete();
	}

	headerDivider() {
		const headerFN = (record, recordIndex, records) => {
			if (record.is_mechanic === 1) record.type = "MONTEURS";
			else record.type = "OVERIG";

			if (record.orders_to_employee_id || this.selectedEmployees.findIndex((emp) => emp.employee_id === record.employee_id) !== -1)
				record.type = "GEKOPPELDE MEDEWERKERS";

			if (recordIndex === 0) return record.type;

			const firstPrev = records[recordIndex - 1].type;
			const firstCurrent = record.type;

			if (removeDiacritics(firstPrev) !== removeDiacritics(firstCurrent)) return firstCurrent.toUpperCase();

			return null;
		};
		return headerFN;
	}

	loadData(event) {
		if (!this.isLastPage) {
			this.employeeQueryService.setQuery({
				...this.query,
				page: this.query.page + 1
			});
			event.target.complete();
			// App logic to determine if all data is loaded
			// and disable the infinite scroll
		} else event.target.complete();
	}

	toggleInfiniteScroll() {
		this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
	}

	refresh(event) {
		this.isLoading = true;
		this.employeeQueryService.setQuery({ ...this.query, page: 1 });
		event.target.disabled = true;
		event.target.complete();
		setTimeout(() => {
			event.target.disabled = false;
		}, 100);
	}

	scrollToTop() {
		this.content.scrollToTop(300);
	}

	scroll(event) {
		if (event.detail.currentY > 200) this.isScrollToTopButtonVisible = true;
		else this.isScrollToTopButtonVisible = false;
	}

	showHideFab() {
		return this.isScrollToTopButtonVisible ? "showFab" : "hideFab";
	}

	addOrdersToEmployee(employee: IEmployeeDto) {
		if (employee)
			return this.ordersToEmployeeApiService.addOrderToEmployee({ order_id: this.workorder.order_id, employee_id: employee.employee_id }).toPromise();
	}

	getSelectedEmployees() {
		return this.ordersToEmployeeApiService.getOrdersToEmployee(1, this.ordersToEmployeeFields, `order_id[eq]:${this.orderId}`).toPromise();
	}

	async dismiss(skip?: boolean) {
		if (skip) return await this.modalController.dismiss();
		// if (this.isDisabled(employee)) return

		this.modalController.dismiss(this.selectedEmployees);
	}

	onSelectEmployee(employee?: IEmployeeDto) {
		if (!employee) return;
		if (this.isDisabled(employee)) return;

		this.selectedEmployees.push(employee);
		this.viewPortEmployees = this.getSortedEmployees(this.employees);
	}

	onRemoveEmployee(employee?: IEmployeeDto) {
		if (!employee) return;

		this.selectedEmployees = _filter(this.selectedEmployees, (se) => se[EmployeeField.EmployeeId] !== employee[EmployeeField.EmployeeId]);
		this.viewPortEmployees = this.getSortedEmployees(this.employees);
	}

	private handleQueryChanges() {
		this.employeeQueryService.queryChanges$
			.pipe(
				switchMap((query: IEmployeeQuery) => {
					this.isLoading = true;
					this.query = query;

					if (this.query.page === 1) {
						this.employees = [];
						this.viewPortEmployees = [];
					}

					let filter = this.employeeQueryService.getFilter();

					if (this.usedInView === "presentEmployeeModalClocking") {
						const employeeIds = this.ordersToEmployee.map((order) => order.employee_id);
						const employeeFilters = employeeIds.map((employee_id) => `employee_id[eq]:"${employee_id}"`).join(",-");
						filter = employeeIds.length > 0 ? employeeFilters : filter;
					}

					return this.employeeApiService.getEmployees(this.query.page, this.fields, filter, this.employeeQueryService.getSort());
				}),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				async (employeesResponse: IEmployeesResponse) => {
					let selectedEmployees: IEmployeeDto | IOrdersToEmployeeDto = [];
					if (this.usedInView === "add") selectedEmployees = this.selectedEmployees;
					else selectedEmployees = (await this.getSelectedEmployees()).data;

					await this.getSelectedEmployees();
					const employeeResponseData: IEmployeeDto[] = employeesResponse.data.map((employee) => {
						selectedEmployees.forEach((selectedEmployee) => {
							if (employee.employee_id === selectedEmployee.employee_id) employee.orders_to_employee_id = selectedEmployee.orders_to_employee_id;
						});
						return employee;
					});
					if (employeeResponseData.length > 0) {
						this.employees = employeeResponseData;
						this.viewPortEmployees = this.getSortedEmployees(this.employees);
						this.isLastPage = employeesResponse.data.length < 100;
						this.isLoading = false;
					} else this.isLoading = false;
				},
				(error) => {
					this.toastService.presentFailureToast(error.error.errorMessage);
					this.isLoading = false;
				}
			);
	}

	private getSortedEmployees(employees: IEmployeeDto[]): IEmployeeDto[] {
		let sorted = employees
			.map((e, i) => {
				const name = `${e.firstname ?? ""} ${e.middlename ?? ""} ${e.lastname ?? ""}`;
				e.gen_full_name = name.trim();
				if (e.number_in_planning === null) e.number_in_planning = 999;
				if (e.is_mechanic === null || e.is_mechanic === undefined || e.is_mechanic === 0) {
					e.is_mechanic = 0;
					e.type = "OVERIG";
				}
				if (e.is_mechanic === 1) e.type = "MONTEURS";
				if (e.organization_id) e.organization = this.getAllOrganizations(e.organization_id).name;

				if (e.orders_to_employee_id || this.selectedEmployees.findIndex((emp) => emp.employee_id === e.employee_id) !== -1) e.type = "GEKOPPELDE MEDEWERKERS";

				return { index: i, value: e };
			})
			.sort((a, b) => {
				if (a.value.type === "MONTEURS" && b.value.type === "MONTEURS") {
					if (a.value.number_in_planning !== null && b.value.number_in_planning !== null) {
						if (a.value.number_in_planning === b.value.number_in_planning) {
							if (a.value.organization === b.value.organization) {
								if (a.value.gen_full_name < b.value.gen_full_name) return -1;
								if (a.value.gen_full_name > b.value.gen_full_name) return 1;
							} else {
								if (a.value.organization < b.value.organization) return -1;
								if (a.value.organization > b.value.organization) return 1;
							}
							return 0;
						}
						return a.value.number_in_planning - b.value.number_in_planning;
					}

					if (a.value.number_in_planning !== null && b.value.number_in_planning === null) return -1;
					if (a.value.number_in_planning === null && b.value.number_in_planning !== null) return 1;
					return 0;
				}
				if (a.value.type === "MONTEURS" && b.value.type !== "MONTEURS") return -1;

				if (a.value.type !== "MONTEURS" && b.value.type === "MONTEURS") return 1;
				return 0;
			})
			.sort((a, b) => {
				if (a.value.type !== "MONTEURS" && b.value.type !== "MONTEURS") {
					if (a.value.number_in_planning !== null && b.value.number_in_planning !== null) {
						if (a.value.number_in_planning === b.value.number_in_planning) {
							if (a.value.organization === b.value.organization) {
								if (a.value.gen_full_name < b.value.gen_full_name) return -1;
								if (a.value.gen_full_name > b.value.gen_full_name) return 1;
							} else {
								if (a.value.organization < b.value.organization) return -1;
								if (a.value.organization > b.value.organization) return 1;
							}
							return 0;
						}
						return a.value.number_in_planning - b.value.number_in_planning;
					}
					if (a.value.number_in_planning !== null && b.value.number_in_planning === null) return -1;
					if (a.value.number_in_planning === null && b.value.number_in_planning !== null) return 1;
					return 0;
				}
				return 0;
			})
			.sort((a, b) => {
				if (a.value.type === "GEKOPPELDE MEDEWERKERS" && b.value.type !== "GEKOPPELDE MEDEWERKERS") return -1;
				if (a.value.type !== "GEKOPPELDE MEDEWERKERS" && b.value.type === "GEKOPPELDE MEDEWERKERS") return 1;
				return 0;
			});

		const sortedClocking = sorted.filter((e) => e.value.type === "GEKOPPELDE MEDEWERKERS");
		if (this.usedInView === "presentEmployeeModalClocking") sorted = sortedClocking;
		return sorted.map((el) => employees[el.index]);
	}

	isSelected(employee: IEmployeeDto) {
		return !!this.selectedEmployees.find((emp) => emp[EmployeeField.EmployeeId] === employee[EmployeeField.EmployeeId]);
	}

	isDisabled(employee: IEmployeeDto) {
		if (this.usedInView === "add") {
			// Disallow more than 10 receivers for now
			if (this.ordersToEmployee?.length >= 10) return true;
			// Prevents duplicate employees
			if (this.isSelected(employee)) return true;
		} else return false;
	}
}
