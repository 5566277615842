export enum helpPageField {
	helpPageId = "help_page_id",
	formPath = "form_path",
	documentShortlinkId = "document_shortlink_id",
	chatbotId = "chatbot_id",
	createdDate = "created_date",
	modifiedDate = "modified_date"
}

export interface IHelpPageDTO {
	help_page_id?: string;
	form_path?: string;
	document_shortlink_id?: string;
	chatbot_id?: string;
	created_date?: string;
	modified_date?: string;
}

export interface IHelpPageResponse {
	currentPage: number;
	data: IHelpPageDTO[];
	nextpage: boolean;
}
