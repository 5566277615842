import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { SettingApiService } from "src/app/core/api/setting-api.service";
import { TurnoverGroupApiService } from "src/app/core/api/turnovergroup-api.service";
import { WorkorderApiService } from "src/app/core/api/workorder-api.service";
import { IArticleDto } from "src/app/core/models/article.model";
import { ISettingResponse, SettingField } from "src/app/core/models/setting.model";
import { ITurnoverGroupsResponse, TurnoverGroupField } from "src/app/core/models/turnovergroup.model";
import { IWorkorderLineDto } from "src/app/core/models/workorder-line.model";
import { ToastService } from "src/app/core/services/toast.service";
import { ActionSheetService } from "../action-sheet/action-sheet.service";

@Component({
	selector: "af-barcode-scanner-action-sheet",
	templateUrl: "./barcode-scanner-action-sheet.component.html"
})
export class BarcodeScannerActionSheetComponent {
	@Input() action: string;
	@Input() scannedBarcode: string;
	@Input() article: IArticleDto;
	@Input() articleDescription: string;
	@Input() orderId: string;
	@Input() state: any;

	constructor(
		private actionSheetService: ActionSheetService,
		private router: Router,
		private workorderApiService: WorkorderApiService,
		private settingApiService: SettingApiService,
		private turnoverGroupApiService: TurnoverGroupApiService,
		private toastService: ToastService
	) {}

	createArticleToWorkOrderLines() {
		this.addArticleAndToWorkorderLine(`workorders/${this.orderId}/edit/orderlines/add`);
		this.dismiss("closeBarcodeScanner"); // dismiss actionsheet and close barcode scanner because type has been given
	}

	createArticleToWorkOrderLinesAndContinue() {
		const openBarcodeOnEntry = true;
		this.addArticleAndToWorkorderLine(`workorders/${this.orderId}/edit/orderlines`, openBarcodeOnEntry);
		this.dismiss("closeBarcodeScanner"); // dismiss actionsheet and close barcode scanner because type has been given
	}

	addArticleToWorkOrderLines() {
		this.addWorkorderLine(this.article.article_id, this.orderId); // article is always found in this case
		this.dismiss("closeBarcodeScanner"); // dismiss actionsheet and close barcode scanner because type has been given
	}

	addArticleToWorkOrderLinesAndContinue() {
		this.postArticleToWorkorderLine(this.article); // post found article to workorderline
		this.dismiss(); // close actionsheet and resume scanning because no dismiss type has been given
	}

	async addWorkorderLine(articleId?: string, orderID?: string) {
		if (this.article && orderID) {
			this.router.navigate([`workorders/${this.orderId}/edit/orderlines/add/${articleId}`], {
				state: {
					routeBackPoint: `workorders/${this.orderId}/edit/orderlines`
				}
			});
		}
	}

	async addArticleAndToWorkorderLine(routeBackPoint: string, openBarcodeOnEntry?: boolean) {
		await this.router.navigate(["/articles/add"], {
			state: {
				scannedBarcode: this.scannedBarcode,
				orderId: this.orderId,
				openBarcodeOnEntry: openBarcodeOnEntry ? openBarcodeOnEntry : null,
				routeBackPoint
			}
		});
	}

	async postArticleToWorkorderLine(article: IArticleDto, orderId?: string) {
		// empty workorderline object
		const workorderLine: IWorkorderLineDto = {};
		// add workorder id to the orderline
		if (this.orderId) 
			workorderLine.order_id = this.orderId;
		 else {
			this.orderId = orderId;
			workorderLine.order_id = this.orderId;
		}

		// get default settings & turnovergroups for use later
		const settings = await this.getDefaultSettings();
		const turnoverGroups = await this.getTurnoverGroups();

		// convert article to workorderline
		Object.entries(article).forEach(([key, value]) => {
			if (key !== undefined && value !== null && value !== undefined) {
				if (key === "gross_price") {
					if (workorderLine.price !== null) 
						workorderLine.price = value;
					
				} else if (key === "description") {
					workorderLine[key] = value;
					workorderLine.description_internal = value;
				} else if (key === "unit_quantity") 
					workorderLine.quantity = value;
				 else 
					workorderLine[key] = value;
				
			}
		});

		// based on article kind set turnovergroup and unit_type
		if (workorderLine.article_kind === 1) {
			workorderLine.turnover_group_id = settings.data[0].turnover_group_id_articles;
			workorderLine.unit_type = 1;
		} else if (workorderLine.article_kind === 2) {
			workorderLine.turnover_group_id = settings.data[0].turnover_group_id_handlings;
			workorderLine.unit_type = 3;
		} else if (workorderLine.article_kind === 3) {
			workorderLine.turnover_group_id = settings.data[0].turnover_group_id_remaining;
			workorderLine.unit_type = 1;
		}

		// set extra workorderlineFields based on article values
		workorderLine.is_manual_input = 0;
		workorderLine.discount_percentage = 0;

		// find turnovergroup based on article kind set ^
		const turnoverGroup = turnoverGroups.data.filter((turnoverGroupData) => workorderLine.turnover_group_id === turnoverGroupData.turnover_group_id);

		// if the turnovergroup has vat_code_id_sale
		if (turnoverGroup[0].vat_code_id_sale) 
			workorderLine.vat_code_id = turnoverGroup[0].vat_code_id_sale;
		
		// if the turnovergroup has no value default to vat_code_id_paytaxedhigh
		if (!workorderLine.vat_code_id.vat_code_id) {
			// default vat high from settings as fallback
			workorderLine.vat_code_id = settings.data[0].vat_code_id_paytaxedhigh;
		}

		// post workorderline object to workorderline
		this.workorderApiService.addWorkorderLine(workorderLine).subscribe(
			() => {
				this.toastService.presentSuccessToast("Orderegel aangemaakt");
			},
			(error: Error) => {
				this.toastService.presentFailureToast(`Fout: ${error.message}`);
			}
		);
	}

	dismiss(dismissType?: string) {
		this.actionSheetService.dismissAll(dismissType ?? dismissType);
	}

	getDefaultSettings(): Promise<ISettingResponse> {
		return this.settingApiService
			.getSetting([
				SettingField.TurnoverGroupIdArticles,
				SettingField.TurnoverGroupIdHandlings,
				SettingField.TurnoverGroupIdRemaining,
				SettingField.VatCodeIdPaytaxedhigh
			])
			.toPromise();
	}

	getTurnoverGroups(): Promise<ITurnoverGroupsResponse> {
		return this.turnoverGroupApiService
			.getTurnoverGroups(1, [TurnoverGroupField.Description, TurnoverGroupField.VatCodeIdSale, TurnoverGroupField.VatCodeIdPurchase])
			.toPromise();
	}
}
