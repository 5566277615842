import { ListResponse } from "./api.model";

export enum AdvertiseDefaultTextField {
    AdvertiseDefaultTextId = "advertise_default_text_id",
    CountryId = "country_id",
    LanguageText = "language_text",
    TextType = "text_type"
}

export enum AdvertiseDefaultTextType {
    Dealer = 1,
    Private = 2
}

export enum SettingField {
    AddressOwnCompany = "address_own_company",
    CityOwnCompany = "city_own_company",
    HouseNumberAddOwnCompany = "house_number_add_own_company",
    HouseNumberOwnCompany = "house_number_own_company",
    NameOwnCompany = "name_own_company",
    ContactIdPasserBy = "contact_id_passer_by",
    TurnoverGroupIdArticles = "turnover_group_id_articles",
    TurnoverGroupIdHandlings = "turnover_group_id_handlings",
    TurnoverGroupIdRemaining = "turnover_group_id_remaining",
    DefaultColorOrderDesc = "default_color_order_desc",
    DefaultTextColorOrderDesc = "default_text_color_order_desc",
    ArticleGroupId = "article_group_id",
    ContactIdSupplier = "contact_id_supplier",
    HourTypeId = "hour_type_id",
    DayEndTime = "day_end_time",
    DayStartTime = "day_start_time",
    VatCodeIdPaytaxedhigh = "vat_code_id_paytaxedhigh",
    DefaultPaymentCondition = "payment_condition_id"
}

export interface IAdvertiseDefaultTextDto {
    [AdvertiseDefaultTextField.AdvertiseDefaultTextId]: string;
    [AdvertiseDefaultTextField.CountryId]?: string;
    [AdvertiseDefaultTextField.LanguageText]?: string;
    [AdvertiseDefaultTextField.TextType]?: AdvertiseDefaultTextType;
}

export interface ISettingDto {
    [SettingField.AddressOwnCompany]?: string;
    [SettingField.CityOwnCompany]?: string;
    [SettingField.HouseNumberAddOwnCompany]?: string;
    [SettingField.HouseNumberOwnCompany]?: string;
    [SettingField.NameOwnCompany]?: string;
    [SettingField.ContactIdPasserBy]?: string;
    [SettingField.TurnoverGroupIdArticles]?: string;
    [SettingField.TurnoverGroupIdHandlings]?: string;
    [SettingField.TurnoverGroupIdRemaining]?: string;
    [SettingField.DefaultColorOrderDesc]?: string;
    [SettingField.DefaultTextColorOrderDesc]?: string;
    [SettingField.ArticleGroupId]?: string;
    [SettingField.ContactIdSupplier]?: string;
    [SettingField.HourTypeId]?: string;
    [SettingField.DayStartTime]?: string;
    [SettingField.DayEndTime]?: string;
    [SettingField.VatCodeIdPaytaxedhigh]?: string;
    [SettingField.DefaultPaymentCondition]?: string;
}

export interface ISettingSiteDto {
    description: string;
    permission_site: string;
    publish: boolean;
    site_code: string;
    site_type: number;
}

export interface ISettingResponse {
    data: ISettingDto[];
}

export interface ISettingSitesResponse {
    currentPage: number;
    data: ISettingSiteDto[];
    maxPages: number;
}

export type IAdvertiseDefaultTextResponse = ListResponse<IAdvertiseDefaultTextDto>;
