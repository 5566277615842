import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IFreeProviderDataResponse, IPaidProviderResponse, IProviderDataParams } from "../models/provider-data.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class ProviderDataApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getPaidProvider(): Observable<IPaidProviderResponse> {
        return this.httpClient.get<IPaidProviderResponse>(`${this.environmentService.apiUrl}/providerdata/defaultprovider`);
    }

    getProviderData(providerDataParams: IProviderDataParams): Observable<IFreeProviderDataResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("licenseplate", providerDataParams.licenseplate);
        if (providerDataParams.bodyid) 
            httpParams = httpParams.set("bodyid", providerDataParams.bodyid);
        
        if (providerDataParams.opendata) 
            httpParams = httpParams.set("opendata", "true");
        
        if (providerDataParams.registrationcode) 
            httpParams = httpParams.set("registrationcode", providerDataParams.registrationcode);
        

        return this.httpClient.get<IFreeProviderDataResponse>(`${this.environmentService.apiUrl}/providerdata`, { params: httpParams });
    }
}
