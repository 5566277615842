<ion-header>
	<ion-toolbar color="primary" mode="md">
		<ion-buttons slot="start">
			<ion-button (click)="dismiss()" data-test="dismiss-button">
				<fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" size="lg" slot="icon-only"></fa-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>Update beschikbaar</ion-title>
		<ion-buttons slot="end">
			<af-header-popover-button [componentName]="this.constructor.name"></af-header-popover-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
	<ion-grid>
		<ion-row>
			<ion-col>
				<div class="ion-text-center code-branch">
					<h2>
						Nieuwe versie <span *ngIf="currentDevicePlatform === 'ios'">({{ availableVersion }})</span>
					</h2>
					<fa-icon [fixedWidth]="true" [icon]="['fal', 'tire-flat']" size="8x"></fa-icon>
				</div>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col>
				<div class="ion-text-center code-branch">
					<h4>Update vereist</h4>
					<p class="dark-mode-normal-text">
						De geinstalleerde versie ({{ currentVersion }}) is verouderd.<br />
						Dit betekent dat sommige functionaliteiten in de app fouten kunnen opleveren. Om dit te voorkomen verzoeken wij jou om de app te updaten naar de
						nieuwste versie.
					</p>
					<ion-button (click)="openAppStore()" expand="block" data-test="update=-app-button">{{ goToStoreString }}</ion-button>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
