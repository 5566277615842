import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { Device } from "@capacitor/device";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { ModalController, Platform, PopoverController } from "@ionic/angular";
import { Storage } from "@ionic/storage-angular";
import { register } from "swiper/element/bundle";
import { fromEvent, Subject } from "rxjs";
import { EmployeeApiService } from "./core/api/employee-api.service";
import { OrganizationApiService } from "./core/api/organization-api.service";
import { UserApiService } from "./core/api/user-api.service";
import { EmployeeField, IEmployeeDto, IEmployeeResponse } from "./core/models/employee.model";
import { IOrganizationDto, IUserOrganizationDto, OrganizationField, UserOrganizationField } from "./core/models/organization.model";
import { UserField } from "./core/models/user.model";
import { AlertService } from "./core/services/alert.service";
import { AnalyticsService } from "./core/services/analytics.service";
import { AuthService } from "./core/services/auth.service";
import { LoadingService } from "./core/services/loading.service";
import { StorageService } from "./core/services/storage.service";
import { ToastService } from "./core/services/toast.service";
import { ActionSheetService } from "./shared/components/action-sheet/action-sheet.service";
import { FeedbackModalComponent } from "./shared/components/feedback-modal/feedback-modal.component";
import { OrganizationActionSheetComponent } from "./shared/components/organization-action-sheet/organization-action-sheet.component";
import { UpdateModalComponent } from "./shared/components/update-modal/update-modal.component";
import { AccessControlService } from "./shared/services/access-control.service";
import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import * as SentryBrowser from "@sentry/browser";
import { environment } from "../environments/environment";
import { EnvironmentService } from "./core/services/environment.service";
import { AuthApiService } from "./core/api/auth-api.service";
import { takeUntil } from "rxjs/operators";
import { HeaderPopoverComponent } from "./shared/components/header-popover/header-popover.component";
import { NotificationRegistrationService } from "./core/services/notification-register.service";
import packageInfo from "package.json";
// Register swiper
register();

@Component({
	selector: "af-app",
	templateUrl: "app.component.html"
})
export class AppComponent implements OnInit {
	ionicEnvironment = environment;
	isLoggedIn = false;
	currentVersion: string;
	availableVersion: string;
	updateAvailability: number;
	private unsubscribe$ = new Subject();

	userOrganizations: IUserOrganizationDto[];
	allOrganizations: IOrganizationDto[];
	currentOrganization: IOrganizationDto;
	currentOrganizationName: string;

	profile: IEmployeeDto = [];
	showProfile = false;
	activeRole: string;
	avatars: [{ employee_id: string; avatar: string; employee_fullname: string }];
	filter = "is_archived[eq]:0,(is_helpdesk_account[eq]:0,-is_helpdesk_account[eq]:null)";
	activeEmployee: IEmployeeDto;
	employeeFields: EmployeeField[] = [
		EmployeeField.Emailaddress,
		EmployeeField.EmployeeId,
		EmployeeField.Fullname,
		EmployeeField.HourTypeIdEmployee,
		EmployeeField.IsShowMoreEmployeesInWork,
		EmployeeField.IsChiefWorkshop,
		EmployeeField.IsOwner,
		EmployeeField.NumberInPlanning
	];

	constructor(
		private alertService: AlertService,
		public authService: AuthService,
		private loadingService: LoadingService,
		private modalController: ModalController,
		private platform: Platform,
		private router: Router,
		private location: Location,
		private toastService: ToastService,
		private analyticsService: AnalyticsService,
		private employeeApiService: EmployeeApiService,
		private organizationApiService: OrganizationApiService,
		private accessControlService: AccessControlService,
		private userApiService: UserApiService,
		private notificationRegistrationService: NotificationRegistrationService,
		private actionSheetService: ActionSheetService,
		private storageService: StorageService,
		private storage: Storage,
		private environmentService: EnvironmentService,
		private authApiService: AuthApiService,
		private popoverController: PopoverController
	) {
		this.initializeApp();

		// Catch events for google analytics
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.analyticsService.getScreenRouter(event.url);
				this.analyticsService.RoutingEvent(event.url);
				this.storageService.set("currentUrl", event.url);
				this.storageService.set("previousUrl", this.storageService.get("currentUrl"));
			}
		});
	}

	async ngOnInit() {
		await this.storage.create(); // Initialize storage

		Device.getInfo().then(async (deviceInfo) => {
			if (deviceInfo.platform === "web") {
				const { hash } = window.location;
				if (hash) {
					const hasMode = hash.split("&").find((item) => item.includes("mode"));
					if (hasMode) {
						const mode = hasMode.split("=")[1];
						await this.storageService.set("mode", mode);
					}
				}

				this.storageService.get("mode").then((mode) => {
					if (mode) document.querySelector("html").setAttribute("mode", mode);
				});
			}
		});

		this.authService.loggedIn.subscribe(async (value) => {
			const previousValue = this.isLoggedIn;

			if (value !== previousValue && value) {
				this.isLoggedIn = value;
				await this.getEmployeeData();
				await this.getModules();
				const userId = await this.authService.getUserId();
				await this.getUserOrganization(userId);
				await this.getAllOrganizations();
				await this.getCurrentOrganization(userId);
				await this.getEmployeeAvatars();
				if (this.ionicEnvironment.notificationsEnabled) this.notificationRegistrationService.tryRegisterDevice();
			}
		});

		this.loadingService.dismiss();

		if (!navigator.onLine) this.toastService.presentFailureToast("Offline");

		fromEvent(window, "offline").subscribe(() => {
			this.toastService.presentFailureToast("Offline");
		});

		fromEvent(window, "online").subscribe(() => {
			this.toastService.presentSuccessToast("Online");
		});

		Device.getInfo().then((info) => {
			if (info.platform !== "web") {
				console.log("(app) device info: ", info.platform);
				this.checkUpdate();
			}
		});
	}

	getModules() {
		return this.authApiService
			.getModules()
			.toPromise()
			.then((modules) => {
				this.storageService.set("modules", modules.modules.data);
			});
	}

	async getEmployeeAvatars() {
		// remove employeAvatars from storage cause this runs on login to refresh data
		await this.storageService.remove("employeeAvatars");
		this.avatars = await this.storageService.get("employeeAvatars");
		if (!this.avatars) this.avatars = [{}] as [{ employee_id: string; avatar: string; employee_fullname: string }];
		const employees = this.employeeApiService.getEmployees(1, this.employeeFields, this.filter).toPromise();
		await employees.then((employeeResponse: IEmployeeResponse) => {
			employeeResponse.data.forEach(async (employee: IEmployeeDto) => {
				const found = this.avatars.filter((a) => a.employee_id === employee.employee_id);
				if (found.length === 0 || !found[0].avatar) {
					this.employeeApiService.getEmployeeAvatar(employee.employee_id).subscribe(async (avatar) => {
						if (avatar) {
							const avatarIndex = this.avatars.findIndex((a) => a.employee_id === employee.employee_id);
							if (avatarIndex !== -1) this.avatars.splice(avatarIndex, 1);
							const base64Avatar = await this.blobToBase64(avatar);
							this.avatars.push({ employee_id: employee.employee_id, avatar: base64Avatar, employee_fullname: employee.v_display_name });
							this.storageService.set("employeeAvatars", this.avatars);
						}
					});
				}
			});
		});
	}

	// Function to convert Blob to Base64
	blobToBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				const base64String = (reader.result as string).split(",")[1];
				resolve(base64String);
			};

			reader.readAsDataURL(blob);
		});
	}

	checkUpdate() {
		this.getAvailableAppVersion().then((result) => {
			this.currentVersion = result.currentVersion;
			this.availableVersion = result.availableVersion;
			this.updateAvailability = result.updateAvailability;
			if (this.updateAvailability === 2) this.presentUpdateModal();
		});
	}

	getRoleNameTranslated(role: string) {
		const isIntern = !!this.profile[0].is_intern;
		let groupName = "";
		switch (role) {
			case "mechanic":
				if (!isIntern) groupName = "Monteur";
				else groupName = "Stagiair Monteur";

				break;
			case "chief_workshop":
				if (!isIntern) groupName = "Chef Werkplaats";
				else groupName = "Stagiair Chef Werkplaats";

				break;
			case "mot_inspector":
				if (!isIntern) groupName = "Keurmeester";
				else groupName = "Stagiair Keurmeester";

				break;
			case "receptionist":
				if (!isIntern) groupName = "Receptionist";
				else groupName = null;

				break;
			case "manager_warehouse":
				if (!isIntern) groupName = "Magazijn beheerder";
				else groupName = "Stagiair Magazijn beheerder";

				break;
			case "salesman":
				if (!isIntern) groupName = "Verkoper";
				else groupName = "Stagiair Verkoper";

				break;
			case "chef_sales":
				if (!isIntern) groupName = "Hoofd verkoper";
				else groupName = null;

				break;
			case "purchaser":
				if (!isIntern) groupName = "Inkoper";
				else groupName = "Stagiair Inkoper";

				break;
			case "administrator":
				if (!isIntern) groupName = "Administratie";
				else groupName = "Stagiair Administratie";

				break;
			case "owner":
				if (!isIntern) groupName = "Eigenaar";
				else groupName = null;

				break;
			case "support":
				if (!isIntern) groupName = "Helpdesk";
				else groupName = null;

				break;
			case "superuser":
				if (!isIntern) groupName = "Root";
				else groupName = null;

				break;
			case "default":
				if (!isIntern) groupName = "Geen toegang";
				else groupName = null;

				break;
		}

		return groupName;
	}

	async presentUpdateModal() {
		const modal = await this.modalController.create({
			component: UpdateModalComponent,
			componentProps: {
				currentVersion: this.currentVersion,
				availableVersion: this.availableVersion,
				updateAvailability: this.updateAvailability
			}
		});
		modal.onDidDismiss().then(({ data }) => {
			if (data) return;
		});
		await modal.present();
	}

	getAvailableAppVersion = async () => {
		const result = await AppUpdate.getAppUpdateInfo();
		return result;
	};

	async presentFeedbackModal() {
		const modal = await this.modalController.create({ component: FeedbackModalComponent });
		await modal.present();
	}

	async presentHelpModal() {
		// const modal = await this.modalController.create({ component: HelpModalComponent });
		// await modal.present();
		const popover = await this.popoverController.create({
			component: HeaderPopoverComponent,
			componentProps: {
				componentName: this.constructor.name,
				openModalDocs: true
			}
		});
		await popover.present();
	}

	presentLogoutAlert() {
		this.alertService.presentConfirmAlert("Uitloggen?", "Wil je uitloggen?", () => {
			this.logout();
		});
	}

	private initializeApp() {
		this.platform.backButton.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			if (this.location.path() !== "/home") this.location.back();
			else return;
		});
	}

	async getEmployeeData() {
		const getActiveEmployee = await this.employeeApiService
			.getActiveEmployee([
				EmployeeField.Fullname,
				EmployeeField.SvyUserId,
				EmployeeField.IsAccessToApp,
				EmployeeField.isShowAllOrdersInApp,
				EmployeeField.IsHideRelationsInApp,
				EmployeeField.OrganizationId,
				EmployeeField.IsChoiceHourCategoryReq,
				EmployeeField.isHelpdeskAccount
			])
			.toPromise();
		if (getActiveEmployee.data) {
			const activeEmployee = getActiveEmployee.data[0];
			this.activeEmployee = activeEmployee;
			const environmentUrl = (await this.environmentService.getEnvironment(await this.storageService.get("username")).toPromise()).api_url;
			const userEnvironment = new URL(environmentUrl).hostname.split(".").pop().toLowerCase();
			// how many procent of traces are send to sentry
			let tracesSampleRate: number = 1;
			// how many procent of replays are send to sentry on error
			let replaysOnErrorSampleRate: number = 1;
			// how many procent of replays are send to sentry on session
			let replaysOnSessionSampleRate: number = 0;
			switch (userEnvironment) {
				case "vip":
					tracesSampleRate = 0.5;
					break;
				case "eu":
				case "nl":
					tracesSampleRate = 0.1;
					break;
				case "dev":
					tracesSampleRate = 1.0;
					break;
				default:
					tracesSampleRate = 1.0;
					break;
			}
			let dist = "";
			if (this.platform) {
				if (this.platform.is("ios") || this.platform.is("ipad") || this.platform.is("iphone")) {
					dist = packageInfo.iosBuildNumber;
				} else if (this.platform.is("android") || this.platform.is("mobile")) {
					dist = packageInfo.androidBuildNumber;
				}
			}
			Sentry.init(
				{
					dsn: "https://2c1030526b3089455e0bf35a1f2ab58e@sentry.autoflex10.com/4",
					// To set your release and dist versions
					release: "mobile@" + environment.versionApp,
					dist: dist,
					// Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
					// We recommend adjusting this value in production.
					tracesSampleRate: tracesSampleRate,
					replaysSessionSampleRate: replaysOnSessionSampleRate,
					replaysOnErrorSampleRate: replaysOnErrorSampleRate,
					environment: userEnvironment,
					integrations: [
						Sentry.browserTracingIntegration(),
						Sentry.replayIntegration({
							// Additional SDK configuration goes in here, for example:
							blockAllMedia: true,
							maskAllText: false
						}),
						SentryBrowser.rewriteFramesIntegration()
					]
				},
				// Forward the init method from @sentry/angular
				SentryAngular.init
			);
			Sentry.setUser({ organization_id: activeEmployee.organization_id, employee_id: activeEmployee.employee_id });
			Sentry.setTags({ organization_id: activeEmployee.organization_id, employee_id: activeEmployee.employee_id, environment: userEnvironment });

			await this.storageService.set("activeEmployee", getActiveEmployee.data[0]).then(async () => {
				this.profile.push(activeEmployee);
				await this.accessControlService.initAccess();
				const access = await this.accessControlService.getAccess();
				this.activeRole = this.getRoleNameTranslated(access.role);
			});

			await this.employeeApiService
				.getEmployeeAvatar(activeEmployee.employee_id)
				.toPromise()
				.then(async (avatar) => {
					if (avatar) {
						const storeAvatar = await this.storageService.get("employeeAvatars");
						this.avatars = storeAvatar ? storeAvatar : [{}];
						if (this.avatars) {
							const avatarIndex = this.avatars.findIndex((a) => a.employee_id === activeEmployee.employee_id);
							if (avatarIndex === -1) {
								const base64Avatar = await this.blobToBase64(avatar);
								this.avatars.push({ employee_id: activeEmployee.employee_id, avatar: base64Avatar, employee_fullname: activeEmployee.v_display_name });
							}
							this.storageService.set("employeeAvatars", this.avatars);
						} else {
							const base64Avatar = await this.blobToBase64(avatar);
							this.avatars.push({ employee_id: this.profile[0].employee_id, avatar: base64Avatar, employee_fullname: this.profile[0].v_display_name });
							this.storageService.set("employeeAvatars", this.avatars);
						}
					}
				});

			if (this.profile) this.showProfile = true;
			else this.showProfile = false;
			return this.profile;
		} else Promise.resolve(null);
	}

	async getUserOrganization(userId: string) {
		if (this.isLoggedIn) {
			const org = await this.organizationApiService
				.getUserOrganizations(1, [UserOrganizationField.OrganizationId, UserOrganizationField.UserOrgId, UserOrganizationField.UserId], `user_id[eq]:${userId}`)
				.toPromise();
			this.userOrganizations = org.data;
		}
	}

	async getAllOrganizations() {
		if (this.isLoggedIn) {
			const org = await this.organizationApiService.getOrganizations(1, [OrganizationField.Name, OrganizationField.OrganizationId]).toPromise();
			this.allOrganizations = org.data;
			this.storageService.set("allOrganizations", this.allOrganizations);
		}
	}

	async getCurrentOrganization(userId: string) {
		if (this.isLoggedIn) {
			const user = await this.userApiService.getUser(userId, [UserField.UserId, UserField.LastUserOrganizationId]).toPromise();
			if (user.data[0].last_used_organization_id) {
				this.currentOrganization = this.allOrganizations.find((x) => x.organization_id === user.data[0].last_used_organization_id);
				this.currentOrganizationName = this.currentOrganization.name;
			} else {
				const userOrg = this.userOrganizations.find((x) => x.user_id === userId);
				if (userOrg) {
					this.currentOrganization = this.allOrganizations.find((x) => x.organization_id === userOrg.organization_id);
					this.currentOrganizationName = this.currentOrganization.name;
				}
			}
		}
	}

	async presentOrganizationModal() {
		return this.actionSheetService.create(
			OrganizationActionSheetComponent,
			{
				userOrganizationIds: this.userOrganizations.map((userOrg) => userOrg.organization_id).filter((org) => org !== this.currentOrganization.organization_id),
				organizations: this.allOrganizations,
				currentOrganization: this.currentOrganization
			},
			"orgsheet"
		);
	}

	private async logout() {
		// delete organization from storage

		await this.storageService.removeOrgFromAllQuerys("settings");

		this.authService.logout().then(
			() => {},
			(error) => {
				this.toastService.presentFailureToast(error.error.errorMessage);
			}
		);
	}
}
