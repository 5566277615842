<af-action-sheet header="Selecteer vestiging">
    <ion-list>
        <ion-item data-test="dismiss-button" disabled="true" color="primary">
            <ion-label class="padding-left">Actief: {{ currentOrganization.name }}</ion-label>
        </ion-item>
        <ion-item *ngFor="let userOrganizationId of userOrganizationIds" (click)="selectOrganization(userOrganizationId)" button="true" data-test="dismiss-button">
            <fa-icon [fixedWidth]="true" [icon]="['fal', 'warehouse']" class="ion-margin-end" size="1x" slot="start"></fa-icon>
            <ion-label>{{ relatedOrganizationData(userOrganizationId) }}</ion-label>
        </ion-item>
    </ion-list>
</af-action-sheet>
