import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { EnvironmentService } from "../services/environment.service";
import { IHelpPageDTO, IHelpPageResponse, helpPageField } from "../models/helppage.model";

@Injectable({
	providedIn: "root"
})
export class HelpPageApiService {
	constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

	getHelpPages(page: number, fields: helpPageField[], filter?: string, sort?: string): Observable<IHelpPageResponse> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("page", page + "");
		httpParams = httpParams.set("fields", fields.join(","));
		if (filter) httpParams = httpParams.set("filter", filter);

		if (sort) httpParams = httpParams.set("sort", sort);

		return this.httpClient.get<IHelpPageResponse>(`${this.environmentService.apiUrl}/helppage`, { params: httpParams });
	}

	getHelpPage(form_path: string, fields: string[]): Observable<IHelpPageResponse> {
		let httpParams = new HttpParams();
		if (fields.length) httpParams = httpParams.set("fields", fields.join(","));
		return this.httpClient.get<IHelpPageResponse>(`${this.environmentService.apiUrl}/helppage/${form_path}`, { params: httpParams });
	}

	addHelpPage(helpPage: IHelpPageDTO): Observable<IHelpPageDTO> {
		return this.httpClient.post<IHelpPageDTO>(`${this.environmentService.apiUrl}/helppage`, helpPage);
	}

	editHelpPage(id: string, helpPage: IHelpPageDTO) {
		return this.httpClient.put(`${this.environmentService.apiUrl}/helppage/${id}`, helpPage);
	}
}
