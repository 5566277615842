import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthenticationGuard } from "./core/guards/authentication.guard";

const routes: Routes = [
	{
		path: "contacts",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./contacts/contacts.module").then((m) => m.ContactsModule)
	},
	{
		path: "home",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./home/home.module").then((m) => m.HomeModule)
	},
	{
		path: "login",
		loadChildren: () => import("./login/login.module").then((m) => m.LoginModule)
	},
	{
		path: "profile",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule)
	},
	{
		path: "settings",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule)
	},
	{
		path: "vehicles",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./vehicles/vehicles.module").then((m) => m.VehiclesModule)
	},
	{
		path: "workorders",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./workorders/workorders.module").then((m) => m.WorkordersModule),
		data: { modules: "workshop", permission: "read" }
	},
	{
		path: "articles",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./articles/articles.module").then((m) => m.ArticlesModule)
	},
	{
		path: "workflow",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./workflow/workflow.module").then((m) => m.WorkflowModule),
		data: { modules: "workflow", permission: "read" }
	},
	{
		path: "timesheet",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./timesheet/timesheet.module").then((m) => m.TimesheetModule),
		data: { modules: "workflow", permission: "read" }
	},
	{
		path: "notification",
		canLoad: [AuthenticationGuard],
		loadChildren: () => import("./notification/notification.module").then((m) => m.NotificationModule)
	},
	{
		path: "",
		redirectTo: "home",
		pathMatch: "full"
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
