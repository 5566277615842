import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";

import { IVehicleQuery, VehicleField, VehicleFilterType, VehicleOrderType, VehicleSortType } from "../../core/models/vehicle.model";
import { StorageService } from "../../core/services/storage.service";

@Injectable()
export class VehicleQueryService {
	queryChanges$ = new Subject<IVehicleQuery>();

	private initialQuery: IVehicleQuery = {
		filter: VehicleFilterType.All,
		page: 1,
		order: VehicleOrderType.Ascending,
		search: "",
		sort: VehicleSortType.BrandModel,
		organization: ""
	};

	private query: IVehicleQuery;

	constructor(private storageService: StorageService) {}

	getFilter(): string {
		const filter = [];
		const filterNotArchived = "is_archived[eq]:0";

		if (this.query.filter === VehicleFilterType.All) filter.push(`${filterNotArchived}`);
		else if (this.query.filter === VehicleFilterType.Occasion) filter.push(`${filterNotArchived},is_on_occasionlist[eq]:1`);
		else if (this.query.filter === VehicleFilterType.Published) {
			filter.push(
				`${filterNotArchived},publish_status[ilike]:"%"hexon\":{\"publish_status\":2}%",-publish_status[ilike]:"%"pew\":{\"publish_status\":2}%",-publish_status[ilike]:"%"pew\":{\"publish_status\":3}%",-publish_status[ilike]:"%"hexon\":{\"publish_status\":3}%"`
			);
		} else if (this.query.filter === VehicleFilterType.CompanyStock) filter.push(`${filterNotArchived},is_company_stock[eq]:1`);
		else if (this.query.filter === VehicleFilterType.LastUsed) {
			filter.push(
				`${filterNotArchived},modified_date[ge]:"${moment().subtract(7, "days").toISOString()}"` +
					",-" +
					`created_date[ge]:"${moment().subtract(7, "days").toISOString()}"`
			);
		} else if (this.query.filter === VehicleFilterType.IsBlocked) filter.push("is_blocked[eq]:1");
		else if (this.query.filter === VehicleFilterType.IsArchived) filter.push("is_archived[eq]:1");

		// if (this.query.organization)
		//     filter.push(`organization_id[eq]:${this.query.organization}`);

		return filter.join(",");
	}

	getSort(): string {
		const order = this.query.order === VehicleOrderType.Ascending ? "" : "-";
		if (this.query.sort === VehicleSortType.LicensePlate) return `${order}${VehicleField.LicensePlate},${VehicleField.FormattedLicensePlate}`;

		if (this.query.sort === VehicleSortType.PublicationDate) return `${order}${VehicleField.PublicationDate},${VehicleField.FormattedLicensePlate}`;

		return `${order}${VehicleField.Brand},${VehicleField.Model},${VehicleField.FormattedLicensePlate}`;
	}

	getSearch(): string {
		return this.query.search;
	}

	async init() {
		const query = await this.storageService.get("settings.vehicles.query");
		if (query) {
			this.query = {
				filter: query.filter,
				page: this.initialQuery.page,
				order: query.order,
				search: this.initialQuery.search,
				sort: query.sort,
				organization: query.organization
			};
			this.queryChanges$.next(this.query);
		} else this.setQuery(this.initialQuery);
	}

	setQuery(query: IVehicleQuery) {
		this.storageService.set("settings.vehicles.query", {
			filter: query.filter,
			order: query.order,
			sort: query.sort,
			search: query.search,
			organization: query.organization
		});
		this.query = query;
		this.queryChanges$.next(query);
	}
}
