import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthApiService } from "src/app/core/api/auth-api.service";
import { CrudAccessTypes, IAccessDto } from "src/app/core/models/auth.model";
import { StorageService } from "src/app/core/services/storage.service";

@Injectable({
	providedIn: "root"
})
export class AccessControlService {
	access: IAccessDto;
	initalized: Promise<void>;
	crudAccess: CrudAccessTypes;

	constructor(private authApiService: AuthApiService, private storageService: StorageService, private activatedRoute: ActivatedRoute) {}

	async initAccess() {
		if (this.initalized) return this.initalized;

		if (!this.access) {
			this.initalized = new Promise<void>(async (resolve) => {
				let access = await this.getAccess();

				if (access) {
					this.access = access;
					const hideRelations = (await this.storageService.get("activeEmployee"))?.is_hide_relations_in_app === 1 ? false : true;
					access.access.settings = { hideRelations };
				} else {
					access = await this.authApiService.getAccess().toPromise();
					const hideRelations = (await this.storageService.get("activeEmployee"))?.is_hide_relations_in_app === 1 ? false : true;
					access.access.settings = { hideRelations };
					this.setAccess(access);
				}
				resolve();
			});

			return this.initalized;
		}
	}

	// save access to storage
	setAccess(access: IAccessDto): Promise<any> {
		this.access = access;
		return this.storageService.set("access", access);
	}

	// get access from storage
	getAccess(): Promise<IAccessDto> {
		return this.storageService.get("access");
	}

	// get access from storage
	removeAccess(): Promise<void> {
		this.access = null;
		this.initalized = null;
		return this.storageService.remove("access");
	}

	// check if has the module/component it needs
	// check if module/components has the permission it needs
	async checkAccess(type: string, object: string, permission: string): Promise<boolean> {
		await this.initAccess();
		let hasObject: string;
		if (type === "roles") {
			if (object.includes("|")) {
				let hasRole = false;
				for (const accessRole of object.split("|")) {
					if (accessRole.startsWith("!")) {
						if (accessRole.slice(1) === this.access.role) hasRole = false;
						else {
							hasRole = true;
							return hasRole;
						}
					} else if (accessRole === this.access.role) {
						hasRole = true;
						return hasRole;
					} else hasRole = false;
				}
				return hasRole;
			} else {
				if (object.startsWith("!")) {
					if (object.slice(1) === this.access.role) return false;
					else return true;
				} else if (object === this.access.role) return true;

				return false;
			}
		} else if (type === "settings") {
			if (object === "hideRelations") return this.access.access.settings.hideRelations;
		} else {
			if (object.includes("|")) {
				for (const accessModule of object.split("|"))
					if (this.getCRUDAccess(this.access.access[type][accessModule], permission) === true) hasObject = accessModule;
			} else hasObject = Object.keys(this.access.access[type]).find((accessModule) => object.includes(accessModule));
		}

		if (hasObject) {
			if (permission) return this.getCRUDAccess(this.access.access[type][hasObject], permission);
			else return false;
		}
	}

	// check if you have the permission
	getCRUDAccess(crud: object, permission: string): boolean {
		for (const key of Object.keys(crud)) if (key.includes(permission)) return true;
		return false;
	}
	// check permissions on field
	getFieldAccessDisplay(fieldName: string): boolean {
		// check which component is needed
		const currentComponent = this.activatedRoute.snapshot.children[0].routeConfig.path;
		// get fields related to component
		const fields = Object.entries(this.access.access.fields)
			.map((f) => ({
				field: f[0].slice(f[0].indexOf(".") + 1),
				component: f[1].component,
				crud: Object.entries(f[1]).filter((e) => e[0] !== "component") as unknown as CrudAccessTypes[]
			}))
			.filter((f) => currentComponent.includes(f.component));

		// if there are related fields
		if (fields.length > 0) {
			const fieldIndex = fields.findIndex((f) => f.field === fieldName);
			if (fieldIndex !== -1) {
				if (fields[fieldIndex].crud.length > 1) {
					// search for my current role
					const currentRoleCrud = fields[fieldIndex].crud.filter((f) => f[0] === this.access.role);
					if (currentRoleCrud.length > 0) {
						if (currentRoleCrud[0][1].display !== undefined) return currentRoleCrud[0][1].display;
						else return true;
					} else {
						const defaultCrudAcces = fields[fieldIndex].crud.filter((f) => f[0] === "default")[0];
						if (defaultCrudAcces[1].display !== undefined) return defaultCrudAcces[1].display;
						else return true;
					}
				} else {
					// 1 result only default
					const currentCrudAcces = fields[fieldIndex].crud[0] as CrudAccessTypes;
					if (currentCrudAcces[1].display !== undefined) return currentCrudAcces[1].display;
					else return true;
				}
			}
		}

		// if not present in rbac
		// return true
		return true;
	}

	// check permissions on field
	getFieldAccess(fieldName: string, value?: any): boolean {
		// check which component is needed
		const currentComponent = this.activatedRoute.snapshot.children[0].routeConfig.path;
		// get fields related to component
		const fields = Object.entries(this.access.access.fields)
			.map((f) => ({
				field: f[0].slice(f[0].indexOf(".") + 1),
				component: f[1].component,
				crud: Object.entries(f[1]).filter((e) => e[0] !== "component") as unknown as CrudAccessTypes[]
			}))
			.filter((f) => currentComponent.includes(f.component));

		// if there are related fields
		if (fields.length > 0) {
			const fieldIndex = fields.findIndex((f) => f.field === fieldName);
			if (fieldIndex !== -1) {
				if (fields[fieldIndex].crud.length > 1) {
					// search for my current role
					const currentRoleCrud = fields[fieldIndex].crud.filter((f) => f[0] === this.access.role);
					if (currentRoleCrud.length > 0) {
						if (currentRoleCrud[0][1].write) return currentRoleCrud[0][1].write;
						else if (currentRoleCrud[0][1].readonly) return this.readOnlyExcpetions(fieldName, value, currentRoleCrud[0][1].readonly);
					} else {
						const defaultCrudAcces = fields[fieldIndex].crud.filter((f) => f[0] === "default")[0];
						if (defaultCrudAcces[1].write !== undefined) return defaultCrudAcces[1].write;
						else if (defaultCrudAcces[1].readonly !== undefined) return this.readOnlyExcpetions(fieldName, value, defaultCrudAcces[1].readonly);
					}
				} else {
					// 1 result only default
					const currentCrudAcces = fields[fieldIndex].crud[0] as CrudAccessTypes;
					if (currentCrudAcces[1].write !== undefined) return currentCrudAcces[1].write;
					else if (currentCrudAcces[1].readonly !== undefined) return this.readOnlyExcpetions(fieldName, value, currentCrudAcces[1].readonly);
				}
			}
		}

		// if not present in rbac
		// return false
		return false;
	}

	readOnlyExcpetions(fieldName: string, value: any, crud: boolean) {
		if (fieldName === "is_archived" || fieldName === "is_blocked") {
			if (value) {
				switch (this.access.role) {
					case "owner":
					case "administrator":
						return false;
					default:
						return crud;
				}
			}
		} else return crud;
	}
}
