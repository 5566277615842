import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { WorkorderDataService } from "../workorders/shared/workorder-data.service";
import { EmployeeQueryService } from "../employee/shared/employee-query.service";
import { ActionSheetComponent } from "./components/action-sheet/action-sheet.component";
import { ActionSheetService } from "./components/action-sheet/action-sheet.service";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { BarcodeScannerActionSheetComponent } from "./components/barcode-scanner-action-sheet/barcode-scanner-action-sheet.component";
import { BarcodeScannerComponent } from "./components/barcode-scanner/barcode-scanner.component";
import { BrandImageComponent } from "./components/brand-image/brand-image.component";
import { ChecklistModalComponent } from "./components/checklist-modal/checklist-modal.component";
import { ChecklistComponent } from "./components/checklist/checklist.component";
import { CollapsibleListComponent } from "./components/collapsible-list/collapsible-list.component";
import { FeedbackModalComponent } from "./components/feedback-modal/feedback-modal.component";
import { FilterDateNavigationComponent } from "./components/filter-date-navigation/filter-date-navigation.component";
import { FilterSearchComponent } from "./components/filter-search/filter-search.component";
import { HeaderPopoverButtonComponent } from "./components/header-popover-button/header-popover-button.component";
import { HeaderPopoverComponent } from "./components/header-popover/header-popover.component";
import { HelpModalComponent } from "./components/help-modal/help-modal.component";
import { HistoryComponent } from "./components/history/history.component";
import { InputCurrencyComponent } from "./components/input-currency/input-currency.component";
import { InputDateTimeModalComponent } from "./components/input-datetime-modal/input-datetime-modal.component";
import { InputDateTimeComponent } from "./components/input-datetime/input-datetime.component";
import { InputDecimalComponent } from "./components/input-decimal/input-decimal.component";
import { InputNumberComponent } from "./components/input-number/input-number.component";
import { InputQuantityComponent } from "./components/input-quantity/input-quantity.component";
import { InputSelectComponent } from "./components/input-select/input-select.component";
import { LookupItemComponent } from "./components/lookup-item/lookup-item.component";
import { ModelInputItemComponent } from "./components/model-input-item/model-input-item.component";
import { ModelLookupListComponent } from "./components/model-lookup-list/model-lookup-list.component";
import { ModelOutputGridComponent } from "./components/model-output-grid/model-output-grid.component";
import { ModelOutputItemComponent } from "./components/model-output-item/model-output-item.component";
import { ModelOutputListComponent } from "./components/model-output-list/model-output-list.component";
import { NavigateButtonsComponent } from "./components/navigate-buttons/navigate-buttons.component";
import { OrganizationActionSheetComponent } from "./components/organization-action-sheet/organization-action-sheet.component";
import { PictureComponent } from "./components/picture/picture.component";
import { PicturesAddActionSheetComponent } from "./components/pictures-add-actionsheet/pictures-add-actionsheet.component";
import { PostalcodeCheckModalComponent } from "./components/postalcode-check-modal/postalcode-check-modal.component";
import { ReleaseNotesModalComponent } from "./components/release-notes-modal/release-notes-modal.component";
import { ResetPasswordModalComponent } from "./components/reset-password-modal/reset-password-modal.component";
import { SwitchItemComponent } from "./components/switch-item/switch-item.component";
import { UpdateModalComponent } from "./components/update-modal/update-modal.component";
import { WorkorderAddModalComponent } from "./components/workorder-add-modal/workorder-add-modal.component";
import { WorkorderMechanicItemComponent } from "./components/workorder-mechanic-item/workorder-mechanic-item.component";
import { AccessControlDirective } from "./directives/access-control/access-control.directive";
import { HideWhenKeyboardVisibleDirective } from "./directives/hide-when-keyboard-visible/hide-when-keyboard-visible.directive";
import { PictureDirective } from "./directives/picture/picture.directive";
import { JoinPipe } from "./pipes/join/join.pipe";
import { ListPipe } from "./pipes/list/list.pipe";
import { SortByPipe } from "./pipes/sortby/sortby.pipe";
import { PictureAddActionsheetButtonComponent } from "./components/picture-add-actionsheet-button/picture-add-actionsheet-button.component";
import { OrderLinePictureService } from "../workorders/shared/orderline-picture.service";
import { timesheetNotBillableButtonComponent } from "./components/not-billable-button/timesheet-not-billable-button.component";
import { LongPressDirective } from "./directives/long-press/long-pres.directive";
import { WarningNoteComponent } from "./components/warning-note/warning-note.component";
import { NotificationBadgeComponent } from "./components/notification-badge/notification-badge.component";
import { NotificationAddModalComponent } from "./components/notification-add-modal/notification-add-modal.component";
import { NotificationItemComponent } from "./components/notification-item/notification-item.component";
import { NotificationEmployeeItemComponent } from "./components/notification-employee-item/notification-employee-item.component";
import { NotificationSelectEmployeeModalComponent } from "./components/notification-select-employee-modal/notification-select-employee-modal.component";
import { NotificationSelectEmployeeItemComponent } from "./components/notification-select-employee-item/notification-select-employee-item.component";
import { NotificationDetailModalComponent } from "./components/notification-detail-modal/notification-detail-modal.component";
import { NotificationFilterSortModalComponent } from "./components/notification-filter-sort-modal/notification-filter-sort-modal.component";
import { NotificationQueryService } from "../core/services/notification-query.service";
import { NotificationDataService } from "../core/services/notification-data.service";

@NgModule({
	providers: [
		ActionSheetService,
		DatePipe,
		DecimalPipe,
		CurrencyPipe,
		ListPipe,
		SortByPipe,
		WorkorderDataService,
		PicturesAddActionSheetComponent,
		WorkorderMechanicItemComponent,
		NotificationEmployeeItemComponent,
		NotificationDataService,
		NotificationQueryService,
		BarcodeScannerActionSheetComponent,
		OrderLinePictureService,
		EmployeeQueryService,
		WarningNoteComponent
	],
	declarations: [
		ActionSheetComponent,
		PicturesAddActionSheetComponent,
		BrandImageComponent,
		BarcodeScannerComponent,
		BarcodeScannerActionSheetComponent,
		CollapsibleListComponent,
		FeedbackModalComponent,
		OrganizationActionSheetComponent,
		ChecklistComponent,
		HeaderPopoverButtonComponent,
		HeaderPopoverComponent,
		HelpModalComponent,
		PostalcodeCheckModalComponent,
		UpdateModalComponent,
		HideWhenKeyboardVisibleDirective,
		AccessControlDirective,
		JoinPipe,
		ListPipe,
		LongPressDirective,
		SortByPipe,
		ModelInputItemComponent,
		ModelOutputItemComponent,
		LookupItemComponent,
		SwitchItemComponent,
		InputCurrencyComponent,
		InputNumberComponent,
		InputDecimalComponent,
		InputQuantityComponent,
		InputSelectComponent,
		InputDateTimeComponent,
		ModelOutputListComponent,
		ModelOutputGridComponent,
		ModelLookupListComponent,
		ChecklistModalComponent,
		PictureComponent,
		AvatarComponent,
		HistoryComponent,
		PictureDirective,
		ReleaseNotesModalComponent,
		ResetPasswordModalComponent,
		NavigateButtonsComponent,
		NotificationAddModalComponent,
		NotificationDetailModalComponent,
		NotificationItemComponent,
		NotificationBadgeComponent,
		WorkorderAddModalComponent,
		NotificationEmployeeItemComponent,
		NotificationFilterSortModalComponent,
		NotificationSelectEmployeeModalComponent,
		NotificationSelectEmployeeItemComponent,
		WorkorderMechanicItemComponent,
		FilterSearchComponent,
		InputDateTimeModalComponent,
		PictureAddActionsheetButtonComponent,
		FilterDateNavigationComponent,
		timesheetNotBillableButtonComponent,
		WarningNoteComponent
	],
	imports: [CommonModule, IonicModule, FontAwesomeModule, ReactiveFormsModule, ScrollingModule],
	exports: [
		ActionSheetComponent,
		PicturesAddActionSheetComponent,
		BrandImageComponent,
		CollapsibleListComponent,
		HeaderPopoverButtonComponent,
		HideWhenKeyboardVisibleDirective,
		AccessControlDirective,
		JoinPipe,
		ListPipe,
		SortByPipe,
		ModelInputItemComponent,
		LookupItemComponent,
		SwitchItemComponent,
		InputCurrencyComponent,
		InputNumberComponent,
		InputDecimalComponent,
		InputQuantityComponent,
		InputSelectComponent,
		InputDateTimeComponent,
		ModelOutputListComponent,
		ModelOutputGridComponent,
		ModelLookupListComponent,
		ChecklistModalComponent,
		PostalcodeCheckModalComponent,
		NavigateButtonsComponent,
		PictureComponent,
		AvatarComponent,
		HistoryComponent,
		PictureDirective,
		WorkorderAddModalComponent,
		WorkorderMechanicItemComponent,
		FeedbackModalComponent,
		NotificationAddModalComponent,
		NotificationDetailModalComponent,
		NotificationItemComponent,
		NotificationBadgeComponent,
		NotificationEmployeeItemComponent,
		OrganizationActionSheetComponent,
		ChecklistComponent,
		FilterSearchComponent,
		InputDateTimeModalComponent,
		PictureAddActionsheetButtonComponent,
		FilterDateNavigationComponent,
		timesheetNotBillableButtonComponent,
		WarningNoteComponent
	],
})
export class SharedModule {}
