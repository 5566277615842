<ion-header>
	<ion-toolbar color="primary" mode="md">
		<ion-buttons slot="start">
			<ion-button (click)="dismiss()" data-test="dismiss-button">
				<fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" size="lg" slot="icon-only"></fa-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>Bericht versturen</ion-title>
		<ion-buttons slot="end">
			<af-header-popover-button [disableNotificationButton]="true" [componentName]="this.constructor.name"></af-header-popover-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<p *ngIf="isLoadingEmployees">Bezig met ophalen medewerkers...</p>
	<form (ngSubmit)="submit()" [formGroup]="form" *ngIf="!isLoadingEmployees">
		<ion-list class="ion-no-margin ion-no-padding">
			<!-- <ion-item>
                <ion-input label="Titel" autofocus="true" formControlName="notification_title" data-test="detail-textarea"></ion-input>
            </ion-item> -->
			<ion-list *ngIf="replyToNotificationId" class="ion-no-margin ion-no-padding">
				<ion-list-header color="medium">Beantwoorden aan</ion-list-header>
				<ion-spinner *ngIf="isLoading" class="ion-margin-start"></ion-spinner>
				<af-notification-item *ngIf="!isLoading && replyToNotification" [notification]="replyToNotification" usedInView="detail"></af-notification-item>
			</ion-list>
			<ion-item>
				<ion-label class="ion-text-wrap" position="floating">Inhoud</ion-label>
				<ion-textarea autofocus formControlName="notification_body" rows="5" data-test="detail-textarea"></ion-textarea>
			</ion-item>
			<af-notification-employee-item
				[(selectedEmployees)]="selectedEmployees"
				[(ordersToEmployeeDelay)]="ordersToEmployeeDelay"
				usedInView="add"
				class="notification-employee-item"
			></af-notification-employee-item>
			<!-- <ion-list>
                <ion-item>
                    <ion-select label="Verstuur naar medewerkers" formControlName="selected_employees" multiple="true" aria-label="employees">
                        <ion-select-option *ngFor="let employee of employees" [value]="employee.svy_user_id">{{ employee.v_display_name }}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-list> -->
		</ion-list>
	</form>
</ion-content>

<ion-footer class="ion-padding">
	<ion-row>
		<ion-col>
			<ion-button (click)="dismiss()" class="ion-no-margin" expand="block" type="button" data-test="cancel-button"> Annuleren </ion-button>
		</ion-col>
		<ion-col>
			<ion-button
				(click)="submit()"
				[disabled]="isSubmitting || isLoading || !this.selectedEmployees?.length || this.form?.invalid"
				class="ion-no-margin"
				expand="block"
				data-test="submit-button"
			>
				<fa-icon [fixedWidth]="true" [icon]="['fas', 'envelope']" class="ion-margin-end"></fa-icon>
				Verzend
				<!-- <ion-spinner *ngIf="isSubmitting" class="ion-margin-start"></ion-spinner> -->
			</ion-button>
		</ion-col>
	</ion-row>
</ion-footer>
