import { Injectable } from "@angular/core";
import { Route, Router, UrlSegment } from "@angular/router";
import { AccessControlService } from "src/app/shared/services/access-control.service";

import { AuthService } from "../services/auth.service";
import { ToastService } from "../services/toast.service";

@Injectable({
	providedIn: "root"
})
export class AuthenticationGuard  {
	constructor(
		private authService: AuthService,
		private router: Router,
		private accessControlService: AccessControlService,
		private toastService: ToastService
	) {}

	async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		const loggedIn = await this.authService.isLoggedIn();
		if (loggedIn) {
			this.authService.loggedInFunction();
			if (await this.hasAccess(route)) return true;
			else {
				this.toastService.presentFailureToast("Onvoldoende rechten");
				this.router.navigate(["/home"], { replaceUrl: true });
				return false;
			}
		}

		this.authService.redirectUrl = `/${segments.join("/")}`;
		this.router.navigate(["/login"], { replaceUrl: true });
		return false;
	}

	async hasAccess(route: Route): Promise<boolean> {
		if (route.data)
			return await this.accessControlService.checkAccess(Object.keys(route.data)[0], route.data[Object.keys(route.data)[0]], route.data.permission);

		// TODO temp fix until we have basic module implemented
		return true;
	}
}
