import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { EmployeeField, IEmployeeDto, IEmployeeEmailResponse, IEmployeeResponse, IEmployeesResponse } from "../models/employee.model";
import { EnvironmentService } from "../services/environment.service";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root"
})
export class EmployeeApiService {
	constructor(private apiService: ApiService, private environmentService: EnvironmentService, private httpClient: HttpClient) {}

	getActiveEmployee(fields: EmployeeField[]): Observable<IEmployeeResponse> {
		let httpParams = new HttpParams();
		if (fields.length) httpParams = httpParams.set("fields", fields.join(","));

		return this.httpClient.get<IEmployeeResponse>(`${this.environmentService.apiUrl}/employee/active`, { params: httpParams });
	}

	getActiveEmployeeModel(fields: string[]): Observable<IEmployeeResponse> {
		let httpParams = new HttpParams();
		if (fields.length) httpParams = httpParams.set("fields", fields.join(","));

		return this.httpClient.get<IEmployeeResponse>(`${this.environmentService.apiUrl}/employee/active`, { params: httpParams });
	}

	getEmployee(id: string, fields: string[]): Observable<IEmployeeResponse> {
		let httpParams = new HttpParams();
		if (fields.length) httpParams = httpParams.set("fields", fields.join(","));

		return this.httpClient.get<IEmployeeResponse>(`${this.environmentService.apiUrl}/employee/${id}`, { params: httpParams });
	}

	getEmployees(page: number, fields: EmployeeField[], filter?: string, sort?: string, search?: string): Observable<IEmployeesResponse> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("page", page + "");
		httpParams = httpParams.set("fields", fields.join(","));
		if (filter) httpParams = httpParams.set("filter", filter);

		if (sort) httpParams = httpParams.set("sort", sort);

		if (search) httpParams = httpParams.set("search", search);

		return this.httpClient.get<IEmployeesResponse>(`${this.environmentService.apiUrl}/employee`, { params: httpParams });
	}

	getEmployeeAvatar(employeeId: string): Observable<Blob> {
		const httpParams = new HttpParams();

		return this.httpClient.get(`${this.environmentService.apiUrl}/employee/${employeeId}/avatar`, {
			params: httpParams,
			responseType: "blob"
		});
	}

	getEmployeeEmail(username: string, apiUrl?: string): Observable<IEmployeeEmailResponse> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("username", username);
		httpParams = httpParams.set("needsAuthentication", "false");

		return this.httpClient.get<any>(`${apiUrl ?? this.apiService.apiUrl}/employee/email`, { params: httpParams });
	}

	editEmployee(id: string, employee: IEmployeeDto) {
		return this.httpClient.put(`${this.environmentService.apiUrl}/employee/${id}`, employee);
	}
}
