import { Pipe, PipeTransform } from "@angular/core";

import { IList } from "../../../core/models/list.model";

@Pipe({
    name: "list"
})
export class ListPipe implements PipeTransform {
    transform(value: string | number, list: IList): any {
        if (list && list.object[value]) 
            return list.object[value];
        
        return null;
    }
}
