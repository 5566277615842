import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { toLower, startsWith } from "lodash-es"
import { from, throwError } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";
import { AuthApiService } from "../api/auth-api.service";

import { AuthService } from "../services/auth.service";
import { StorageService } from "../services/storage.service";
import pLimit from "p-limit";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	limit = pLimit(1);
	tokenValidated = false;
	constructor(private authService: AuthService, private authApiService: AuthApiService, private storageService: StorageService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler) {
		const handler = from(this.handler(request, next)).pipe(
			switchMap(async (req) => req),
			catchError((error) => from(this.errorHandler(error, request, next)).pipe(switchMap(async (req) => req))),
			finalize(() => (this.tokenValidated = false))
		);
		return handler;
	}

	handler(request: HttpRequest<any>, next: HttpHandler) {
		let req = request.clone();
		if (req.params.has("needsAuthentication") && req.params.get("needsAuthentication") === "false") {
			req = request.clone({ params: request.params.delete("needsAuthentication") });
			return next.handle(req);
		} else {
			const tokenValidUntilAsString = this.authService.tokenValidUntil,
				authToken = this.authService.authToken;
			if (tokenValidUntilAsString) {
				const tokenValidUntilAsMoment = moment(tokenValidUntilAsString);
				if (
					authToken &&
					tokenValidUntilAsMoment &&
					moment().isAfter(moment(tokenValidUntilAsMoment).subtract(5, "minutes")) &&
					moment().isBefore(tokenValidUntilAsMoment)
				) {
					this.limit(async () => {
						if (!this.tokenValidated) {
							const httpRequest = await this.refreshAuthTokenHandler(request, next);
							this.tokenValidated = true;
							return next.handle(httpRequest);
						}
					});
				}
				return next.handle(this.addToken(req));
			}
			return next.handle(this.addToken(req));
		}
	}

	refreshAuthTokenHandler(request: HttpRequest<any>, next: HttpHandler) {
		const promise = new Promise<HttpRequest<any>>(async (resolve) => {
			await this.updateTokenInformation();
			let req = request;
			// await this.updateTokenInformation();
			req = await this.refreshToken(request, next);
			resolve(req);
		});
		return promise;
	}

	/**
	 * Called on every error returned by every API request, including assets (images)
	 */
	errorHandler(error: any, request: HttpRequest<any>, next: HttpHandler) {
		const promise = new Promise<HttpEvent<any>>(async (resolve, reject) => {
			// We have to check for error.error.errorMessage because a 404 could also mean "not found"
			if (error.status === 404 && startsWith(toLower(error.error.errorMessage), "token_id not found")) {
				await this.clearStorage();
				resolve(next.handle(request).toPromise());
			// We have to check for error.error.errorMessage because a 401 could also mean "unauthenticated"
			} else if (error.status === 401 && startsWith(toLower(error.error.errorMessage), "token invalid")) {
				await this.limit(async () => {
					let req = request;

					req = await this.validateToken(request, next);
					resolve(next.handle(req).toPromise());
				});
			} else resolve(throwError(error).toPromise());
		});
		return promise;
	}

	/**
	 * Remove all items from Storage except username, password & fingerprint,
	 * then reloads the window.
	 * 
	 * This effectively logs the user out.
	 */
	async clearStorage() {
		const username = await this.storageService.get("username"),
			password = await this.storageService.get("password"),
			fingerprint = await this.storageService.get("fingerprint"),
			darkTheme = await this.storageService.get("darkTheme"),
			autoTheme = await this.storageService.get("autoTheme");
		await this.storageService.clearStorage();
		this.authService.loggedIn.next(false);
		await this.storageService.set("darkTheme", darkTheme);
		await this.storageService.set("autoTheme", autoTheme);
		await this.storageService.set("username", username);
		await this.storageService.set("password", password);
		await this.storageService.set("fingerprint", fingerprint);
		window.location.reload();
	}

	async validateToken(request?: HttpRequest<any>, next?: HttpHandler) {
		const promise = new Promise<HttpRequest<any>>(async (resolve) => {
			await this.updateTokenInformation();
			const tokenValidUntilAsString = await this.authService.getTokenValidUntil(),
				loginParams = await this.authService.getLoginParams(),
				authToken = await this.authService.getAuthToken();
			if (tokenValidUntilAsString) {
				const tokenValidUntilAsMoment = moment(tokenValidUntilAsString);
				if (
					authToken &&
					tokenValidUntilAsMoment &&
					loginParams &&
					moment().isAfter(moment(tokenValidUntilAsMoment).subtract(5, "minutes")) &&
					moment().isBefore(tokenValidUntilAsMoment)
				) {
					await this.refreshAuthTokenHandler(request, next);
					resolve(this.addToken(request));
				} else if (authToken && tokenValidUntilAsMoment && moment().isBefore(moment(tokenValidUntilAsMoment))) resolve(this.addToken(request));
				else if (
					moment().isAfter(moment(tokenValidUntilAsMoment)) &&
					loginParams &&
					loginParams.username &&
					(loginParams.password || loginParams.fingerprint)
				) {
					await this.reAuthenticate();
					resolve(this.addToken(request));
				}
			} else {
				await this.clearStorage();
				resolve(this.addToken(request));
			}
		});

		return promise;
	}

	async updateTokenInformation() {
		const promise = new Promise<void>(async (resolve) => {
			const authToken = await this.authService.getAuthToken();
			if (authToken) {
				const tokenResponse = await this.authService.checkLoggedInToken(authToken);
				if (tokenResponse) {
					await this.authService.setTokenValidUntil(tokenResponse.token_valid_until);
					await this.authService.setAuthToken(tokenResponse.token);
					resolve();
				} else {
					await this.clearStorage();
					resolve();
				}
			} else {
				await this.clearStorage();
				resolve();
			}
		});
		return promise;
	}

	refreshToken(request?: HttpRequest<any>, next?: HttpHandler) {
		const promise = new Promise<HttpRequest<any>>(async (resolve) => {
			const authToken = await this.authService.getAuthToken();
			if (authToken) {
				const tokenResponse = await this.authService.refreshAuthToken();
				if (tokenResponse) {
					await this.authService.setTokenValidUntil(tokenResponse.token_valid_until);
					await this.authService.setAuthToken(tokenResponse.token);
					resolve(this.addToken(request));
				}
			}
		});
		return promise;
	}

	reAuthenticate() {
		const promise = new Promise<void>(async (resolve) => {
			const loginParams = await this.authService.getLoginParams();
			if (loginParams && loginParams.username && (loginParams.password || loginParams.fingerprint)) {
				const authToken = await this.authService.getAuthToken();
				if (authToken) {
					const authPromise = await this.authApiService.logout(authToken).toPromise();

					if (authPromise) {
						await this.authService.setTokenValidUntil("");
						await this.authService.setAuthToken("");
						await this.authService.login(loginParams);
						resolve();
					} else {
						this.clearStorage();
						resolve();
					}
				} else {
					this.clearStorage();
					resolve();
				}
			} else {
				this.clearStorage();
				resolve();
			}
		});
		return promise;
	}

	private addToken(request: HttpRequest<any>) {
		const token = this.authService.authToken;
		if (token) return request.clone({ setHeaders: { token } });
		return request;
	}
}
