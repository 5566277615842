export enum ContactField {
	Address = "address",
	City = "city",
	CompanyName = "company_name",
	ContactId = "contact_id",
	ContactNumber = "contact_number",
	ContactType = "contact_type",
	CountryId = "country_id",
	HouseNumber = "house_number",
	HouseNumberAdd = "house_number_add",
	Initials = "initials",
	LastName = "lastname",
	MiddleName = "middlename",
	PostalCode = "postalcode",
	ShortName = "shortname",
	VehicleIds = "vehicle_ids",
	Archived = "is_archived",
	Blocked = "is_blocked",
	VDisplayName = "v_display_name",
	OpenAmount = "open_amount",
	isCollectiveInvoicePreference = "is_collective_invoice_preference",
	OrganizationId = "organization_id",
	IsShowNoteAsWarning = "is_show_note_as_warning",
	Notes = "notes"
}

export enum ContactFilterType {
	All,
	Blocked,
	Archived,
	Company,
	PrivateCustomer,
	Provider,
	LastUsed
}

export enum ContactOrderType {
	Ascending,
	Descending
}

export enum ContactSortType {
	LastName,
	ShortName,
	ContactNumber
}

export interface IContactDto {
	contact_id?: string;
	[key: string]: any;
}

export interface IContactQuery {
	filter: ContactFilterType;
	page: number;
	order: ContactOrderType;
	search: string;
	sort: ContactSortType;
	organization: string;
}

export interface IContactResponse {
	data: IContactDto[];
	maxPages: number;
}

export interface IContactsResponse {
	currentPage: number;
	data: IContactDto[];
	maxPages: number;
}

export enum ContactRemoveInUseError {
	purchases = "Inkoop voertuig",
	articles = "Onderdelen",
	banks = "Banken",
	catalogs = "Grossiers",
	contacts = "Relaties",
	open_items = "Openstaande posten",
	orders = "Orders",
	vehicle_role_lines = "Voertuigrollen",
	order_lines = "Orderregels",
	settings = "Instellingen",
	vehicles = "Voertuigen",
	contact_documents = "Documenten",
	contact_communications = "Contact mogelijkheden",
	contact_histories = "Historie",
	external_ids = "Externe ID's",
	emails_sent = "Verzonden e-mails",
	notifications = "Notificaties"
}
