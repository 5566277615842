import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { AlertController } from "@ionic/angular";

import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: "root"
})
export class AuthorizationGuard  {
    constructor(private alertController: AlertController, private authService: AuthService) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (this.authService.hasModules(route.data.modules)) 
            return true;
        

        const alert = await this.alertController.create({
            header: "Geen toegang",
            message: "De publicatie module is niet actief",
            buttons: [
                { text: "Annuleren", role: "cancel" },
                {
                    text: "Contact",
                    handler: () => {
                        window.location.href = "https://www.autoflex.nl/contact";
                    }
                }
            ]
        });
        await alert.present();
        return false;
    }
}
