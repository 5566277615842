import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IFeedback } from "../models/service-desk.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class ServiceDeskApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addFeedback(feedback: IFeedback): Observable<any> {
        return this.httpClient.post<any>(`${this.environmentService.apiUrl}/servicedesk/feedback`, feedback);
    }
}
