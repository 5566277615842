import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";

import { ContactField, ContactFilterType, ContactOrderType, ContactSortType, IContactQuery } from "../../core/models/contact.model";
import { StorageService } from "../../core/services/storage.service";

@Injectable()
export class ContactQueryService {
	queryChanges$ = new Subject<IContactQuery>();

	private initialQuery: IContactQuery = {
		filter: ContactFilterType.All,
		page: 1,
		order: ContactOrderType.Ascending,
		search: "",
		sort: ContactSortType.ShortName,
		organization: ""
	};
	private query: IContactQuery;

	constructor(private storageService: StorageService) {}

	getFilter(): string {
		const filter = [];
		const filterNotArchived = "(is_archived[eq]:0,-is_archived[eq]:null)";
		if (this.query.filter === ContactFilterType.All) filter.push(`${filterNotArchived}`);
		else if (this.query.filter === ContactFilterType.Blocked) filter.push("is_blocked[eq]:1");
		else if (this.query.filter === ContactFilterType.Archived) filter.push("is_archived[eq]:1");
		else if (this.query.filter === ContactFilterType.Company) filter.push(`${filterNotArchived},contact_type[eq]:2`);
		else if (this.query.filter === ContactFilterType.PrivateCustomer) filter.push(`${filterNotArchived},contact_type[eq]:1`);
		else if (this.query.filter === ContactFilterType.Provider) filter.push(`${filterNotArchived},contact_type[eq]:4`);
		else if (this.query.filter === ContactFilterType.LastUsed) {
			filter.push(
				`${filterNotArchived},modified_date[ge]:"${moment().subtract(7, "days").toISOString()}"` +
					",-" +
					`created_date[ge]:"${moment().subtract(7, "days").toISOString()}"`
			);
		}

		// if (this.query.organization)
		//     filter.push(`organization_id[eq]:${this.query.organization}`);

		return filter.join(",");
	}

	getSort(): string {
		const order = this.query.order === ContactOrderType.Ascending ? "" : "-";
		if (this.query.sort === ContactSortType.ShortName) return `${order}${ContactField.ShortName},${ContactField.ContactNumber}`;

		if (this.query.sort === ContactSortType.ContactNumber) return `${order}${ContactField.ContactNumber}`;

		return `${order}${ContactField.LastName},${ContactField.ContactNumber}`;
	}

	getSearch(): string {
		return this.query.search;
	}

	async init() {
		const query = await this.storageService.get("settings.contacts.query");
		if (query) {
			this.query = {
				filter: query.filter,
				page: this.initialQuery.page,
				order: query.order,
				search: this.initialQuery.search,
				sort: query.sort,
				organization: query.organization
			};
			this.queryChanges$.next(this.query);
		} else this.setQuery(this.initialQuery);
	}

	setQuery(query: IContactQuery) {
		this.storageService.set("settings.contacts.query", {
			filter: query.filter,
			order: query.order,
			sort: query.sort,
			search: query.search,
			organization: query.organization
		});
		this.query = query;
		this.queryChanges$.next(query);
	}
}
