<ng-container
    *ngTemplateOutlet="asyncTemplate;context:{unreadNotifications$: notificationApiService.unreadNotificationCountObservable | async}">
</ng-container>

<ng-template #asyncTemplate let-unreadNotifications="unreadNotifications$">
    <ion-badge 
        class="notification-badge" 
        color="danger"
        *ngIf="unreadNotifications > 0"
    >
        {{ unreadNotifications }}
    </ion-badge>
</ng-template>