import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class LoadingService {
    loading;

    constructor(private loadingConroller: LoadingController) {}

    async present(message: string) {
        this.loading = await this.loadingConroller.create({ message });
        await this.loading.present();
    }

    async dismiss() {
        await this.loading.dismiss();
    }
}
