<ion-header>
	<ion-toolbar color="primary" mode="md">
		<ion-buttons slot="start">
			<ion-button (click)="dismiss(false)" data-test="dismiss-button">
				<fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" size="lg" slot="icon-only"></fa-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>Medewerker selecteren</ion-title>
		<ion-buttons slot="end">
			<af-header-popover-button [componentName]="this.constructor.name" [disableNotificationButton]="true"></af-header-popover-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content #content [scrollEvents]="true" (ionScroll)="scroll($event)" color="medium" class="ion-no-padding">
	<ion-refresher (ionRefresh)="refresh($event)" slot="fixed" pullFactor="0.4">
		<ion-refresher-content pullingIcon="refresh-circle-outline"></ion-refresher-content>
	</ion-refresher>

	<af-filter-search [query]="query" module="employee"></af-filter-search>
	<div *ngIf="!isLoading && employees.length === 0" class="ion-padding ion-text-center">Geen monteurs gevonden</div>

	<ng-container *ngFor="let mechanicOrOther of viewPortEmployees; let i = index">
		<ion-item-divider *ngIf="headerDivider()(mechanicOrOther, i, viewPortEmployees)" color="secondary">
			{{ mechanicOrOther.type }}
		</ion-item-divider>

		<div class="employee-item-div">
			<af-notification-select-employee-item
				[employee]="mechanicOrOther"
				[organization]="getAllOrganizations(mechanicOrOther.organization_id)"
				(select)="onSelectEmployee(mechanicOrOther)"
				(delete)="onRemoveEmployee(mechanicOrOther)"
				[disabled]="isDisabled(mechanicOrOther)"
				[isSelected]="isSelected(mechanicOrOther)"
				data-test="dismiss-button"
			></af-notification-select-employee-item>
		</div>
	</ng-container>

	<ion-infinite-scroll *ngIf="!isLastPage && !isLoading" threshold="25%" (ionInfinite)="loadData($event)">
		<ion-infinite-scroll-content loadingSpinner="circles" loadingText="Laden.."> </ion-infinite-scroll-content>
	</ion-infinite-scroll>

	<ion-fab horizontal="end" slot="fixed" vertical="bottom" [ngClass]="showHideFab()">
		<ion-fab-button (click)="scrollToTop()" data-test="scrolltop-button">
			<fa-icon [fixedWidth]="true" [icon]="['fal', 'arrow-to-top']" size="lg"></fa-icon>
		</ion-fab-button>
	</ion-fab>
	<div *ngIf="isLoading" class="ion-padding ion-text-center">
		<ion-spinner duration="500"></ion-spinner>
	</div>
</ion-content>
