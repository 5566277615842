import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IVehicleOptionAddDto, IVehicleOptionEditDto, IVehicleOptionsResponse } from "../models/vehicle-option.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class VehicleOptionApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addVehicleOptions(vehicleId: string, vehicleOptions: IVehicleOptionAddDto[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("vehicle_id", vehicleId);

        return this.httpClient.post(`${this.environmentService.apiUrl}/vehicleoption`, vehicleOptions, { params: httpParams });
    }

    editVehicleOptions(vehicleOptions: IVehicleOptionEditDto[]) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicleoption`, vehicleOptions);
    }

    getVehicleOptions(vehicleId: string): Observable<IVehicleOptionsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("vehicle_id", vehicleId);

        return this.httpClient.get<IVehicleOptionsResponse>(`${this.environmentService.apiUrl}/vehicleoption`, { params: httpParams });
    }
}
