import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IVehicleTyreDto, IVehicleTyreResponse, IVehicleTyresResponse } from "../models/vehicletyre.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class VehicleTyreApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getVehicleTyre(id: string, fields: string[]): Observable<IVehicleTyreResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IVehicleTyreResponse>(`${this.environmentService.apiUrl}/vehicletyre/${id}`, { params: httpParams });
    }

    getVehicleTyres(page: number, fields: string[], filter?: string, sort?: string, search?: string): Observable<IVehicleTyresResponse> {
        let httpParams = new HttpParams();
        if (page) 
            httpParams = httpParams.set("page", page + "");
        
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IVehicleTyresResponse>(`${this.environmentService.apiUrl}/vehicletyre`, { params: httpParams });
    }

    addVehicleTyre(vehicletyre: IVehicleTyreDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/vehicletyre`, vehicletyre);
    }
    editVehicleTyre(id: string, vehicletyre: IVehicleTyreDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicletyre/${id}`, vehicletyre);
    }
    deleteVehicleTyre(id: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/vehicletyre/${id}`);
    }
}
