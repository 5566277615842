<ion-header>
	<ion-toolbar color="primary" mode="md">
		<ion-buttons slot="start">
			<ion-button (click)="dismiss()" data-test="dismiss-button">
				<fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" size="lg" slot="icon-only"></fa-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>
			<span>Discussie</span>
		</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content #content class="notification-detail-modal__content">
	<!-- <ion-list-header color="medium">Ontvangers {{replyThreadId}}</ion-list-header> -->
	<ion-item slot="fixed" class="maxHeight notification-detail-modal__receiver">
		<ng-container *ngFor="let receiver of receiverEmployees">
			<af-avatar [id]="'avatar-tooltip' + receiver.employee_id" class="avatar" [employeeId]="receiver.employee_id"></af-avatar>
			<ion-popover [trigger]="'avatar-tooltip' + receiver.employee_id" triggerAction="click">
				<ng-template>
					<ion-content class="ion-padding">{{ receiver.v_display_name }}</ion-content>
				</ng-template>
			</ion-popover>
		</ng-container>
		<!-- <ion-text>{{ receiver.v_display_name }} <ion-text *ngIf="receiver.is_read" color="grey">Gelezen</ion-text></ion-text> -->
	</ion-item>

	<!-- <ion-list-header color="medium">Bericht</ion-list-header> -->
	<ion-spinner *ngIf="isLoading" class="ion-margin-start"></ion-spinner>
	<ion-list *ngIf="!isLoading && threadNotifications" class="ion-no-margin ion-no-padding">
		<ng-container *ngFor="let related of threadNotifications">
			<af-notification-item [notification]="related" usedInView="detail"></af-notification-item>
		</ng-container>
	</ion-list>
</ion-content>

<ion-footer class="reply-footer" mode="md">
	<ion-row>
		<ion-col>
			<form (ngSubmit)="onSendReply()" [formGroup]="replyForm" class="notification-detail-modal__footer-input">
				<ion-textarea
					class="reply-textarea"
					autofocus
					autoGrow
					formControlName="notification_body"
					rows="1"
					data-test="detail-textarea"
					placeholder="Typ een bericht"
				></ion-textarea>
				<div class="notification-detail-modal__footer-input--button">
					<ion-button [disabled]="replyForm.invalid || isSubmitting || isLoading" type="submit" color="success" data-test="send-reply-button">
						<fa-icon *ngIf="!isSubmitting" [fixedWidth]="true" color="white" [icon]="['fas', 'paper-plane']" size="lg" slot="icon-only"></fa-icon>
						<ion-spinner *ngIf="isSubmitting"></ion-spinner>
					</ion-button>
				</div>
			</form>
		</ion-col>
	</ion-row>
</ion-footer>
