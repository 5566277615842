import { Injectable } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { IField, IGroup } from "../models/model.model";

@Injectable({
    providedIn: "root"
})
export class FormService {
    getFormByFields(fields: IField[], object: any): FormGroup {
        const form = new FormGroup({});
        fields.forEach((field: IField) => {
            form.addControl(field.id, new FormControl(object[field.id], this.getFormControlValidators(field)));
        });
        return form;
    }

    getFormByGroups(groups: IGroup[], object: any): FormGroup {
        const form = new FormGroup({});
        groups.forEach((group: IGroup) => {
            group.fields.forEach((field: IField) => {
                form.addControl(field.id, new FormControl(object[field.id], this.getFormControlValidators(field)));
            });
        });
        return form;
    }

    private getFormControlValidators(field: IField): ValidatorFn[] {
        const validators = [];
        if (field.isRequired) 
            validators.push(Validators.required);
        
        return validators;
    }
}
