import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import {
    IOrderDescriptionResponse,
    IWorkorderDto,
    IWorkorderResponse,
    IWorkordersResponse,
    OrderDescriptionField,
    WorkorderField
} from "../models/workorder.model";
import { EnvironmentService } from "../services/environment.service";
import { IWorkorderLineDto, IWorkorderLineResponse, IWorkordersLinesResponse } from "./../models/workorder-line.model";

@Injectable({
    providedIn: "root"
})
export class WorkorderApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addWorkorder(workorder: IWorkorderDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/order`, workorder);
    }

    editWorkorder(id: string, workorder: IWorkorderDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/order/${id}`, workorder);
    }

    editWorkorderWorkflow(id: string, workorder: {is_in_workflow: 0 | 1}) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/order/${id}/inworkflow`, workorder);
    }

    getWorkorder(id: string, fields: string[]): Observable<IWorkorderResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IWorkorderResponse>(`${this.environmentService.apiUrl}/order/${id}`, { params: httpParams });
    }

    getWorkorders(page: number, fields: WorkorderField[], filter?: string, sort?: string, search?: string): Observable<IWorkordersResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IWorkordersResponse>(`${this.environmentService.apiUrl}/order`, { params: httpParams });
    }

    removeWorkorder(orderId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/order/${orderId}`);
    }

    // orderdescription
    getOrderDescriptions(page: number, fields: OrderDescriptionField[], filter?: string, sort?: string): Observable<IOrderDescriptionResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        return this.httpClient.get<IOrderDescriptionResponse>(`${this.environmentService.apiUrl}/orderdescription`, { params: httpParams });
    }

    getOrderDescription(id: string, fields: string[]): Observable<IOrderDescriptionResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IOrderDescriptionResponse>(`${this.environmentService.apiUrl}/orderdescription/${id}`, { params: httpParams });
    }

    addWorkorderLine(workorderLine: IWorkorderLineDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/orderline`, workorderLine);
    }

    getWorkorderLine(id: string, fields: string[]): Observable<IWorkorderLineResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        shareReplay();
        return this.httpClient.get<IWorkorderLineResponse>(`${this.environmentService.apiUrl}/orderline/${id}`, { params: httpParams });
    }

    getWorkorderLines(page: number, fields: string[], filter?: string, sort?: string, search?: string): Observable<IWorkordersLinesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        
        shareReplay();
        return this.httpClient.get<IWorkordersLinesResponse>(`${this.environmentService.apiUrl}/orderline`, { params: httpParams });
    }

    editWorkorderLine(id: string, workorderLine: IWorkorderLineDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/orderline/${id}`, workorderLine);
    }

    removeWorkorderLine(orderLineId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/orderline/${orderLineId}`);
    }
}
