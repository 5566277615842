export enum FieldType {
	Checkbox,
	Datetime,
	Date,
	Time,
	Input,
	Radio,
	Select,
	Textarea,
	Toggle,
	Lookup,
	Switch,
	Currency,
	NumberField,
	Decimal,
	Display,
	Int4,
	QuantityInt,
	QuantityFloat
}

export enum ModelType {
	CheckAddress = "checkaddress",
	Contact = "contact",
	Contactcommunication = "contactcommunication",
	Vehicle = "vehicle",
	Order = "order",
	OrderLine = "orderline",
	Article = "article",
	Timesheet = "timesheet",
	Employee = "employee",
	ChecklistItem = "checklistitem",
	ChecklistItemGroup = "checklistitemgroup",
	ChecklistItemLine = "checklistitemline",
	VehicleTyre = "vehicletyre"
}

export interface IField {
	displayValue: string; // used for lookup fieldtype
	fieldType: string; // source stringvalue fieldtype
	format: string;
	id: string;
	isRequired: boolean;
	length: number;
	listId: string;
	lookupValue: string;
	multiply: number;
	name: string;
	postfix: string;
	postgres: boolean;
	prefix: string;
	readonly: boolean;
	sort: number;
	type: FieldType;
	visible: boolean;
}

export interface IGroup {
	fields: IField[];
	name: string;
	sort: number;
}

export interface IModel {
	keys: string[];
	object: {
		[key: string]: IModelItem;
	};
}

export interface IModelItem {
	acl: string;
	datatype: string;
	field: string;
	fieldsort: number;
	fieldtype: string;
	format: string;
	groupname: string;
	groupsort: number;
	is_app: number;
	is_required: number;
	label: string;
	label_alt: string;
	length: number;
	multiply: number;
	postfix: string;
	prefix: string;
	is_postgres: number;
}

export interface IModelResponse {
	data: IModelItem[];
}

export interface IModels {
	[key: string]: IModel;
}
