import { Component, OnInit, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { isNil, uniq, includes } from "lodash-es";

import { EmployeeApiService } from "src/app/core/api/employee-api.service";
import { NotificationApiService } from "src/app/core/api/notification-api.service";
import { EmployeeField, IEmployeeDto } from "src/app/core/models/employee.model";
import { INotificationDto, INotificationMeta, NotificationByUserField, NotificationToUserField } from "src/app/core/models/notification.model";
import { AuthService } from "src/app/core/services/auth.service";
import { IOrdersToEmployeeDto } from "src/app/core/models/orders-to-employee.model";
import { ToastService } from "src/app/core/services/toast.service";
import { StorageService } from "src/app/core/services/storage.service";
import { NotificationMetaService } from "src/app/core/api/notification-meta.service";
import { NotificationAddService } from "src/app/core/api/notification-add.service";

type NotificationWithMeta = INotificationDto & {
	meta: INotificationMeta;
};

@Component({
	selector: "af-notification-add-modal",
	templateUrl: "./notification-add-modal.component.html",
	styleUrls: ["./notification-add-modal.component.scss"]
})
export class NotificationAddModalComponent implements OnInit {
	@Input() replyToNotificationId?: string;
	replyToNotification: NotificationWithMeta;

	form: FormGroup;
	isLoading = false;
	isSubmitting = false;
	isLoadingEmployees = true;
	employees: IEmployeeDto[] = [];

	activeEmployee: IEmployeeDto;
	selectedEmployees: IEmployeeDto[] = [];
	ordersToEmployeeDelay: IOrdersToEmployeeDto[] = [];

	private notificationFields: NotificationByUserField[] = [
		NotificationByUserField.NotificationType,
		NotificationByUserField.OwnerId,
		NotificationByUserField.Icon,
		NotificationByUserField.NotificationId,
		NotificationByUserField.ModifiedDate,
		NotificationByUserField.CreatedBy,
		NotificationByUserField.NotificationText,
		NotificationByUserField.OrganizationId,
		NotificationByUserField.MotifiedBy,
		NotificationByUserField.RelatedRecordId,
		NotificationByUserField.CreatedDate,
		NotificationByUserField.RmNotificationToUserId
	];
	private employeeFields: EmployeeField[] = [
		EmployeeField.Emailaddress,
		EmployeeField.EmployeeId,
		EmployeeField.Fullname,
		EmployeeField.HourTypeIdEmployee,
		EmployeeField.IsShowMoreEmployeesInWork,
		EmployeeField.IsChiefWorkshop,
		EmployeeField.IsOwner,
		EmployeeField.IsMechanic,
		EmployeeField.NumberInPlanning,
		EmployeeField.IsChoiceHourCategoryReq,
		EmployeeField.isHelpdeskAccount,
		EmployeeField.Firstname,
		EmployeeField.Middlename,
		EmployeeField.Lastname,
		EmployeeField.OrganizationId,
		EmployeeField.SvyUserId
	];
	private employeeFilter = "is_archived[eq]:0,(is_helpdesk_account[eq]:0,-is_helpdesk_account[eq]:null),is_blocked[eq]:0";
	private notificationToUserFields: NotificationToUserField[] = [NotificationToUserField.NotificationToUserId, NotificationToUserField.UserId];

	constructor(
		private storageService: StorageService,
		private modalController: ModalController,
		private notificationApiService: NotificationApiService,
		private notificationAddService: NotificationAddService,
		private notificationMetaService: NotificationMetaService,
		private employeeApiService: EmployeeApiService,
		private authService: AuthService,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.activeEmployee = await this.storageService.get("activeEmployee");

		this.employeeApiService.getEmployees(1, this.employeeFields, this.employeeFilter).subscribe((employeeListResult) => {
			this.employees = employeeListResult.data;
			this.isLoadingEmployees = false;

			this.form = new FormGroup({
				// notification_title: new FormControl("", Validators.required),
				notification_body: new FormControl("", Validators.required)
				// selected_employees: new FormControl([], [Validators.required, Validators.minLength(1)])
			});

			this.fetchReplyToNotification();
		});
	}

	dismiss() {
		this.modalController.dismiss();
	}

	async refreshNotificationCount() {
		const userId = await this.authService.getUserId();

		this.notificationApiService.getUnreadNotifications(userId);
	}

	async fetchReplyToNotification() {
		if (!isNil(this.replyToNotificationId)) {
			this.isLoading = true;
			const result = await this.notificationApiService.getNotification(this.replyToNotificationId, this.notificationFields);
			const notification = result.data[0];

			this.notificationMetaService.fetchMetaForNotification([notification]).then((notificationMeta) => {
				this.replyToNotification = {
					...notification,
					meta: notificationMeta.find((meta) => meta.notification_id === notification.notification_id)
				};

				// Fetch all users who were previously notified
				this.notificationApiService
					.getNotificationToUserList(1, this.notificationToUserFields, `notification_id[eq]:${notification.notification_id}`)
					.subscribe((notificationToUserResult) => {
						const previousReceiverUserIds = notificationToUserResult.data.map((notificationToUser) => notificationToUser.user_id) as string[];
						const previousReceiverEmployees = this.employees
							.filter((employee) => includes(previousReceiverUserIds, employee[EmployeeField.SvyUserId]))
							.map((employee) => employee[EmployeeField.EmployeeId]);
						const previousSender = notification.created_by as string;
						const currentUser = this.activeEmployee[EmployeeField.EmployeeId];

						// Incude all previous notification receivers, exclude the current user, re-include the previous notification sender
						const newReceivers = uniq([...previousReceiverEmployees.filter((receiver) => receiver !== currentUser), previousSender]);

						this.selectedEmployees = this.employees.filter((employee) => includes(newReceivers, employee[EmployeeField.EmployeeId]));
					});

				this.isLoading = false;
			});
		}
	}

	isValid(): boolean {
		if (this.form?.invalid) return false;

		if (!this.selectedEmployees || this.selectedEmployees.length < 1) return false;

		return true;
	}

	async submit() {
		if (!this.isValid()) return;
		this.isSubmitting = true;

		const userId = await this.authService.getUserId();

		const selectedEmployees = this.selectedEmployees.map((eployee) => eployee[EmployeeField.SvyUserId]);
		const notificationText = this.form.value.notification_body;
		const replyThreadId = this.replyToNotification?.reply_thread_id;

		// Always append self to receivers, or the user won't be able to see their sent notifications
		const notificationReceivers = uniq([...selectedEmployees, userId]);

		try {
			await this.notificationAddService.sendNotifications(notificationReceivers, notificationText, replyThreadId);
		} catch (e) {
			this.toastService.presentFailureToast("Er ging iets mis bij het verzenden van het bericht");
			console.error(`sendNotifications: ${e}`);

			return;
		} finally {
			this.isSubmitting = false;
		}

		this.refreshNotificationCount();
		this.toastService.presentSuccessToast("Bericht verzonden!");
		this.dismiss();
	}
}
