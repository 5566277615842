import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IDefaultDeliveryPackageField, IDefaultDeliveryPackagesResponse } from "../models/default-delivery-package.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class DefaultDeliveryPackagesService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getDeliveryPackages(fields: IDefaultDeliveryPackageField[]): Observable<IDefaultDeliveryPackagesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));
        return this.httpClient.get<IDefaultDeliveryPackagesResponse>(`${this.environmentService.apiUrl}/advertisedefaultdeliverypackage`, { params: httpParams });
    }
}
