import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
    AdvertiseDefaultTextField,
    IAdvertiseDefaultTextResponse,
    ISettingResponse,
    ISettingSitesResponse,
    SettingField
} from "../models/setting.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class SettingApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getAdvertiseDefaultText(fields: AdvertiseDefaultTextField[]): Observable<IAdvertiseDefaultTextResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));

        return this.httpClient.get<IAdvertiseDefaultTextResponse>(`${this.environmentService.apiUrl}/advertisedefaulttext`, { params: httpParams });
    }

    getSetting(fields: SettingField[]): Observable<ISettingResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<ISettingResponse>(`${this.environmentService.apiUrl}/setting`, { params: httpParams });
    }

    getSites(): Observable<ISettingSitesResponse> {
        return this.httpClient.get<ISettingSitesResponse>(`${this.environmentService.apiUrl}/setting/sites`);
    }
}
