import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";

import { ArticleField, ArticleFilterType, ArticleOrderType, ArticleSortType, IArticleQuery } from "../../core/models/article.model";
import { StorageService } from "../../core/services/storage.service";

@Injectable()
export class ArticleQueryService {
    queryChanges$ = new Subject<IArticleQuery>();

    private initialQuery: IArticleQuery = {
        filter: ArticleFilterType.All,
        page: 1,
        order: ArticleOrderType.Ascending,
        search: "",
        sort: ArticleSortType.CreatedDate,
        organization: ""
    };
    private query: IArticleQuery;

    constructor(private storageService: StorageService) {}

    getFilter(): string {
        const filter = [];
        const filterNotArchived = "is_archived[eq]:0";
        if (this.query.filter === ArticleFilterType.All)
            filter.push(`${filterNotArchived}`);
         else if (this.query.filter === ArticleFilterType.LastUsed) {
            filter.push(
                `${filterNotArchived},modified_date[ge]:"${moment().subtract(7, "days").toISOString()}"` +
                    ",-" +
                    `created_date[ge]:"${moment().subtract(7, "days").toISOString()}"`
            );
        } else if (this.query.filter === ArticleFilterType.Handlings)
            filter.push(`${filterNotArchived},article_kind[eq]:2`);
         else if (this.query.filter === ArticleFilterType.Archived)
            filter.push("is_archived[eq]:1");
         else if (this.query.filter === ArticleFilterType.Blocked)
            filter.push("is_blocked[eq]:1");


        if (this.query.search) {
            const search = [];
            search.push(`${ArticleField.Description}[like]:"${this.query.search.trim()}"`);
            search.push(`${ArticleField.ArticleNumber}[like]:"${this.query.search.trim()}"`);
            search.push(`${ArticleField.Ean}[like]:"${this.query.search.trim()}"`);

            // TODO
            filter.push(`(${search.join(",-")})`);
        }

        if (this.query.organization)
            filter.push(`organization_id[eq]:${this.query.organization}`);


        return filter.join(",");
    }

    getSort(): string {
        const order = this.query.order === ArticleOrderType.Ascending ? "" : "-";
        if (this.query.sort === ArticleSortType.CreatedDate)
            return `${order}${ArticleField.CreatedDate},${order}${ArticleField.ArticleNumber}`;

        if (this.query.sort === ArticleSortType.ArticleNumber)
            return `${order}${ArticleField.ArticleNumber}`;

        return `${order}${ArticleField.CreatedDate},${order}${ArticleField.ArticleNumber}`;
    }

    async init() {
        const query = await this.storageService.get("settings.articles.query");
        if (query) {
            this.query = {
                filter: query.filter,
                page: this.initialQuery.page,
                order: query.order,
                search: this.initialQuery.search,
                sort: query.sort,
                organization: ""
            };
            this.queryChanges$.next(this.query);
        } else
            this.setQuery(this.initialQuery);

    }

    setQuery(query: IArticleQuery) {
        this.storageService.set("settings.articles.query", {
            filter: query.filter,
            order: query.order,
            sort: query.sort
        });
        this.query = query;
        this.queryChanges$.next(query);
    }
}
