import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";

@Injectable({
    providedIn: "root"
})
export class AlertService {
    constructor(private alertController: AlertController) {}

    async presentConfirmAlert(header: string, message: string, handler: any) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [
                { text: "Nee", role: "cancel" },
                { text: "Ja", handler }
            ]
        });
        await alert.present();
    }

    async presentConfirmAlertDoubleHandler(header: string, message: string, yesHandler: any, noHandler: any) {
    const alert = await this.alertController.create({
        header,
        message,
        buttons: [
            {
                text: "Nee",
                role: "cancel",
                handler: noHandler
            },
            {
                text: "Ja",
                handler: yesHandler
            }
        ]
    });
    await alert.present();
}

    async presentConfirmAlertContinue(header: string, message: string, handler: any, cancelHandler: any | undefined) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [
                { text: "Annuleren", role: "cancel", handler: cancelHandler },
                { text: "Nieuwe starten", handler }
            ]
        });
        await alert.present();
    }
    async presentConfirmAlertContinueCustomButtonText(header: string, message: string, handler: any, buttonText: string) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [{ text: buttonText, handler }],
            backdropDismiss: false
        });
        await alert.present();
    }

    // alert with only a cancel button
    async presentConfirmAlertNoHandler(header: string, message: string) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [{ text: "sluiten", role: "cancel" }]
        });
        await alert.present();
    }

    // alert stopping not billable timesheet
    async presentConfirmAlertNotBillableTimesheet(header: string, message: string, handler: any) {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [
                { text: "Annuleren", role: "cancel" },
                { text: "Stoppen", handler }
            ]
        });
        await alert.present();
    }
}
