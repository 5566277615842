<ion-item (click)="onSelect($event)" class="af-notification-item" [detail]="clickable" data-test="notification-item"
	lines="full">
	<af-avatar class="avatar" [class.avatar--detail]="usedInView === 'detail'" *ngIf="notification.meta?.sender"
		[employeeId]="notification.meta?.sender.employee_id"></af-avatar>

	<ion-label class="af-notification-item__label minHeight ion-text-nowrap">
		<ion-label class="af-notification-text__header">
			<ion-text class="af-notification-dot" *ngIf="isRead === false">⬤</ion-text>
			<ion-text class="af-notification-text__header-sender" *ngIf="notification.meta?.sender" color="grey">
				{{ notification.meta?.sender.v_display_name }}
			</ion-text>
			<ion-text class="af-notification-text__header-date" [class.af-notification-text__header-date--detail]="usedInView === 'detail'" color="grey">
				{{ formattedDate }}
			</ion-text>
		</ion-label>

		<ion-label class="ion-text-nowrap" *ngIf="usedInView === 'preview'">
			<ion-text color="grey">{{ formatDate(notification.created_date) }}</ion-text>
			{{ notification.notification_text }}
		</ion-label>

		<!-- Code between ">" and "</ion-text> must be oneliner or white-space: break-spaces won't work properly -->
		<ion-text [class.af-notification-text__body--list]="usedInView === 'list'"
			[class.af-notification-text__body--detail]="usedInView === 'detail'">{{notification.notification_text}}</ion-text>
	</ion-label>

</ion-item>