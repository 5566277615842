import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import {
    ITimesheetBillableTimeResponse,
    ITimesheetDto,
    ITimesheetResponse,
    ITimesheetsResponse
} from "../models/timesheet.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class TimesheetApiService {
    // Workaround: prevent starting / stopping timesheet clocking when a timesheet item is loading
    public isTimeSheetItemLoadingObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addTimesheet(timesheet: ITimesheetDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/timesheet`, timesheet);
    }

    getTimesheet(id: string, fields: string[]): Observable<ITimesheetResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        shareReplay();
        return this.httpClient.get<ITimesheetResponse>(`${this.environmentService.apiUrl}/timesheet/${id}`, { params: httpParams });
    }

    getTimesheetBillableTime(startTime: string, endTime: string, hourTypeId?: string): Observable<ITimesheetBillableTimeResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("start_time", startTime);
        httpParams = httpParams.set("end_time", endTime);
        if (hourTypeId) 
            httpParams = httpParams.set("hour_type_id", hourTypeId);
        

        return this.httpClient.get<ITimesheetBillableTimeResponse>(`${this.environmentService.apiUrl}/timesheet/billabletime`, { params: httpParams });
    }

    getTimesheets(page: number, fields: string[], filter?: string, sort?: string, search?: string): Observable<ITimesheetsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        
        shareReplay();
        return this.httpClient.get<ITimesheetsResponse>(`${this.environmentService.apiUrl}/timesheet`, { params: httpParams });
    }

    editTimesheet(id: string, timesheet: ITimesheetDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/timesheet/${id}`, timesheet);
    }

    removeTimesheet(timesheetId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/timesheet/${timesheetId}`);
    }

    setTimesheetItemLoading(newValue: boolean) {
        if (this.isTimeSheetItemLoadingObservable.getValue() !== newValue)
            this.isTimeSheetItemLoadingObservable.next(newValue)
    }
}
