import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class DisplayApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    getSelectedValue(table: string, id: string, fields: string[]) {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get(`${this.environmentService.apiUrl}/${table}/${id}`, { params: httpParams });
    }
}
