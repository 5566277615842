export enum OrdersToEmployeeField {
    OdersToEmployeeId = "orders_to_employee_id",
    OrganizationId = "organization_id",
    OwnerId = "owner_id",
    OrderId = "order_id",
    EmployeeId = "employee_id",
    CreatedDate = "created_date",
    CreatedBy = "created_by",
    ModifiedDate = "modified_date",
    ModifiedBy = "modified_by"
}

export enum OrdersToEmployeeFilterType {
    All
}

export enum OrdersToEmployeeOrderType {
    Ascending,
    Descending
}

export enum OrdersToEmployeeSortType {
    All,
    CreatedDate
}

export interface IOrdersToEmployeeDto {
    order_id: string;
    employee_id: string;
    orders_to_employee_id?: string;
    [key: string]: any;
}

export interface IOrdersToEmployeeQuery {
    filter: OrdersToEmployeeFilterType;
    page: number;
    order: OrdersToEmployeeOrderType;
    search: string;
    sort?: OrdersToEmployeeSortType;
}

export interface IOrdersToEmployeeResponse {
    data: IOrdersToEmployeeDto[];
    maxPages: number;
}

export interface IOrdersToEmployeeResponse {
    currentPage: number;
    data: IOrdersToEmployeeDto[];
    maxPages: number;
}
