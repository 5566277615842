<ion-header>
	<ion-toolbar color="primary" mode="md">
		<ion-buttons slot="start">
			<ion-button (click)="dismiss()" data-test="dismiss-modal">
				<fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" size="lg" slot="icon-only"></fa-icon>
			</ion-button>
		</ion-buttons>
		<ion-title>Let op!</ion-title>
		<ion-buttons slot="end">
			<af-header-popover-button [componentName]="this.constructor.name"></af-header-popover-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content *ngIf="inputObject.showModal">
	<ion-grid>
		<ion-row>
			<ion-col size="12">
				<ion-text>
					{{ inputObject.messagesTitle.title }} <br />
					{{ inputObject.messagesTitle.note }}
				</ion-text>
				<ion-text>
					<br /><br />
					<span *ngFor="let item of inputObject.messages">
						Kenteken: {{ item.title }} <br />
						{{ item.note }}
					</span>
				</ion-text>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
