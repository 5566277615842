import { Injectable } from "@angular/core";
import { request, gql } from "graphql-request";
import { defer, from } from "rxjs";
import { ISendPushRequest, ISendPushResponse } from "../models/notification.model";
import { ApiService } from "./api.service";

const sendPushDocument = gql`
	mutation sendPush($data: SendPushInput!) {
		sendPush(data: $data) {
			__typename
			... on Error {
				code
				message
				i18n {
					key
					params
				}
			}
			... on SendPushSuccess {
				messageId
			}
		}
	}
`;

@Injectable({
	providedIn: "root"
})
export class NotificationApiv3Service {
	constructor(private apiService: ApiService) {}

	sendPush(authToken: string, push: ISendPushRequest) {
		return request<ISendPushResponse>("https://api.autoflex10.dev/v3/graphql", sendPushDocument, push, {
			appid: "4c2cab09-d56f-4145-a63a-aa88ecee4e65",
			Authorization: authToken
		});
	}
}
