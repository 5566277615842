import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from "@angular/core";
import { fromEvent, merge, Observable, of, Subscription, timer } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Directive({
	selector: "[afLongPress]"
})
export class LongPressDirective implements OnDestroy {
	private eventSubscribe: Subscription;
	threshold = 1000;

	@Output()
	mouseLongPress = new EventEmitter();

	constructor(private elementRef: ElementRef) {
		const mousedown = fromEvent<MouseEvent>(elementRef.nativeElement, "mousedown").pipe(
			filter((event) => event.button === 0), // Only allow left button (Primary button)
			map((event) => true) // turn on threshold counter
		);
		const touchstart = fromEvent(elementRef.nativeElement, "touchstart").pipe(map(() => true));
		const touchEnd = fromEvent(elementRef.nativeElement, "touchend").pipe(map(() => false));
		const mouseup = fromEvent<MouseEvent>(window, "mouseup").pipe(
			filter((event) => event.button === 0), // Only allow left button (Primary button)
			map(() => false) // reset threshold counter
		);
		this.eventSubscribe = merge(mousedown, mouseup, touchstart, touchEnd)
			.pipe(
				switchMap((state) => (state ? this.tresholdAction() : of(null))),
				filter((value) => !!value)
			)
			.subscribe(() => {
				this.mouseLongPress.emit();
			});
	}

	async tresholdAction() {
		const nmbr$ = of(this.threshold);
		const timeout = new Promise((resolve) => setTimeout(resolve, this.threshold));
		await timeout;
		return nmbr$;
	}

	ngOnDestroy(): void {
		if (this.eventSubscribe) this.eventSubscribe.unsubscribe();
	}
}
