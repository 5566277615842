import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IVehicleSiteDto, IVehicleSitesResponse, VehicleAdvertiseField } from "../models/vehicle-advertise-site.model";
import { IVehicleDeliveryPackageDto, IVehicleDeliveryPackagesResponse, VehicleDeliveryPackagesField } from "../models/vehicle-delivery-packages.model";
import {
    IVehicleAddResponse,
    IVehicleDto,
    IVehicleFormatLicensePlateResponse,
    IVehicleIsPublishedResponse,
    IVehiclePublishStatusResponse,
    IVehicleResponse,
    IVehiclesResponse,
    IVehicleTextDto,
    IVehicleTextsResponse,
    IVehicleTypesResponse,
    VehicleAdvertiseDefaultTextField,
    VehicleField,
    VehicleTypeField
} from "../models/vehicle.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class VehicleApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addDealerText(dealerText: IVehicleTextDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext`, dealerText);
    }

    addDeliveryPackage(deliveryPackage: IVehicleDeliveryPackageDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/vehicledeliverypackage`, deliveryPackage);
    }

    addPrivateText(privateText: IVehicleTextDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext`, privateText);
    }

    addVehicle(vehicle: IVehicleDto): Observable<IVehicleAddResponse> {
        return this.httpClient.post<IVehicleAddResponse>(`${this.environmentService.apiUrl}/vehicle`, vehicle);
    }

    addVehicleWithRdw(vehicle: IVehicleDto): Observable<IVehicleAddResponse> {
        return this.httpClient.post<IVehicleAddResponse>(`${this.environmentService.apiUrl}/vehicle/createwithrdw`, vehicle);
    }

    editDealerText(dealerText: IVehicleTextDto, advertiseTextId: string) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext/${advertiseTextId}`, dealerText);
    }

    editLicensePlate(vehicleId: string, licensePlate: string) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${vehicleId}/updatelicenseplate`, { license_plate: licensePlate, vehicle_id: vehicleId });
    }

    editPrivateText(privateText: IVehicleTextDto, advertiseTextId) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext/${advertiseTextId}`, privateText);
    }

    addSite(site: IVehicleSiteDto): Observable<IVehicleSitesResponse> {
        return this.httpClient.post<IVehicleSitesResponse>(`${this.environmentService.apiUrl}/vehicleadvertisesite`, site);
    }

    removeSite(advertiseSiteId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/vehicleadvertisesite/${advertiseSiteId}`);
    }

    editVehicle(id: string, vehicle: IVehicleDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${id}`, vehicle);
    }

    formatLicensePlate(licensePlate: string): Observable<IVehicleFormatLicensePlateResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("license_plate", licensePlate);

        return this.httpClient.get<IVehicleFormatLicensePlateResponse>(`${this.environmentService.apiUrl}/vehicle/formatlicenseplate`, { params: httpParams });
    }

    getDealerTexts(fields: VehicleAdvertiseDefaultTextField[], filter?: string): Observable<IVehicleTextsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));

        if (filter) 
            httpParams = httpParams.set("filter", filter);
        

        return this.httpClient.get<IVehicleTextsResponse>(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext`, { params: httpParams });
    }

    getDeliveryPackages(fields: VehicleDeliveryPackagesField[], filter?: string): Observable<IVehicleDeliveryPackagesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        

        return this.httpClient.get<IVehicleDeliveryPackagesResponse>(`${this.environmentService.apiUrl}/vehicledeliverypackage`, { params: httpParams });
    }

    getPrivateTexts(fields: VehicleAdvertiseDefaultTextField[], filter?: string): Observable<IVehicleTextsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));

        if (filter) 
            httpParams = httpParams.set("filter", filter);
        

        return this.httpClient.get<IVehicleTextsResponse>(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext`, { params: httpParams });
    }

    getSites(fields: VehicleAdvertiseField[], filter?: string): Observable<IVehicleSitesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        

        return this.httpClient.get<IVehicleSitesResponse>(`${this.environmentService.apiUrl}/vehicleadvertisesite`, { params: httpParams });
    }

    searchVehicleByLicensePlate(search: string, fields: string[]): Observable<IVehicleResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        if (search.length) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IVehicleResponse>(`${this.environmentService.apiUrl}/vehicle`, { params: httpParams });
    }

    getVehicle(id: string, fields: string[]): Observable<IVehicleResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IVehicleResponse>(`${this.environmentService.apiUrl}/vehicle/${id}`, { params: httpParams });
    }

    getVehiclePublishStatus(vehicleId: string): Observable<IVehiclePublishStatusResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("vehicle_id", vehicleId);

        return this.httpClient.get<IVehiclePublishStatusResponse>(`${this.environmentService.apiUrl}/vehicle/currentpublishstatus`, { params: httpParams });
    }

    getVehicleIsPublished(vehicleId: string): Observable<IVehicleIsPublishedResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("vehicle_id", vehicleId);

        return this.httpClient.get<IVehicleIsPublishedResponse>(`${this.environmentService.apiUrl}/vehicle/ispublished`, { params: httpParams });
    }

    getVehicles(page: number, fields: VehicleField[], filter?: string, sort?: string, search?: string, organization?: string): Observable<IVehiclesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        
        if (organization) 
            httpParams = httpParams.append("filter", organization);
        
        return this.httpClient.get<IVehiclesResponse>(`${this.environmentService.apiUrl}/vehicle`, { params: httpParams });
    }

    removeDealerText(dealerTextId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext/${dealerTextId}`);
    }

    removeDeliveryPackage(deliveryPackageId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/vehicledeliverypackage/${deliveryPackageId}`);
    }

    removePrivateText(privateTextId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/vehicleadvertisedefaulttext/${privateTextId}`);
    }

    removeVehicle(vehicleId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/vehicle/${vehicleId}`);
    }

    setNotSold(vehicleId: string) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${vehicleId}/publishnotsold`, { vehicle_id: vehicleId });
    }

    setSold(vehicleId: string) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${vehicleId}/publishsold`, { vehicle_id: vehicleId });
    }

    startPublish(vehicleId: string) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${vehicleId}/publishstart`, { vehicle_id: vehicleId });
    }
    publishUpdate(vehicleId: string, publishsitetype: number) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${vehicleId}/publishupdate`, { vehicle_id: vehicleId, publishsitetype });
    }

    stopPublish(vehicleId: string) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/vehicle/${vehicleId}/publishstop`, { vehicle_id: vehicleId });
    }

    getVehicleTypes(page: number, fields: VehicleTypeField[], filter?: string, sort?: string): Observable<IVehicleTypesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        

        return this.httpClient.get<IVehicleTypesResponse>(`${this.environmentService.apiUrl}/vehicletype`, { params: httpParams });
    }
}
