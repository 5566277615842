import { IUserOrganizationDto } from "./organization.model";

export enum VehicleField {
	Brand = "brand",
	Color = "color",
	FuelCode = "fuel_code",
	IsPublished = "is_published",
	IsSold = "is_sold",
	IsSoldHexon = "is_sold_hexon",
	LicensePlate = "license_plate",
	Milometer = "milometer",
	MilometerType = "milometer_type",
	Model = "model",
	Modelyear = "modelyear",
	NumberOfTransmissions = "number_of_transmissions",
	PictureId = "picture_id",
	PictureListId = "picture_list_id",
	PublicationDate = "publication_date",
	PeriodicCheckDate = "periodic_check_date",
	RatingPrice = "rating_price",
	RegistrationCode = "registration_code",
	SellPrice = "sell_price",
	TransmissionType = "transmission_type",
	VehicleId = "vehicle_id",
	VehicleVersion = "vehicle_version",
	FormattedLicensePlate = "formatted_license_plate",
	IsTmps = "is_tmps",
	IsBlocked = "is_blocked",
	IsArchived = "is_archived",
	LicensePlateSignal = "license_plate_signal",
	PublishStatus = "publish_status",
	OrganizationId = "organization_id",
	IsShowNoteAsWarning = "is_show_note_as_warning",
	NotesInternal = "notes_internal"
}

export enum VehicleFilterType {
	All,
	Occasion,
	Published,
	CompanyStock,
	LastUsed,
	IsBlocked,
	IsArchived
}

export enum VehicleOrderType {
	Ascending,
	Descending
}

export enum VehiclePublishStatus {
	NotPublished,
	Processing,
	Published,
	Sold,
	ProcessingDepublish
}

export enum VehicleSortType {
	LicensePlate,
	PublicationDate,
	BrandModel
}

export enum VehicleTextType {
	Dealer = 1,
	Private = 2
}

export interface IVehicleAddResponse {
	currentPage: number;
	data: [
		{
			created_by: string;
			created_date: string;
			formatted_license_plate: string;
			hexon_id: string;
			license_plate: string;
			organization_id: string;
			owner_id: string;
			vehicle_id: string;
		}
	];
	maxPages: number;
}

export interface IVehicleDto {
	vehicle_id?: string;
	[key: string]: any;
}

export interface IVehicleEditResponse {
	data: IVehicleDto[];
}

export interface IVehicleFormatLicensePlateResponse {
	data: [
		{
			license_plate: string;
		}
	];
}

export interface IVehiclePublishStatusResponse {
	current_publish_status: VehiclePublishStatus;
}

export interface IVehicleIsPublishedResponse {
	ispublished: boolean;
}

export interface IVehicleQuery {
	filter: VehicleFilterType;
	page: number;
	order: VehicleOrderType;
	search: string;
	sort: VehicleSortType;
	organization: string;
}

export interface IVehicleResponse {
	data: IVehicleDto[];
	maxPages: number;
}

export interface IVehiclesResponse {
	currentPage: number;
	data: IVehicleDto[];
	maxPages: number;
}

export enum VehicleAdvertiseDefaultTextField {
	AdvertiseDefaultTextId = "advertise_default_text_id",
	TextType = "text_type",
	OwnerId = "owner_id",
	OrganizationId = "organization_id",
	LanguageText = "language_text",
	ModifiedBy = "modified_by",
	CreatedDate = "created_date",
	CreatedBy = "created_by",
	CountryId = "country_id",
	VehicleId = "vehicle_id"
}

export interface IVehicleTextDto {
	country_id?: string;
	language_text: string;
	text_type?: number;
	vehicle_advertise_default_text_id?: string;
	vehicle_id?: string;
}

export interface IVehicleTextsResponse {
	currentPage: number;
	data: IVehicleTextDto[];
	maxPages: number;
}

export enum VehicleTypeField {
	IsSystem = "is_system",
	VehicleTypeId = "vehicle_type_id",
	OwnerId = "owner_id",
	OrganizationId = "organization_id",
	Description = "description",
	CreatedDate = "created_date"
}

export interface IVehicleTypeDto {
	is_system: number;
	vehicle_type_id: string;
	owner_id: string;
	organization_id: string;
	description: string;
	created_date: string;
	is_blocked: boolean;
	is_archived: boolean;
}

export interface IVehicleTypesResponse {
	currentPage: number;
	data: IVehicleTypeDto[];
	maxPages: number;
}

export enum LicensePlateSignalEnum {
	NO_STATUS_APPLIED = 0,
	WAIT_FOR_CHECK = 1,
	NOT_VALID = 2
}
