import { App } from "@capacitor/app";

import { environment } from "../environments/environment";
import { AuthService } from "./core/services/auth.service";
import { EnvironmentService } from "./core/services/environment.service";
import { LoadingService } from "./core/services/loading.service";
import { StorageService } from "./core/services/storage.service";

export const initApp =
	(authService: AuthService, environmentService: EnvironmentService, loadingService: LoadingService, storageService: StorageService) => () =>
		new Promise<void>(async (resolve) => {
			await loadingService.present("Een moment...").then(async () => {
				try {
					const versionCode = (await App.getInfo()).build;
					const versionNumber = (await App.getInfo()).version;

					environmentService.versionCode = versionCode + "";
					environmentService.versionNumber = versionNumber;
				} catch (error) {
					environmentService.versionCode = environment.versionApp;
					environmentService.versionNumber = "";
				}
				const authToken = await authService.getAuthToken();
				const loginParams = await authService.getLoginParams();
				if (authToken && loginParams && loginParams.username) {
					const getEnvironment = await environmentService.getEnvironment(loginParams.username).toPromise();
					if (getEnvironment) {
						await authService
							.checkLoggedInToken(authToken)
							.then(async (tokenResponse) => {
								if (tokenResponse) {
									await authService.setTokenValidUntil(tokenResponse.token_valid_until);
									await authService.setAuthToken(tokenResponse.token);
									resolve();
								}
							})
							.catch(async (error) => {
								const username = await storageService.get("username"),
									password = await storageService.get("password"),
									fingerprint = await storageService.get("fingerprint");
								await storageService.clearStorage();
								authService.loggedIn.next(false);
								await storageService.set("username", username);
								await storageService.set("password", password);
								await storageService.set("fingerprint", fingerprint);
								resolve();
							});
					} else {
						await authService.clearStorage();
						authService.loggedIn.next(false);
						resolve();
					}
				}
			});

			resolve();
		});
