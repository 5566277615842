<span *ngIf="showSearch">
	<ion-item *ngIf="filterChip" class="filters ion-no-padding">
		<ion-label class="ion-padding-start"> <fa-icon [fixedWidth]="true" [icon]="['fas', 'filter']" slot="start"></fa-icon> </ion-label>
		<span class="chips">
			<ion-chip *ngFor="let chip of filterChip" class="filter-chip">
				<button ion-button icon-only clear item-right class="filter-chip-button" (click)="clearFilter(chip.key)">
					<ion-icon class="filter-chip-icon" name="close"></ion-icon>
				</button>
				<ion-label class="filter-chip-label">{{ chip.value }}</ion-label>
			</ion-chip>
		</span>
	</ion-item>
	<ion-item class="custom-searchbar ion-no-padding">
		<!-- <ion-searchbar (ionChange)="search($event)" placeholder="Zoeken" debounce="500"></ion-searchbar> -->
		<ion-searchbar
			[debounce]="500"
			(ionInput)="search($event)"
			value="{{ searchValue ? searchValue : '' }}"
			placeholder="Zoeken"
			class="filter-searchbar ion-no-padding"
			data-test="search-field"
		></ion-searchbar>
	</ion-item>
</span>
