import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
    ChecklistItemField,
    ChecklistItemGroupField,
    ChecklistItemLineField,
    IChecklistItemGroupResponse,
    IChecklistItemGroupsResponse,
    IChecklistItemLineDto,
    IChecklistItemLineResponse,
    IChecklistItemLinesResponse,
    IChecklistItemResponse,
    IChecklistItemsResponse
} from "../models/checklist.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class ChecklistApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    // CHECKLIST ITEM
    getChecklistItem(page: number, id: string, fields: string[]): Observable<IChecklistItemResponse> {
        let httpParams = new HttpParams();
        if (page) 
            httpParams = httpParams.set("page", page + "");
        
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IChecklistItemResponse>(`${this.environmentService.apiUrl}/checklistitem/${id}`, { params: httpParams });
    }

    getChecklistItems(page: number, fields: ChecklistItemField[], filter?: string, sort?: string, search?: string): Observable<IChecklistItemsResponse> {
        let httpParams = new HttpParams();
        if (page) 
            httpParams = httpParams.set("page", page + "");
        
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IChecklistItemsResponse>(`${this.environmentService.apiUrl}/checklistitem`, { params: httpParams });
    }

    // CHECKLIST GROUP
    getCheckListItemGroup(id: string, fields: ChecklistItemGroupField[]): Observable<IChecklistItemGroupResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IChecklistItemResponse>(`${this.environmentService.apiUrl}/checklistitemgroup/${id}`, { params: httpParams });
    }

    getChecklistItemGroups(page: number, fields: ChecklistItemGroupField[], filter?: string, sort?: string, search?: string): Observable<IChecklistItemGroupsResponse> {
        let httpParams = new HttpParams();
        if (page) 
            httpParams = httpParams.set("page", page + "");
        
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IChecklistItemGroupsResponse>(`${this.environmentService.apiUrl}/checklistitemgroup`, { params: httpParams });
    }

    // CHECKLIST LINE
    getChecklistItemLine(page: number, id: string, fields: string[]): Observable<IChecklistItemLineResponse> {
        let httpParams = new HttpParams();
        if (page) 
            httpParams = httpParams.set("page", page + "");
        
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IChecklistItemLineResponse>(`${this.environmentService.apiUrl}/checklistitemline/${id}`, { params: httpParams });
    }

    getChecklistItemLines(page: number, fields: ChecklistItemLineField[], filter?: string, sort?: string, search?: string): Observable<IChecklistItemLinesResponse> {
        let httpParams = new HttpParams();
        if (page) 
            httpParams = httpParams.set("page", page + "");
        
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IChecklistItemLinesResponse>(`${this.environmentService.apiUrl}/checklistitemline`, { params: httpParams });
    }

    addChecklistItemLine(checklistItemLine: IChecklistItemLineDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/checklistitemline`, checklistItemLine);
    }
    editChecklistItemLine(id: string, checklistItemLine: IChecklistItemLineDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/checklistitemline/${id}`, checklistItemLine);
    }
    deleteChecklistItemLine(id: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/checklistitemline/${id}`);
    }
}
