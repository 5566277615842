import { ListResponse } from "./api.model";

export enum VehicleRoleLineField {
    Code = "code",
    ContactId = "contact_id",
    CreatedBy = "created_by",
    CreatedDate = "created_date",
    ModifiedBy = "modified_by",
    ModifiedDate = "modified_date",
    OrganizationId = "organization_id",
    OwnerId = "owner_id",
    VehicleDescription = "vehicle_description",
    VehicleId = "vehicle_id",
    VehicleRoleId = "vehicle_role_id",
    VehicleRoleLineId = "vehicle_role_line_id"
}

export interface IAddVehicleRoleLineDto {
    contact_id: string;
    vehicle_id: string;
    vehicle_role_id: string;
}

export interface IVehicleRoleLineDto {
    code?: string;
    contact_id?: string;
    created_by?: string;
    created_date?: string;
    modified_by?: string;
    modified_date?: string;
    organization_id?: string;
    owner_id?: string;
    vehicle_description?: string;
    vehicle_id?: string;
    vehicle_role_id?: string;
    vehicle_role_line_id?: string;
}

export type IVehicleRoleLineResponse = ListResponse<IVehicleRoleLineDto>;

export type IVehicleRoleLinesResponse = ListResponse<IVehicleRoleLineDto>;
