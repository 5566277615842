import { DOCUMENT } from "@angular/common";
import { get } from "lodash-es"
import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Inject, Injectable, Injector } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ActionSheetService {
    private refMap: object = {};

    constructor(
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        @Inject(DOCUMENT) private document: any,
        private injector: Injector
    ) {}

    create(component: any, params: any, identifier: string = "default"): Observable<any> {
        if (get(this.refMap, identifier)) {
            console.warn(`ActionSheetService: Attempted to open a duplicate action sheet! identifier=${identifier}`)
            return this.refMap[identifier].dismiss;
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(this.injector);
        if (params) 
            Object.assign(componentRef.instance, params);
        
        this.applicationRef.attachView(componentRef.hostView);
        this.document.body.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);

        this.refMap[identifier] = {
            componentRef,
            dismiss: new Subject()
        };
        return this.refMap[identifier].dismiss;
    }

    dismiss(data?: any, identifier: string = "default") {
        this.applicationRef.detachView(this.refMap[identifier].componentRef.hostView);
        this.refMap[identifier].componentRef.destroy();
        this.refMap[identifier].dismiss.next(data);
        this.refMap[identifier].dismiss.complete();
        delete this.refMap[identifier];
    }

    dismissAll(data?: any) {
        Object.keys(this.refMap).forEach((key) => {
            this.dismiss(data, key);
            delete this.refMap[key];
        });
    }
}
