import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { IHourtypeDto, IHourtypeResponse, IHourtypesResponse } from "../models/hourtype.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class HourtypeApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addHourtype(hourtype: IHourtypeDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/hourtype`, hourtype);
    }

    getHourtype(id: string, fields: string[]): Observable<IHourtypeResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        
        return this.httpClient.get<IHourtypeResponse>(`${this.environmentService.apiUrl}/hourtype/${id}`, { params: httpParams });
    }

    getHourtypes(page: number, fields: string[], filter?: string, sort?: string, search?: string): Observable<IHourtypesResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        
        shareReplay();
        return this.httpClient.get<IHourtypesResponse>(`${this.environmentService.apiUrl}/hourtype`, { params: httpParams });
    }

    editHourtype(id: string, hourtype: IHourtypeDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/timesheet/${id}`, hourtype);
    }

    removeHourtype(hourtypeId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/hourtype/${hourtypeId}`);
    }
}
