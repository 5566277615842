export enum ArticleField {
	ArticleNumber = "article_number",
	ArticleType = "article_type",
	OwnerId = "owner_id",
	ArticleKind = "article_kind",
	Description = "description",
	ContactId = "contact_id",
	ArticleId = "article_id",
	TurnOverGroupId = "turnover_group_id",
	OrgaanizationId = "organization_id",
	ArticleGroupId = "article_group_id",
	ModifiedDate = "modified_date",
	ModifiedBy = "modified_by",
	CreatedDate = "created_date",
	CreatedBy = "created_by",
	UnitQuantity = "unit_quantity",
	UnitType = "unit_type",
	GrossPrice = "gross_price",
	PurchasePrice = "purchase_price",
	Ean = "ean",
	IsArchived = "is_archived",
	IsBlocked = "is_blocked"
}

export enum ArticleKindEnum {
	Article = 1,
	Handling = 2,
	Remaining = 3
}

export enum ArticleFilterType {
	All,
	LastUsed,
	Handlings,
	Archived,
	Blocked
}

export enum ArticleOrderType {
	Ascending,
	Descending
}

export enum ArticleSortType {
	CreatedDate,
	ArticleNumber
}

export interface IArticleDto {
	article_id?: string;
	[key: string]: any;
}

export interface IArticleQuery {
	filter: ArticleFilterType;
	page: number;
	order: ArticleOrderType;
	search: string;
	sort: ArticleSortType;
	organization: string;
}

export interface IArticleResponse {
	data: IArticleDto[];
	currentPage: number;
	maxPages: number;
}

export interface IArticlesResponse {
	currentPage: number;
	data: IArticleDto[];
	maxPages: number;
}
