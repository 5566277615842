export enum TurnoverGroupField {
    Code = "code",
    Description = "description",
    VatCodeIdSale = "vat_code_id_sale",
    VatCodeIdPurchase = "vat_code_id_purchase"
}

export enum TurnoverGroupFilterType {
    All
}

export enum TurnoverGroupOrderType {
    Ascending,
    Descending
}

export enum TurnoverGroupSortType {}
// TODO

export interface ITurnoverGroupDto {
    turnover_group_id?: string;
    description?: string;
    vat_code_id_sale?: string;
    vat_code_id_purchase?: string;
    [key: string]: any;
}

export interface ITurnoverGroupQuery {
    filter: TurnoverGroupFilterType;
    page: number;
    order: TurnoverGroupOrderType;
    search: string;
    sort: TurnoverGroupSortType;
}

export interface ITurnoverGroupResponse {
    data: ITurnoverGroupDto[];
    maxPages: number;
}

export interface ITurnoverGroupsResponse {
    currentPage: number;
    data: ITurnoverGroupDto[];
    maxPages: number;
}
