import { Component, Input, OnInit } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import html2canvas from "html2canvas";

import { FeedbackModalComponent } from "../feedback-modal/feedback-modal.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { HelpPageApiService } from "src/app/core/api/helppage-api.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IHelpPageDTO, helpPageField } from "src/app/core/models/helppage.model";
import { ToastService } from "src/app/core/services/toast.service";
import { StorageService } from "src/app/core/services/storage.service";
import { IEmployeeDto } from "src/app/core/models/employee.model";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { Router } from "@angular/router";
import { NotificationAddModalComponent } from "../notification-add-modal/notification-add-modal.component";
import { environment as ionicEnvironment } from "src/environments/environment";

@Component({
	selector: "af-header-popover",
	templateUrl: "./header-popover.component.html",
	styleUrls: ["./header-popover.component.scss"]
})
export class HeaderPopoverComponent implements OnInit {
	ionicEnvironment = ionicEnvironment;
	@Input() componentName: string;
	@Input() disableNotificationButton: boolean;
	@Input() openModalDocs: boolean;
	generatedFormPath: string;
	isDocsPageSettingsModalOpen = false;
	openDocsPage = false;

	docsBaseUrl = "https://docs.autoflex10.eu/";
	docShortlinkUrl: string;
	helpPages: IHelpPageDTO[] = [];
	defaultDocumentShortlinkId = "532742145";

	formPath: string;
	form: FormGroup;
	url: SafeResourceUrl;
	newHelp = false;
	formValid = false;
	activeEmployee: IEmployeeDto;

	isCapturing = false;

	constructor(
		private modalController: ModalController,
		private popoverController: PopoverController,
		private router: Router,
		private sanitzer: DomSanitizer,
		private helpPageApiService: HelpPageApiService,
		private toastService: ToastService,
		private storageService: StorageService,
		private environmentService: EnvironmentService
	) {}

	async ngOnInit() {
		this.activeEmployee = await this.storageService.get("activeEmployee");
		const environment = new URL(this.environmentService.apiUrl).hostname.split(".").pop();
		switch (environment) {
			case "dev":
				this.docsBaseUrl = "https://docs.autoflex10.dev/";
				break;
			case "work":
				this.docsBaseUrl = "https://docs.autoflex10.work/";
				break;
			case "vip":
				this.docsBaseUrl = "https://docs.autoflex10.vip/";
				break;
			default:
				this.docsBaseUrl = "https://docs.autoflex10.eu/";
				break;
		}

		this.generatedFormPath = this.generateFormPath();

		this.form = new FormGroup({
			helpPageId: new FormControl(""),
			shortLinkId: new FormControl("", Validators.required),
			formPath: new FormControl(this.generatedFormPath)
		});
		this.form.controls.formPath.disable();

		this.form.controls.shortLinkId.valueChanges.subscribe((value: string) => {
			if (value.startsWith("https://docs.autoflex10")) {
				const shortLinkId = value.split("/").pop();
				this.form.controls.shortLinkId.setValue(shortLinkId, { emitEvent: false });
				this.formValid = this.form.valid;
			} else this.formValid = this.form.valid;
		});

		await this.getAllHelpPages().catch((error) => {
			this.toastService.presentFailureToast(error);
		});
		if (this.openModalDocs) this.getDocsPage(true);
	}

	async addFeedback() {
		this.dismiss();
		this.isCapturing = true;

		// Wait to capture the screenshot because the popover needs a moment to close
		setTimeout(async () => {
			const screenshot = await this.captureBrowserScreenshot();

			const modal = await this.modalController.create({
				component: FeedbackModalComponent,
				componentProps: { screenshot }
			});
			await modal.present();

			this.isCapturing = false;
		}, 100);
	}

	async captureBrowserScreenshot(): Promise<string> {
		return new Promise(async (resolve) => {
			try {
				const element = document.body;
				const canvas = await html2canvas(element, { scale: 1 });
				const dataUrl = canvas.toDataURL("image/png");
				resolve(dataUrl);
			} catch (error) {
				console.error("Error capturing browser screenshot:", error);
				resolve(null);
			}
		});
	}

	dismiss() {
		this.popoverController.dismiss();
	}

	async getAllHelpPages() {
		const fields = [helpPageField.helpPageId, helpPageField.formPath, helpPageField.documentShortlinkId, helpPageField.createdDate, helpPageField.modifiedDate];
		const promise: Promise<IHelpPageDTO[]> = new Promise(async (resolve, reject) => {
			let nextpage = true;
			let page = 1;
			while (nextpage) {
				await this.helpPageApiService
					.getHelpPages(page, fields, 'form_path[like]:"mobile."')
					.toPromise()
					.then(async (helpPageResponse) => {
						this.helpPages.push(...helpPageResponse.data);
						nextpage = helpPageResponse.nextpage;
						page = page + 1;
						if (!nextpage) resolve(this.helpPages);
					});
			}
		});
		const result = await promise;
		this.storageService.set("helpPages", result);
		return result;
	}

	async getDocsPage(isOpen: boolean) {
		if (!isOpen) this.dismiss();
		else {
			if (this.generatedFormPath) {
				const docsPageData = await this.getDocsPageData(this.generatedFormPath);
				if (docsPageData.form_path === undefined && docsPageData.document_shortlink_id === undefined) {
					this.docShortlinkUrl = `${this.docsBaseUrl}link/${this.defaultDocumentShortlinkId}/detail`;
					this.url = this.cleanUrl(this.docShortlinkUrl);
				} else {
					this.formPath = docsPageData.form_path;
					this.docShortlinkUrl = `${this.docsBaseUrl}link/${docsPageData.document_shortlink_id}/detail`;
					this.url = this.cleanUrl(this.docShortlinkUrl);
				}
			}
		}

		this.openDocsPage = isOpen;
	}

	async getDocsPageData(formPath: string): Promise<IHelpPageDTO> {
		const docsPageData: IHelpPageDTO[] = await this.storageService.get("helpPages");
		if (docsPageData?.length > 0) {
			const result = docsPageData.filter((helpPage) => helpPage.form_path === formPath);
			if (result.length > 0) return result[0];
			else {
				return {
					help_page_id: undefined,
					form_path: undefined,
					document_shortlink_id: undefined
				};
			}
		} else {
			return {
				help_page_id: undefined,
				form_path: undefined,
				document_shortlink_id: undefined
			};
		}
	}

	async setDocsPageSettingModalOpen(isOpen: boolean) {
		if (this.activeEmployee.is_helpdesk_account === 1) {
			if (!isOpen) this.dismiss();
			else {
				if (this.generatedFormPath) {
					const docsPageData = await this.getDocsPageData(this.generatedFormPath);
					if (docsPageData.form_path === undefined && docsPageData.document_shortlink_id === undefined) {
						this.form.controls.shortLinkId.setValue("");
						this.form.controls.formPath.setValue(this.generatedFormPath);
						this.form.markAsPristine();
						this.newHelp = true;
					} else {
						this.formPath = docsPageData.form_path;
						this.docShortlinkUrl = `${this.docsBaseUrl}link/${docsPageData.document_shortlink_id}/detail`;
						this.url = this.cleanUrl(this.docShortlinkUrl);
						this.form.controls.shortLinkId.setValue(docsPageData.document_shortlink_id);
						this.form.controls.formPath.setValue(docsPageData.form_path);
						this.form.controls.helpPageId.setValue(docsPageData.help_page_id);
						this.form.markAsPristine();
						this.newHelp = false;
					}
				}
			}
			this.isDocsPageSettingsModalOpen = isOpen;
		}
	}

	cleanUrl(url: string) {
		return this.sanitzer.bypassSecurityTrustResourceUrl(url);
	}

	goToPageUrl(url: string) {
		window.open(url, "_blank");
	}
	//NOTIFICATION FUNCTIONALITY
	goToNotifications() {
		this.dismiss();
		this.router.navigate(["/notification"]);
	}

	//NOTIFICATION FUNCTIONALITY
	async presentNotificationAddModal(replyToNotificationId?: string) {
		const modal = await this.modalController.create({
			component: NotificationAddModalComponent,
			backdropDismiss: false,
			componentProps: {
				replyToNotificationId
			}
		});
		await modal.present();
	}

	generateFormPath() {
		const pathName = window.document.location.pathname.replace(/([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/gm, "id");
		const componentName = this.componentName;
		const formPath = "mobile" + pathName.replace(/\//gm, ".") + "." + componentName;
		return formPath;
	}

	submit(type: string) {
		if (!this.form.valid) return;
		if (type === "add") {
			const postData: IHelpPageDTO = {
				form_path: this.form.controls.formPath.value,
				document_shortlink_id: this.form.controls.shortLinkId.value
			};
			this.helpPageApiService.addHelpPage(postData).subscribe((response) => {
				this.toastService.presentSuccessToast("Help page toegevoegd");
				this.setDocsPageSettingModalOpen(false);
			});
		} else if (type === "edit") {
			const helpPageId = this.form.controls.helpPageId.value;
			const putData: IHelpPageDTO = {
				form_path: this.form.controls.formPath.value,
				document_shortlink_id: this.form.controls.shortLinkId.value
			};
			this.helpPageApiService.editHelpPage(helpPageId, putData).subscribe(() => {
				this.toastService.presentSuccessToast("Help page aangepast");
				this.setDocsPageSettingModalOpen(false);
			});
		}
	}
}
