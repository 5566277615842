import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeNl from "@angular/common/locales/nl";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTrello as fabTrello } from "@fortawesome/free-brands-svg-icons";
import {
	faTable as falTable,
	faAddressCard as falAddressCard,
	faArchive as falArchive,
	faArrowToTop as falArrowToTop,
	faBarcodeRead as falBarcodeRead,
	faBars as falBars,
	faBell as falBell,
	faBellPlus as falBellPlus,
	faBuilding as falBuilding,
	faBullhorn as falBullhorn,
	faCalendar as falCalendar,
	faCalendarAlt as falCalendarAlt,
	faCalendarDay as falCalendarDay,
	faCamera as falCamera,
	faCar as falCar,
	faCarMechanic as falCarMechanic,
	faCheckCircle as falCheckCircle,
	faClipboardList as falClipboardList,
	faClock as falClock,
	faCloudDownload as falCloudDownload,
	faCodeBranch as falCodeBranch,
	faCog as falCog,
	faDollyFlatbed as falDollyFlatbed,
	faEdit as falEdit,
	faEllipsisV as falEllipsisV,
	faEnvelope as falEnvelope,
	faEnvelopeOpen as falEnvelopeOpen,
	faEuroSign as falEuroSign,
	faEye as falEye,
	faFingerprint as falFingerprint,
	faFlagCheckered as falFlagCheckered,
	faHandshake as falHandshake,
	faHistory as falHistory,
	faHome as falHome,
	faIdCard as falIdCard,
	faImages as falImages,
	faKey as falKey,
	faList as falList,
	faListAlt as falListAlt,
	faPaperPlane as falPaperPlane,
	faPhone as falPhone,
	faPlusCircle as falPlusCircle,
	faQuestionCircle as falQuestionCircle,
	faReceipt as falReceipt,
	faSearch as falSearch,
	faSignOut as falSignOut,
	faSortAmountDown as falSortAmountDown,
	faSortAmountUp as falSortAmountUp,
	faStar as falStar,
	faStopwatch as falStopwatch,
	faStream as falStream,
	faSync as falSync,
	faTasks as falTasks,
	faTimes as falTimes,
	faTireFlat as falTireFlat,
	faTrashAlt as falTrashAlt,
	faUser as falUser,
	faUserClock as falUserClock,
	faUserLock as falUserLock,
	faUsers as falUsers,
	faWarehouse as falWarehouse,
	faWatch as falWatch,
	faStickyNote as falStickyNote
} from "@fortawesome/pro-light-svg-icons";
import { faBoxAlt as farBoxAlt, faLockAlt as farLockAlt, faCarBuilding as farCardBuilding } from "@fortawesome/pro-regular-svg-icons";
import {
	faArrowAltRight as fasArrowAltRight,
	faBroadcastTower as fasBroadcastTower,
	faCaretDown as fasCaretDown,
	faCaretLeft as fasCaretLeft,
	faCaretRight as fasCaretRight,
	faChartBar as fasChartBar,
	faCheck as fasCheck,
	faCheckCircle as fasCheckCircle,
	faEnvelope as fasEnvelope,
	faEnvelopeOpen as fasEnvelopeOpen,
	faEuroSign as fasEuroSign,
	faExclamationCircle as fasExclamationCircle,
	faExclamationTriangle as fasExclamationTriangle,
	faFilter as fasFilter,
	faInfoCircle as fasInfoCircle,
	faLink as fasLink,
	faPaperPlane as fasPaperPlane,
	faPhone as fasPhone,
	faPlus as fasPlus,
	faPlusCircle as fasPlusCircle,
	faQuestionCircle as fasQuestionCircle,
	faStar as fasStar,
	faTimesCircle as fasTimesCircle,
	faTrashAlt as fasTrashAlt,
	faTrashUndoAlt as fasTrashUndoAlt,
	faUndo as fasUndo,
	faUnlink as fasUnlink,
	faLightbulbSlash as fasLightbulbSlash,
	faLightbulbOn as fasLightbulbOn,
	faChevronRight as fasChevronRight,
	faChevronLeft as fasChevronLeft,
	faChevronDoubleLeft as fasChevronDoubleLeft,
	faChevronDoubleRight as fasChevronDoubleRight,
	faMoon as fasMoon,
	faEclipseAlt as fasEclipseAlt,
	faQuestion as fasQuestion,
	faExternalLinkAlt as fasExternalLinkAlt
} from "@fortawesome/pro-solid-svg-icons";
// import { AppVersion } from "@ionic-native/app-version/ngx";
import { FingerprintAIO } from "@awesome-cordova-plugins/fingerprint-aio/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";

import { initApp } from "./app-initializer";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { AuthService } from "./core/services/auth.service";
import { EnvironmentService } from "./core/services/environment.service";
import { LoadingService } from "./core/services/loading.service";
import { StorageService } from "./core/services/storage.service";
import { ToastService } from "./core/services/toast.service";
import { SharedModule } from "./shared/shared.module";

registerLocaleData(localeNl, "nl");

// SENTRY
import * as SentryAngular from "@sentry/angular";

@NgModule({
	providers: [
		// AppVersion,
		FingerprintAIO,
		{
			deps: [AuthService, EnvironmentService, LoadingService, ToastService, StorageService, HttpClient, SentryAngular.TraceService],
			multi: true,
			provide: APP_INITIALIZER,
			useFactory: initApp
		},
		{
			provide: LOCALE_ID,
			useValue: "nl-NL"
		},
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		{
			provide: ErrorHandler,
			// Attach the Sentry ErrorHandler
			useValue: SentryAngular.createErrorHandler()
		},
		{
			provide: SentryAngular.TraceService,
			deps: [Router]
		}
	],
	declarations: [AppComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		CoreModule,
		FontAwesomeModule,
		HttpClientModule,
		IonicModule.forRoot({ backButtonText: "", innerHTMLTemplatesEnabled: true }),
		IonicStorageModule.forRoot({
			storeName: "__autoflexdb",
			version: 1
		}),
		SharedModule
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private faIconLibrary: FaIconLibrary) {
		this.faIconLibrary.addIcons(
			fasLightbulbSlash,
			fasLightbulbOn,
			falAddressCard,
			falSearch,
			falArrowToTop,
			fasArrowAltRight,
			falBars,
			falBell,
			falBellPlus,
			falBuilding,
			falBullhorn,
			falCamera,
			falBarcodeRead,
			falCar,
			falArchive,
			falUserLock,
			falClipboardList,
			falCloudDownload,
			falCog,
			falEdit,
			falEllipsisV,
			falEnvelope,
			falEnvelopeOpen,
			falEuroSign,
			falEye,
			falFingerprint,
			falHome,
			falImages,
			falKey,
			falReceipt,
			falList,
			falCheckCircle,
			falCodeBranch,
			falTireFlat,
			falFlagCheckered,
			falWarehouse,
			falIdCard,
			falPaperPlane,
			falPhone,
			falPlusCircle,
			falQuestionCircle,
			falSignOut,
			falSortAmountDown,
			falCarMechanic,
			falHandshake,
			falSortAmountUp,
			falStar,
			falStream,
			falSync,
			falStopwatch,
			falClock,
			falUserClock,
			falWatch,
			falTimes,
			falTrashAlt,
			falUser,
			falUsers,
			falHistory,
			fasCaretDown,
			fasCaretLeft,
			fasCaretRight,
			fasChartBar,
			fasCheck,
			fasCheckCircle,
			fasEnvelope,
			fasEnvelopeOpen,
			fasExclamationTriangle,
			fasExclamationCircle,
			fasFilter,
			fasInfoCircle,
			fasPaperPlane,
			fasPhone,
			fasPlus,
			fasQuestionCircle,
			fasStar,
			fasTimesCircle,
			fasTrashAlt,
			fasTrashUndoAlt,
			fasUndo,
			fasUnlink,
			fasLink,
			fasChevronRight,
			fasChevronLeft,
			fasChevronDoubleLeft,
			fasChevronDoubleRight,
			fasMoon,
			fasEclipseAlt,
			farLockAlt,
			farBoxAlt,
			farCardBuilding,
			fasPlusCircle,
			fasQuestion,
			falCalendar,
			falCalendarAlt,
			falCalendarDay,
			falTasks,
			fasBroadcastTower,
			falDollyFlatbed,
			fasEuroSign,
			falListAlt,
			fabTrello,
			falTable,
			fasExternalLinkAlt,
			falStickyNote
		);
	}
}
