import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { ApiService } from "../api/api.service";

@Injectable({
	providedIn: "root"
})
export class EnvironmentService {
	apiUrl: string;
	environment: string;
	userId: string;
	versionCode: string;
	versionNumber: string;

	get version() {
		if (this.versionCode) return `${this.versionNumber} (${this.versionCode})`;

		return this.versionNumber;
	}

	constructor(private apiService: ApiService, private httpClient: HttpClient) {}

	getEnvironment(username: string, apiUrl?: string): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("username", username);
		httpParams = httpParams.set("needsAuthentication", "false");

		return this.httpClient.get<any>(`${apiUrl ?? this.apiService.apiUrl}/util/environment`, { params: httpParams }).pipe(
			tap((environmentResponse) => {
				this.apiUrl = environmentResponse.api_url;
				this.environment = environmentResponse.environment;
				this.userId = environmentResponse.user_id;
			})
		);
	}
}
