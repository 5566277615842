import { Injectable } from "@angular/core";
import { Device } from "@capacitor/device";
import { PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { isNil, get, size, forEach } from "lodash-es";

import { AuthService } from "./auth.service";
import { UserApiService } from "../api/user-api.service";
import { DeviceType, IUserDeviceDto, UserDeviceField } from "../models/user.model";
import { BehaviorSubject } from "rxjs";
import { INotificationDto, PushPermissionStatus } from "../models/notification.model";
import { ToastService } from "./toast.service";

@Injectable({
	providedIn: "root"
})
export class NotificationRegistrationService {
	public pushRegistrationToken: BehaviorSubject<string> = new BehaviorSubject(undefined);
	public pushPermissionStatus: BehaviorSubject<PushPermissionStatus> = new BehaviorSubject(undefined);
	public registrationError: BehaviorSubject<string> = new BehaviorSubject(undefined);
	public latestNotification: BehaviorSubject<PushNotificationSchema> = new BehaviorSubject(undefined);

	constructor(private userApiService: UserApiService, private authService: AuthService, private toastService: ToastService) {
		Device.getInfo().then((info) => {
			if (info.platform !== "web") {
				PushNotifications.checkPermissions().then((permStatus) => {
					this.pushPermissionStatus.next(permStatus.receive as PushPermissionStatus);
				});
				this.addListeners();
			} else console.warn("Detected platform web, unable to register for notifications");
		});
	}

	private deviceTypeForPlatform(platform: "ios" | "android" | "web"): DeviceType {
		return get(
			{
				ios: DeviceType.IOS,
				android: DeviceType.Android,
				web: DeviceType.Web
			},
			platform
		);
	}

	/**
	 * Retrieve all Userdevice by device_key or push_token, auto-paginate.
	 */
	private listUserDeviceByDevice(deviceKey: string, pushToken: string, page: number = 1): Promise<IUserDeviceDto[]> {
		return new Promise<IUserDeviceDto[] | undefined>((resolve, reject) => {
			this.userApiService.getUserDeviceList(page, `device_key[eq]:${deviceKey},-push_token[eq]:${pushToken}`).subscribe(async (userDeviceResponse) => {
				const result: IUserDeviceDto[] = userDeviceResponse.data || [];

				if (userDeviceResponse.nextpage) {
					const nextPageResult = await this.listUserDeviceByDevice(deviceKey, pushToken, page + 1);

					result.push(...nextPageResult);
				}

				resolve(result);
			});
		});
	}

	private handleRegistration = async (token: Token) => {
		const userId = await this.authService.getUserId();
		const deviceInfo = await Device.getInfo();
		const deviceId = await Device.getId();

		try {
			const deviceType = this.deviceTypeForPlatform(deviceInfo.platform);
			if (isNil(deviceType)) {
				console.error("notification registration: could not retrieve deviceType");
				return;
			}

			console.log("handle registration: push_token:", token.value);
			this.pushRegistrationToken.next(token.value);

			const existingDevicesByDevice = await this.listUserDeviceByDevice(deviceId.identifier, token.value);

			console.log(`handle registrationn: found userDevices with matching device_key or push_token: size=${size(existingDevicesByDevice)}`);

			let foundMatch = false;
			forEach(existingDevicesByDevice, (device) => {
				if (
					// If all parameters match, and we haven't found a match before:
					!foundMatch &&
					device[UserDeviceField.DeviceKey] === deviceId.identifier &&
					device[UserDeviceField.PushToken] === token.value &&
					device[UserDeviceField.UserId] === userId
				) {
					// Userdevice up to date, do nothing
					console.log("handle registration: found matching userDevice, ignoring...");
					foundMatch = true;
				} else {
					console.log("handle registration: deleting non-matching userDevice, id=", device[UserDeviceField.UserDeviceId]);
					this.userApiService
						.deleteUserDevice(device[UserDeviceField.UserDeviceId])
						.subscribe((result) => console.log("handle registration: userDevice deleted"));
				}
			});

			// If we still haven't found a matching Userdevice
			if (!foundMatch) {
				// Create a new Userdevice
				return this.userApiService
					.addUserDevice({
						description: deviceInfo.name,
						device_key: deviceId.identifier,
						device_type: deviceType,
						// TODO retrieve app user agent?
						device_user_agent: "",
						push_token: token.value,
						user_id: userId
					})
					.subscribe((result) => {
						console.log("handle registration: registered new Userdevice!");
					});
			}
		} catch (e) {
			console.warn(`Could not handle notification registration: ${e}`);
		}
	};

	/**
	 * Listen for events sent by the PushNotification plugin.
	 */

	// NOTIFICATION FUNCTIONALITY
	private addListeners = async () => {
		await PushNotifications.addListener("registration", this.handleRegistration);

		await PushNotifications.addListener("registrationError", (err) => {
			console.error("Registration error: ", err.error);
			this.registrationError.next(err.error);
		});

		await PushNotifications.addListener("pushNotificationReceived", (pushNotification) => {
			console.log("Push notification received: ", pushNotification);
			this.latestNotification.next(pushNotification);

			const notificationMeta = JSON.parse(pushNotification.data?.notification) as INotificationDto;

			this.toastService.presentNotificationToast(pushNotification.title, pushNotification.body, notificationMeta.reply_thread_id);
		});

		await PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
			console.log("Push notification action performed", notification.actionId, notification.inputValue);
		});
	};

	/**
	 * Prompt the user for notification permissions. The user will see a native prompt.
	 *
	 * Returns `true` if permission granted.
	 */
	public async tryAskPermission(): Promise<boolean> {
		let permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive === "prompt") {
			permStatus = await PushNotifications.requestPermissions();
			this.pushPermissionStatus.next(permStatus.receive as PushPermissionStatus);
		}

		if (permStatus.receive !== "granted") {
			console.warn(`Invalid notification permission status: ${permStatus.receive}`);

			return false;
		}

		console.log("PERMISSION STATUS", permStatus.receive);

		return true;
	}

	/**
	 * Try to register this device.
	 */
	public async tryRegisterDevice() {
		const info = await Device.getInfo();
		if (info.platform === "web") {
			// Device not supported
			return;
		}
		try {
			const hasPermission = await this.tryAskPermission();
			if (!hasPermission) throw new Error("No permission");

			console.log("TRYREGISTERDEVICE: REGISTERING DEVICE");
			return PushNotifications.register();
		} catch (e) {
			console.warn(`Could not register device for notifications: ${e}`);
		}
	}
}
