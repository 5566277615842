import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LicensePlateService {
    private validPatterns: string[] = [
        "XX-99-99",
        "99-99-XX",
        "99-XX-99",
        "XX-99-XX",
        "XX-XX-99",
        "99-XX-XX",
        "99-XXX-9",
        "9-XXX-99",
        "XX-999-X",
        "X-999-XX",
        "XXX-99-X",
        "X-99-XXX",
        "9-XX-999",
        "999-XX-9"
    ];

    public hasValidPattern(licensePlate: string): boolean {
        return this.validPatterns.indexOf(licensePlate.replace(/[a-zA-Z]/g, "X").replace(/[0-9]/g, "9")) !== -1;
    }
}
