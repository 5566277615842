import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { IEmployeeDto } from "../../../core/models/employee.model";
import { IOrganizationDto } from "src/app/core/models/organization.model";
import { IModuleDto } from "src/app/core/models/auth.model";
import { StorageService } from "src/app/core/services/storage.service";

/**
 * Based on EmployeeItemComponent
 */
@Component({
	selector: "af-notification-select-employee-item",
	templateUrl: "./notification-select-employee-item.component.html",
	styleUrls: ["./notification-select-employee-item.component.scss"]
})
export class NotificationSelectEmployeeItemComponent implements OnInit {
	@Input() employee: IEmployeeDto;
	@Input() organization: IOrganizationDto;
	@Input() selectedEmployees: IEmployeeDto[] = [];
	@Input() disabled: boolean;
	@Input() isSelected: boolean;
	@Output() select = new EventEmitter<{ employee: IEmployeeDto }>();
	@Output() delete = new EventEmitter<{ employee: IEmployeeDto }>();
	modules = [];

	constructor(
		private storageService: StorageService
	) { }

	async ngOnInit() {
		// Used to check multi-vestiging
		this.modules = ((await this.storageService.get("modules")) as IModuleDto[]).flatMap((module) => module.name);
	}

	onDelete(e: Event) {
		// Since this button overlaps with the (select) click area, we need to stop propagation
		e.stopPropagation();

		this.delete.emit({ employee: this.employee })
	}

	onSelect(e: Event) {
		e.stopPropagation();

		if (this.isSelected) return

		this.select.emit({ employee: this.employee })
	}
}
