import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AccessControlService } from "src/app/shared/services/access-control.service";

import { ToastService } from "../services/toast.service";

@Injectable({
	providedIn: "root"
})
export class AccessGuard  {
	constructor(private router: Router, private accessControlService: AccessControlService, private toastService: ToastService) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		if (await this.hasAccess(route)) return true;
		else {
			this.router.navigate(["/home"], { replaceUrl: true });
			this.toastService.presentFailureToast("Onvoldoende rechten");
			return false;
		}
	}

	async hasAccess(route: ActivatedRouteSnapshot): Promise<boolean> {
		if (route.data)
			return await this.accessControlService.checkAccess(Object.keys(route.data)[0], route.data[Object.keys(route.data)[0]], route.data.permission);

		// TODO temp fix until we have basic module implemented
		return true;
	}
}
