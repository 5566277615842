import { Injectable } from "@angular/core";
import { get } from "lodash-es"
import { forkJoin } from "rxjs";
import { v4 as uuid } from "uuid";

import { INotificationDto, NotificationType } from "../models/notification.model"
import { NotificationApiService } from "./notification-api.service";
import { UserApiService } from "./user-api.service";
import { DeviceType } from "../models/user.model";
import { StorageService } from "../services/storage.service";
import { AuthService } from "../services/auth.service";
import { NotificationApiv3Service } from "./notification-apiv3.service";

@Injectable({
    providedIn: "root"
})
export class NotificationAddService {
    constructor(
        private notificationApiService: NotificationApiService,
		private notificationApiv3Service: NotificationApiv3Service,
		private userApiService: UserApiService,
		private storageService: StorageService,
		private authService: AuthService
    ) {}

	/**
	 * Send a push notification to a user, using the apiv3 service.
	 * 
	 * TODO: Batch send push to try to increase performance 
	 * 
	 * @param title The title of the notification
	 * @param body The body of the notification
	 * @param pushToken The unique push token for the push receiver
	 * @param notification The original notification object.
	 * The embedded notification_id and reply_thread_id are used by the receiver to be able to deeplink into the detail page.
	 * @param replyThreadId The notification reply_thread_id.
	 * Used to group notifications together in a single stack on the native phone lock screen.
	 */
	public async sendPush(title: string, body: string, pushToken: string, notification: INotificationDto, replyThreadId: string) {
		const authToken = await this.authService.getAuthToken();

		try {
			const result = await this.notificationApiv3Service.sendPush(authToken, {
				data: {
					subtitle: "",
					title,
					body,
					data: {
						// Send the entire notification object as JSON string data
						notification: JSON.stringify(notification)
					},
					token: pushToken,
					originId: notification.created_by,
					originType: "employee",
					threadId: replyThreadId
				}
			})
			// eslint-disable-next-line no-underscore-dangle
			if (result.sendPush.__typename === "SendPushSuccess") 
				console.log("SEND PUSH SUCCESS:", result.sendPush.messageId);
			else 
				console.log("SEND PUSH ERROR:", result.sendPush.i18n.key);

			return result;

		} catch (e) {
			console.error(`sendPush: failed to send notification to ${pushToken}: ${e}`)
			throw new Error(`sendPush: ${e}`)
		}
	}

	/**
	 * Send a notification and push message to multiple users.
	 * 
	 * 1. Fetches all UserDevice objects for each user to determine their push_token.
	 * 2. Creates a new Notification object.
	 * 3. Creates a new NotificationToUser object for each user
	 * 4. Calls `this.sendPush` for each user
	 * 
	 * @param receiverEmployeeUserIds The user_id of the employees who should receive notifications.
	 * The current logged in user always gets a notificationToUser object even if they are not added to this list,
	 * but they do not get a push message unless they were added to this list.
	 * @param notificationText The actual body of the notificaiton. 
	 * The title of the notification is automatically generated in this function.
	 * @param replyThreadId The reply_thread_id of this notificaiton. 
	 * If supplied, the notification will be formatted as a response.
	 * If not supplied, a reply_thread_id will be automatically generated.
	 */
	public async sendNotifications(receiverEmployeeUserIds: string[], notificationText: string, replyThreadId?: string) {
		const activeEmployee = await this.storageService.get("activeEmployee");
		const sendPushToSelf = await this.storageService.get("sendPushToSelf");
		const loggedInUserId = await this.authService.getUserId();

		// Use the reply_thread_id from the replyNotification if available, otherwise generate a new one
		const notificationGroupId = replyThreadId || uuid()
		const pushTitle = replyThreadId
			? `Nieuw antwoord van ${activeEmployee.v_display_name}`
			: `Nieuw bericht van ${activeEmployee.v_display_name}`

		this.notificationApiService
			.addNotification({
				icon: "fas fa-icon",
				notification_text: notificationText,
				reply_thread_id: notificationGroupId,
				notification_type: NotificationType.Custom
			})
			.subscribe((result) => {
				/**
				 * The inserted notification
				 */
				const notification = result.data[0];

				const notificationToUserObservableMap = receiverEmployeeUserIds.map((user_id: string) =>
					// TODO: if one of them fails, all of them fail
					this.notificationApiService.addNotificationToUser({
						notification_id: get(notification, "notification_id"),
						user_id
					})
				);

				//  Wait for all NotificationToUser to be added
				forkJoin(notificationToUserObservableMap).subscribe(() => {
					// Only send push messages to self if that settings option is enabled
					const pushReceiverUserIds = sendPushToSelf === true ? receiverEmployeeUserIds : receiverEmployeeUserIds.filter(id => id !== loggedInUserId)

					// TODO: Known issue: users with more than 100 devices will miss out on some notifications
					const deviceTokenObservableMap = pushReceiverUserIds.map((user_id: string) =>
						this.userApiService.getUserDeviceList(1, `user_id[eq]:${user_id},(device_type[eq]:${DeviceType.Android},-device_type[eq]:${DeviceType.IOS})`)
					);

					forkJoin(deviceTokenObservableMap).subscribe((userDeviceList) => {
						const allDeviceTokens = userDeviceList.flatMap((user) => user.data.map((device) => device.push_token));

						return Promise.all(
							allDeviceTokens.map((deviceToken) => this.sendPush(pushTitle, notificationText, deviceToken, notification, notificationGroupId))
						).catch(e => {
							throw new Error(`sendPush: ${e}`)
						})
					});
				});
			});
	}
}
