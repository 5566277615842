import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FingerprintAIO } from "@awesome-cordova-plugins/fingerprint-aio/ngx";
import { BehaviorSubject, from, Observable } from "rxjs";
import { AccessControlService } from "src/app/shared/services/access-control.service";
import { v4 as uuid } from "uuid";

import { AuthApiService } from "../api/auth-api.service";
import { ILoginParams, ILoginResponse, IModuleDto, ITokenResponse } from "../models/auth.model";
import { AnalyticsService } from "./analytics.service";
import { EnvironmentService } from "./environment.service";
import { StorageService } from "./storage.service";

@Injectable({
	providedIn: "root"
})
export class AuthService {
	authToken = "";
	tokenValidUntil = "";
	redirectUrl = "/";
	themeFromStorage: boolean;
	autoThemeFromStorage: boolean;

	private modules: IModuleDto[] = undefined;

	loggedIn = new BehaviorSubject<boolean>(false);

	constructor(
		private authApiService: AuthApiService,
		private fingerprintAIO: FingerprintAIO,
		private storageService: StorageService,
		private analyticsService: AnalyticsService,
		private accessControlService: AccessControlService,
		private environmentService: EnvironmentService,
		private httpClient: HttpClient
	) {}

	async applyThemeUser() {
		this.themeFromStorage = await this.storageService.get("darkTheme");
		this.autoThemeFromStorage = await this.storageService.get("autoTheme");
		if (this.themeFromStorage) document.body.setAttribute("theme", "dark");
		else if (this.autoThemeFromStorage === true || this.autoThemeFromStorage === null || this.autoThemeFromStorage === undefined) {
			// default behaviour apply auto theme
			this.storageService.set("autoTheme", true);
			const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
			if (prefersDark.matches) document.body.setAttribute("theme", "dark");
			prefersDark.addEventListener("change", (mediaQuery) => {
				this.storageService.get("autoTheme").then((autoTheme) => {
					if (mediaQuery.matches && autoTheme) document.body.setAttribute("theme", "dark");
					else if (!this.themeFromStorage) document.body.removeAttribute("theme");
					else if (this.themeFromStorage) document.body.setAttribute("theme", "dark");
				});
			});
		}
	}

	async loggedInFunction() {
		this.loggedIn.next(await this.isLoggedIn());
		// Set analytics services
		this.analyticsService.initUserId();
		this.applyThemeUser();
	}

	async checkLoggedInToken(token: string): Promise<ITokenResponse> {
		if (!this.httpClient) this.httpClient = new HttpClient(null);
		let httpParams = new HttpParams();
		httpParams = httpParams.set("token_id", token);
		httpParams = httpParams.set("needsAuthentication", "false");
		return await this.httpClient.get<ITokenResponse>(`${this.environmentService.apiUrl}/authenticate/token`, { params: httpParams }).toPromise();
	}

	addFingerprint(): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			const fingerprint = uuid();
			this.authApiService.addFingerprint(fingerprint).subscribe(
				async () => {
					await this.setFingerprint(fingerprint);
					resolve();
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	async canLoginWithFingerprint(): Promise<boolean> {
		if (await this.isFingerprintAuthenticationAvailable()) return await this.hasFingerprint();

		return false;
	}

	getFingerprint(): Promise<string> {
		return this.storageService.get("fingerprint");
	}

	async getLoginParams(): Promise<ILoginParams> {
		this.applyThemeUser();
		return {
			fingerprint: await this.getFingerprint(),
			organization: await this.getOrganization(),
			password: await this.getPassword(),
			username: await this.getUsername(),
			userId: await this.getUserId()
		};
	}

	getOrganization(): Promise<string> {
		return this.storageService.get("organization");
	}

	getUserId(): Promise<string> {
		return this.storageService.get("userId");
	}

	getPassword(): Promise<string> {
		return this.storageService.get("password");
	}

	getTokenValidUntil() {
		return this.storageService.get("tokenValidUntil");
	}

	getUsername(): Promise<string> {
		return this.storageService.get("username");
	}

	async hasFingerprint(): Promise<boolean> {
		const fingerprint = await this.getFingerprint();
		return !!fingerprint;
	}

	async isFingerprintAuthenticationAvailable(): Promise<boolean> {
		try {
			await this.fingerprintAIO.isAvailable();
			return true;
		} catch (error) {
			return false;
		}
	}

	hasModule(module: string) {
		return this.modules.find((m) => m.name === module) !== undefined;
	}

	hasModules(modules: string[]) {
		return modules.find((m) => this.hasModule(m)) !== undefined;
	}

	async isLoggedIn(): Promise<boolean> {
		const token = await this.getAuthToken();
		if (!this.modules) this.modules = await this.storageService.get("modules");

		return token && this.modules?.length > 0;
	}

	async login(loginParams: ILoginParams): Promise<ILoginParams> {
		const promise = new Promise<ILoginParams>(async (resolve, reject) => {
			try {
				const loginResponse = await this.authApiService.login(loginParams).toPromise();
				await this.setAuthToken(loginResponse.token);
				await this.setTokenValidUntil(loginResponse.token_valid_until);
				await this.setUserId(loginResponse.user_id);
				await this.getModulesAndAccess();
				resolve(loginResponse);
			} catch (error) {
				const username = await this.storageService.get("username"),
					password = await this.storageService.get("password"),
					fingerprint = await this.storageService.get("fingerprint");
				await this.storageService.clearStorage();
				this.loggedIn.next(false);
				await this.storageService.set("username", username);
				await this.storageService.set("password", password);
				await this.storageService.set("fingerprint", fingerprint);
				// window.location.reload();
				reject(error);
			}
		});

		return promise;
	}

	async getModulesAndAccess() {
		const modulesResponse = await this.authApiService.getModules().toPromise();
		this.modules = modulesResponse.modules.data;
		this.storageService.set("modules", this.modules);
		await this.accessControlService.initAccess();
	}

	async loginWithoutRelog() {
		const authToken = await this.getAuthToken();
		if (authToken) this.authToken = authToken;
		if (!this.modules) this.modules = await this.storageService.get("modules");
		const username = await this.storageService.get("username");

		await this.environmentService.getEnvironment(username).toPromise();
	}

	async logout(noReload?: boolean): Promise<Observable<any>> {
		const username = await this.storageService.get("username"),
			password = await this.storageService.get("password"),
			fingerprint = await this.storageService.get("fingerprint"),
			darkTheme = await this.storageService.get("darkTheme"),
			autoTheme = await this.storageService.get("autoTheme"),
			token = await this.getAuthToken();

		const promise = this.authApiService
			.logout(token)
			.toPromise()
			.then(async () => {
				this.deleteAuthToken();
				await this.setTokenValidUntil("");

				this.modules = [];
				this.accessControlService.removeAccess();
				this.storageService.clearStorage();

				await this.storageService.set("darkTheme", darkTheme);
				await this.storageService.set("autoTheme", autoTheme);
				await this.storageService.set("username", username);
				await this.storageService.set("password", password);
				await this.storageService.set("fingerprint", fingerprint);
				this.loggedIn.next(false);
				if (noReload === true) return;
				else {
					this.loggedInFunction();
					this.accessControlService.removeAccess();
					this.storageService.remove("organization");
					// reload clears the cache and the objectUrl of the image
					// if logged out and reloaded you will navigate to /login anyway
					window.location.reload();
				}
			});
		return from(promise);
	}

	refreshTokenOrg(selectedOrganizationId: string) {
		return new Promise<void>(async (resolve) => {
			this.authApiService.refreshToken(this.authToken, selectedOrganizationId ? selectedOrganizationId : null).subscribe(async (loginResponse) => {
				await this.setAuthToken(loginResponse.token);
				await this.setTokenValidUntil(loginResponse.token_valid_until);
				await this.loginWithoutRelog();
				window.location.reload();
				resolve();
			});
		});
	}

	async refreshAuthToken(): Promise<ILoginResponse> {
		const promise = new Promise<ILoginResponse>(async (resolve) => {
			const authToken = await this.getAuthToken();
			if (authToken) this.authToken = authToken;
			if (!this.modules) this.modules = await this.storageService.get("modules");
			const refreshtoken = await this.authApiService.refreshToken(this.authToken).toPromise();
			resolve(refreshtoken);
		});
		return promise;
	}

	setAuthToken(authToken: string): Promise<any> {
		this.authToken = authToken;
		return this.storageService.set("authToken", authToken);
	}
	getAuthToken(): Promise<any> {
		return this.storageService.get("authToken");
	}
	deleteAuthToken(): Promise<any> {
		this.authToken = "";
		return this.storageService.remove("authToken");
	}

	removeFingerprint(): Promise<any> {
		return new Promise<void>((resolve, reject) => {
			this.authApiService.removeFingerprint().subscribe(
				async () => {
					await this.setFingerprint("");
					resolve();
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	setFingerprint(fingerprint: string): Promise<any> {
		return this.storageService.set("fingerprint", fingerprint);
	}

	setOrganization(organization: string): Promise<any> {
		return this.storageService.set("organization", organization);
	}

	setUserId(userId: string): Promise<any> {
		return this.storageService.set("userId", userId);
	}

	setPassword(password: string): Promise<any> {
		return this.storageService.set("password", password);
	}

	setTokenValidUntil(tokenValidUntil: string): Promise<any> {
		this.tokenValidUntil = tokenValidUntil;
		return this.storageService.set("tokenValidUntil", tokenValidUntil);
	}
	clearStorage() {
		return this.storageService.clearStorage();
	}

	setUsername(username: string): Promise<any> {
		return this.storageService.set("username", username);
	}

	async showFingerprintAuthenticationDialogue(): Promise<boolean> {
		try {
			await this.fingerprintAIO.show({
				title: "Autoflex Vingerafdruk", // Android: Used for encryption. iOS: used for dialogue if no `localizedReason` is given.
				disableBackup: true, // Only for Android(optional)
				fallbackButtonTitle: "Gebruik pincode", // Only for iOS
				description: "Inloggen" // Only for iOS
			});
			await this.fingerprintAIO.registerBiometricSecret({
				description: "Autoflex Vingerafdruk",
				secret:
					"1qyJ7CaCUnrZKoBA91cdMLY2DMn471dOE4IS55rQKv0EY2kEoCnYha71lad6A0QvvNiaVVC7YhznTDltaoWMgjaBwpMtl1MhzwsrA38Zryu5lMSSVvjx4NLk61UacguSQvXStWf6ATh91umizJFBDkNC2SJ52DqdHQQ5i1NE6pwN8enfODZ9M8hKfceyL3pJxhqipHawCr1cLTuBFAWzZFbvlG2kEiK5vdTuAMFdwE8KlbN8zpn0eBoIBzPb6",
				invalidateOnEnrollment: true,
				disableBackup: true // always disabled on Android
			});
			return true;
		} catch (error) {
			return false;
		}
	}
}
