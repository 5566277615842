import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { StorageService } from "./storage.service";
import { INotificationQuery, NotificationField, NotificationFilterReadType, NotificationFilterType, NotificationOrderType, NotificationSortType, NotificationType } from "../models/notification.model";

@Injectable()
export class NotificationQueryService {
	queryChanges$ = new Subject<INotificationQuery>();

	initialQuery: INotificationQuery = {
		filter: NotificationFilterType.All,
		page: 1,
		order: NotificationOrderType.Descending,
		search: "",
		sort: NotificationSortType.CreatedDate,
		filterRead: NotificationFilterReadType.All,
		organization: ""
	};
	private query: INotificationQuery;

	constructor(private storageService: StorageService) {}

	getFilter(): string {
		const filter = [];

		if (this.query.filter === NotificationFilterType.System)
			filter.push(`notification_type[eq]:"${NotificationType.System}"`);
		else if (this.query.filter === NotificationFilterType.Custom) 
			filter.push(`notification_type[eq]:"${NotificationType.Custom}"`);
		 else if (this.query.filter === NotificationFilterType.Helpdesk) 
			filter.push(`notification_type[eq]:"${NotificationType.Helpdesk}"`);
		 else if (this.query.filter === NotificationFilterType.Orders) 
			filter.push(`notification_type[eq]:"${NotificationType.Orders}"`);
		 else if (this.query.filter === NotificationFilterType.RepairAdvices) 
			filter.push(`notification_type[eq]:"${NotificationType.RepairAdvices}"`);
		 else if (this.query.filter === NotificationFilterType.Users) 
			filter.push(`notification_type[eq]:"${NotificationType.Users}"`);
		
		// TODO: Probably keep this for multi-vestiging??
		if (this.query.organization) filter.push(`organization_id[eq]:${this.query.organization}`);

		if (this.query.search) filter.push(`notification_text[ilike]:"%${this.query.search}%"`)

		return filter.join(",");
	}

	getSort(): string {
		const order = this.query.order === NotificationOrderType.Ascending ? "" : "-";
		if (this.query.sort === NotificationSortType.CreatedDate) return `${order}${NotificationField.CreatedDate}`;

		return ""
	}

	getSearch(): string {
		return this.query.search;
	}

	getFilterRead(): boolean | null {
		if (this.query.filterRead === NotificationFilterReadType.Unread)
			return false
		if (this.query.filterRead === NotificationFilterReadType.Read)
			return true

		return null
	} 

	async init() {
		const query = this.initialQuery;
		if (query) {
			this.query = {
				filter: query.filter,
				page: this.initialQuery.page,
				order: query.order,
				search: this.initialQuery.search,
				sort: query.sort,
				filterRead: query.filterRead,
				organization: query.organization
			};
			this.queryChanges$.next(this.query);
		} else this.setQuery(this.initialQuery);
	}

	setQuery(query: INotificationQuery) {
		this.storageService.set("settings.notifications.query", {
			filter: query.filter,
			order: query.order,
			sort: query.sort,
			search: query.search,
			filterRead: query.filterRead,
			organization: query.organization
		});

		this.query = query;
		this.queryChanges$.next(query);
		return query;
	}
}
