<ion-header>
    <ion-toolbar color="primary" mode="md">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()" data-test="dismiss-button">
                <fa-icon [fixedWidth]="true" [icon]="['fal', 'times']" size="lg" slot="icon-only"></fa-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>Feedback</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form (ngSubmit)="submit()" [formGroup]="form">
        <ion-list class="ion-no-margin ion-no-padding">
            <ion-item>
                <ion-select label="Soort" cancelText="Annuleren" formControlName="type" okText="OK" data-test="select-input">
                    <ion-select-option value="bug" data-test="select-input-option">Probleem</ion-select-option>
                    <ion-select-option value="improvement" data-test="select-input-option">Verbetering</ion-select-option>
                    <ion-select-option value="feature" data-test="select-input-option">Nieuwe functionaliteit</ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-input label="Onderwerp" class="ion-text-end" formControlName="subject" type="text" data-test="subject-input"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="ion-text-wrap" position="stacked">Details - Geef zoveel mogelijk details</ion-label>
                <ion-textarea formControlName="body" rows="5" data-test="detail-textarea"></ion-textarea>
            </ion-item>
        </ion-list>

        <div *ngIf="!screenshot" class="ion-padding">
            <ion-button (click)="addScreenshot()" class="ion-no-margin" expand="block" data-test="addscreenshot-button">
                <fa-icon [fixedWidth]="true" [icon]="['fas', 'plus']" class="ion-margin-end"></fa-icon>
                Voeg screenshot toe
            </ion-button>
        </div>

        <div *ngIf="screenshot" class="ion-padding">
            <div class="af-picture-container">
                <img [src]="screenshot" alt="" class="af-picture" />
                <ion-button (click)="removeScreenshot()" class="af-picture-button" color="danger" data-test="removescreenshot-button">
                    <fa-icon [fixedWidth]="true" [icon]="['fas', 'trash-alt']" slot="icon-only"></fa-icon>
                </ion-button>
            </div>
        </div>
    </form>
</ion-content>

<ion-footer class="ion-padding">
    <ion-button (click)="submit()" [disabled]="form.invalid || isSubmitting" class="ion-no-margin" expand="block" data-test="submit-button">
        <fa-icon [fixedWidth]="true" [icon]="['fas', 'envelope']" class="ion-margin-end"></fa-icon>
        Verzend
        <ion-spinner *ngIf="isSubmitting" class="ion-margin-start"></ion-spinner>
    </ion-button>
</ion-footer>
