export const listsConfig = {
    communicationtype: { key: 'contact_communication_type_id', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    country: { key: 'country_id', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    orderstatus: { key: 'order_status_id', value: 'status_description', type: 'order_type', blocked: 'is_blocked', archived: 'is_archived' },
    fuelcode: { key: 'value', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    milometer: { key: 'value', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    paymentcondition: { key: 'payment_condition_id', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    transmission: { key: 'value', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    vehiclebody: { key: 'vehicle_body_id', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    vehiclerole: { key: 'vehicle_role_id', value: 'description', blocked: 'is_blocked', archived: 'is_archived' },
    vehicletype: { key: 'vehicle_type_id', value: 'description', blocked: 'is_blocked', archived: 'is_archived' }
};
