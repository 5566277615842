import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { UtilApiService } from "../api/util-api.service";
import { listsConfig } from "../config/lists.config";
import { IList, ILists } from "../models/list.model";

@Injectable({
	providedIn: "root"
})
export class ListService {
	private cache: ILists = {};

	constructor(private utilApiService: UtilApiService) {}

	getLists(listIds: string[]): Observable<ILists> {
		const nonCachedListIds = listIds.filter((listId: string) => !this.cache[listId]);
		const observable = nonCachedListIds.length ? this.utilApiService.getLists(nonCachedListIds) : of([]);

		return observable.pipe(
			map((listsResponse: any) => {
				const lists: ILists = {};

				nonCachedListIds.forEach((listId: string) => {
					if (listsResponse[listId]) {
						const list = listsResponse[listId].data || listsResponse[listId];
						this.cache[listId] = this.normalizeList(listId, list);
					} else console.log(`Error listid: ${listId} doens't returns data`);
				});

				listIds.forEach((listId: string) => {
					lists[listId] = this.cache[listId];
				});
				return lists;
			})
		);
	}

	private normalizeList(listId: string, list: any): IList {
		const listAsKeys = [];
		const listAsObject = {};
		const listType = [];
		const listArchived = [];
		const listBlocked = [];
		const listConfig = listsConfig[listId];

		if (listConfig) {
			list.forEach((listItemResponse: any) => {
				const key = listItemResponse[listConfig.key];
				const value = listItemResponse[listConfig.value];
				listAsKeys.push(key);
				listAsObject[key] = value;
				if (listConfig.type) {
					const type = listItemResponse[listConfig.type];
					listType.push(type);
				}

				const archived = listItemResponse[listConfig.archived];
				listArchived.push(archived ? true : false);

				const blocked = listItemResponse[listConfig.blocked];
				listBlocked.push(blocked ? true : false);
			});
		} else {
			Object.entries(list).forEach((listItemResponse: any) => {
				const key = listItemResponse[1];
				const value = listItemResponse[0];
				listAsKeys.push(key);
				listAsObject[key] = value;
			});
		}

		return {
			keys: listAsKeys,
			object: listAsObject,
			type: listType,
			archived: listArchived,
			blocked: listBlocked
		};
	}
}
