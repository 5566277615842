import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";

import { ApiService } from "./api/api.service";
import { AuthApiService } from "./api/auth-api.service";
import { ChecklistApiService } from "./api/checklist-api.service";
import { ContactApiService } from "./api/contact-api.service";
import { ContactCommunicationApiService } from "./api/contact-communication-api.service";
import { DefaultDeliveryPackagesService } from "./api/default-delivery-packages-api.service";
import { DisplayApiService } from "./api/display-api.service";
import { EmployeeApiService } from "./api/employee-api.service";
import { HourtypeApiService } from "./api/houtype-api.service";
import { LookupApiService } from "./api/lookup-api.service";
import { PictureApiService } from "./api/picture-api.service";
import { ProviderDataApiService } from "./api/provider-data-api.service";
import { ServiceDeskApiService } from "./api/service-desk-api.service";
import { SettingApiService } from "./api/setting-api.service";
import { TimesheetApiService } from "./api/timesheet-api.service";
import { UtilApiService } from "./api/util-api.service";
import { VehicleApiService } from "./api/vehicle-api.service";
import { VehicleOptionApiService } from "./api/vehicle-option-api.service";
import { VehicleRoleApiService } from "./api/vehicle-role-api.service";
import { VehicleRoleLineApiService } from "./api/vehicle-role-line-api.service";
import { VehicleTyreApiService } from "./api/vehicle-tyre-api.service";
import { WorkorderApiService } from "./api/workorder-api.service";
import { AccessGuard } from "./guards/access.guard";
import { AuthenticationGuard } from "./guards/authentication.guard";
import { AuthorizationGuard } from "./guards/authorization.guard";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { OfflineInterceptor } from "./interceptors/offline.interceptor";
import { AnalyticsService } from "./services/analytics.service";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/dataService.service";
import { EnvironmentService } from "./services/environment.service";
import { FormService } from "./services/form.service";
import { LicensePlateService } from "./services/license-plate.service";
import { ListService } from "./services/list.service";
import { LoadingService } from "./services/loading.service";
import { ModelService } from "./services/model.service";
import { StorageService } from "./services/storage.service";
import { ToastService } from "./services/toast.service";

@NgModule({
	providers: [
		AnalyticsService,
		ApiService,
		AuthApiService,
		AuthenticationGuard,
		AuthorizationGuard,
		AccessGuard,
		AuthService,
		ContactApiService,
		LookupApiService,
		DisplayApiService,
		ContactCommunicationApiService,
		EmployeeApiService,
		EnvironmentService,
		FormService,
		LicensePlateService,
		ListService,
		LoadingService,
		ModelService,
		PictureApiService,
		ProviderDataApiService,
		ServiceDeskApiService,
		SettingApiService,
		DefaultDeliveryPackagesService,
		StorageService,
		ToastService,
		UtilApiService,
		VehicleApiService,
		VehicleOptionApiService,
		VehicleRoleLineApiService,
		VehicleRoleApiService,
		TimesheetApiService,
		WorkorderApiService,
		ChecklistApiService,
		VehicleTyreApiService,
		HourtypeApiService,
		DataService,
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: OfflineInterceptor
		},
		{ multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor }
	],
	imports: [CommonModule]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) throw new Error("CoreModule is already loaded. Import it in the AppModule only");
	}
}
