import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { EmployeeField, EmployeeFilterType, EmployeeOrderType, EmployeeSortType, IEmployeeQuery } from "src/app/core/models/employee.model";

import { StorageService } from "../../core/services/storage.service";

@Injectable()
export class EmployeeQueryService {
	queryChanges$ = new Subject<IEmployeeQuery>();
	private initialQuery: IEmployeeQuery = {
		filter: EmployeeFilterType.All,
		page: 1,
		order: EmployeeOrderType.Ascending,
		search: "",
		sort: EmployeeSortType.isMechanic
	};
	private query: IEmployeeQuery;

	constructor(private storageService: StorageService) {}

	getFilter(): string {
		const filter = [
			"is_archived[eq]:0,(is_helpdesk_account[eq]:0,-is_helpdesk_account[eq]:null)"
		];

		if (this.query.search.length > 0) filter.push(this.getSearch());

		return filter.join(",");
	}

	getSort(): string {
		const order = this.query.order === EmployeeOrderType.Ascending ? "" : "-";
		if (this.query.sort === EmployeeSortType.Fullname) return `${order}${EmployeeField.Fullname}`;

		if (this.query.sort === EmployeeSortType.isMechanic) return `${order}${EmployeeField.IsMechanic}`;
		else return `${order}${EmployeeField.NumberInPlanning}`;
	}

	getSearch(): string {
		const filterSearch = [];
		if (this.query.search.length > 0) filterSearch.push(`(fullname[ilike]:"${this.query.search}")`);

		return filterSearch.join(",");
	}

	async init() {
		const query = await this.storageService.get("settings.employee.query");
		if (query) {
			this.query = {
				filter: this.initialQuery.filter,
				page: this.initialQuery.page,
				order: this.initialQuery.order,
				search: this.initialQuery.search,
				sort: this.initialQuery.sort
			};
			this.queryChanges$.next(this.query);
		} else this.setQuery(this.initialQuery);
	}

	setQuery(query: IEmployeeQuery) {
		this.storageService.set("settings.employee.query", {
			filter: query.filter,
			order: query.order,
			sort: query.sort,
			search: query.search
		});
		this.query = query;
		this.queryChanges$.next(query);
	}
}
