import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { IAddchecklistPictureParams, IAddOrderPictureParams, IAddVehiclePictureParams } from "../models/picture.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
	providedIn: "root"
})
export class PictureApiService {
	endpoint: string;
	constructor(private domSanitizer: DomSanitizer, private environmentService: EnvironmentService, private httpClient: HttpClient) {}

	setEndpoint(endpoint: string) {
		this.endpoint = endpoint;
	}

	addVehiclePicture(addVehiclePictureParams: IAddVehiclePictureParams) {
		return this.httpClient.post(`${this.environmentService.apiUrl}/picture`, addVehiclePictureParams);
	}

	addOrderPicture(addOrderPictureParams: IAddOrderPictureParams) {
		return this.httpClient.post(`${this.environmentService.apiUrl}/orderpicture`, addOrderPictureParams);
	}

	addChecklistPicture(addChecklistPictureParams: IAddchecklistPictureParams) {
		return this.httpClient.post(`${this.environmentService.apiUrl}/checklistpicture`, addChecklistPictureParams);
	}

	getPicture(id: string, width?: string, height?: string): Observable<any> {
		let httpParams = new HttpParams();
		if (width && height) {
			httpParams = httpParams.set("w", width);
			httpParams = httpParams.set("h", height);
		} else httpParams = httpParams.set("source", "true");

		return this.httpClient.get(`${this.environmentService.apiUrl}/${this.endpoint}/${id}`, { params: httpParams, responseType: "blob" }).pipe(
			map((blob: Blob) => {
				if (blob) return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
			})
		);
	}

	reorderPictures(vehiclePictureIds: string[]): Observable<any> {
		return this.httpClient.put(`${this.environmentService.apiUrl}/${this.endpoint}`, { ordered: vehiclePictureIds });
	}

	removeVehiclePicture(pictureId: string): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("pictureid", pictureId);

		return this.httpClient.delete(`${this.environmentService.apiUrl}/picture`, { params: httpParams });
	}

	removeOrderPicture(pictureId: string): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("pictureid", pictureId);

		return this.httpClient.delete(`${this.environmentService.apiUrl}/orderpicture`, { params: httpParams });
	}

	removeChecklistPicture(pictureId: string): Observable<any> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("pictureid", pictureId);

		return this.httpClient.delete(`${this.environmentService.apiUrl}/checklistpicture`, { params: httpParams });
	}

	rotatePicture(pictureId: string, rotation: number) {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("degrees", rotation + "");

		return this.httpClient.put(`${this.environmentService.apiUrl}/${this.endpoint}/${pictureId}/rotate`, null, { params: httpParams });
	}
}
