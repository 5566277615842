export enum WorkorderField {
	OrderId = "order_id",
	CreatedBy = "created_by",
	OwnerId = "owner_id",
	IsPasserBy = "is_passer_by",
	OrderNumber = "order_number",
	Description = "description",
	OrderDate = "order_date",
	PlanningStartDate = "planning_start_date",
	PlanningReadyDate = "planning_ready_date",
	VehicleId = "vehicle_id",
	ContactId = "contact_id",
	EmployeeId = "employee_id",
	EmployeeIdAssignedTo = "employee_id_assigned_to",
	IsVehicleHere = "is_vehicle_here",
	// OrdersToEmployees = 'orders_to_employees',
	EmployeeIdProcessedBy = "employee_id_processed_by",
	IsUnknownVehicle = "is_unknown_vehicle",
	Milometer = "milometer",
	MilometerType = "milometer_type",
	OrderDescriptionId = "order_description_id",
	Address = "address",
	ReplacementTransport = "replacement_transport",
	ReplacementtType = "replacement_type",
	IsCheck = "is_check",
	IsWash = "is_wash",
	IsVacuuming = "is_vacuuming",
	ContactFullName = "contact_fullname",
	ContactsVDisplayName = "r_contacts_v_display_name",
	VehicleDisplayName = "r_vehicles_v_display_name",
	VehicleLicensePlate = "r_vehicles_license_plate",
	OrderLicensePlate = "license_plate",
	OrderStatusId = "order_status_id",
	RobOrderNumber = "rob_order_number",
	OrderType = "order_type",
	isCustomDescription = "is_custom_description",
	RmOrderToEmployeesEmployeeId = "rm_orders_to_employees_employee_id",
	RmTimesheetsEmployeeId = "rm_timesheets_employee_id",
	RmTimesheetsStartTime = "rm_timesheets_start_time",
	RmTimesheetsTimesheetId = "rm_timesheets_timesheet_id",
	RmTimesheetsEndTime = "rm_timesheets_end_time",
	RmTimesheetsHourTypeId = "rm_timesheets_hour_type_id",
	IsInWorkflow = "is_in_workflow",
	InvoiceDate = "invoice_date",
	IsInvoiced = "is_invoiced",
	InvoiceNumber = "invoice_number",
	IsExpired = "is_expired",
	OpenAmount = "open_amount",
	PaymentConditionId = "payment_condition_id",
	IsCollect = "is_collect",
	IsCiPrinted = "is_ci_printed",
	Notes = "notes_internal"
}

export enum WorkorderFilterType {
	All,
	Yesterday,
	Today,
	Tomorrow,
	ThisWeek,
	Date
}

export enum WorkorderOrderType {
	Ascending,
	Descending
}

export enum WorkorderSortType {
	LicensePlate,
	PlanningStartDate,
	OrderNumber
}

export interface IWorkorderDto {
	order_id?: string;
	rm_orders_to_employees_employee_id?: string[];
	[key: string]: any;
}

export interface IWorkorderQuery {
	filter: WorkorderFilterType;
	page: number;
	order: WorkorderOrderType;
	search: string;
	sort: WorkorderSortType;
	filterDate: string;
	organization: string;
}

export interface IWorkorderResponse {
	data: IWorkorderDto[];
	maxPages: number;
}

export interface IWorkordersResponse {
	currentPage: number;
	data: IWorkorderDto[];
	maxPages: number;
	nextpage: boolean;
}

export enum OrderDescriptionField {
	Color = "color",
	TextColor = "text_color",
	createdBy = "created_by",
	CreatedDate = "created_date",
	DefaultTime = "default_time",
	DisplayDefaultTime = "display_default_time",
	IsControlLastRdwAdvice = "is_control_last_rdw_advice",
	IsInternal = "is_internal",
	ModifiedBy = "modified_by",
	ModifiedDate = "modified_date",
	OrderDescription = "order_description",
	OrderDescriptionId = "order_description_id",
	OrderDescriptionType = "order_description_type",
	OrganizationId = "organization_id",
	OwnerId = "owner_id"
}

export interface IOrderDescriptionDto {
	color: string;
	text_color: string;
	created_by: string;
	created_date: string;
	default_time: string;
	display_default_time: string;
	is_control_last_rdw_advice: string;
	is_internal: string;
	modified_by: string;
	modified_date: string;
	order_description: string;
	order_description_id: string;
	order_description_type: string;
	organization_id: string;
	owner_id: string;
}

export interface IOrderDescriptionResponse {
	data: IOrderDescriptionDto[];
}
