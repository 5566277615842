import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { ContactApiService } from "src/app/core/api/contact-api.service";
import { VehicleApiService } from "src/app/core/api/vehicle-api.service";
import { VehicleRoleLineApiService } from "src/app/core/api/vehicle-role-line-api.service";
import { ContactField, IContactDto } from "src/app/core/models/contact.model";
import { VehicleRoleLineField } from "src/app/core/models/vehicle-role-line.model";
import { IVehicleDto, VehicleField } from "src/app/core/models/vehicle.model";

interface Message {
	title?: string;
	note?: string;
}
interface MessagesTitle {
	title: string;
	note: string;
}

@Component({
	selector: "af-warning-note",
	templateUrl: "./warning-note.component.html",
	styleUrls: ["./warning-note.component.scss"]
})
export class WarningNoteComponent implements OnInit {
	constructor(
		private modalController: ModalController,
		private vehicleApiService: VehicleApiService,
		private vehicleRoleLineApiService: VehicleRoleLineApiService,
		private contactApiService: ContactApiService
	) {}

	showModal = false;
	@Input() inputObject: {
		contact?: IContactDto;
		vehicle?: IVehicleDto;
		messages: Message[];
		messagesTitle: MessagesTitle;
		showModal: boolean;
	};

	vehicleFields = [VehicleField.LicensePlate, VehicleField.VehicleId, VehicleField.IsShowNoteAsWarning, VehicleField.NotesInternal];
	contactFields = [ContactField.ContactId, ContactField.VDisplayName, ContactField.IsShowNoteAsWarning, ContactField.Notes];

	async ngOnInit(): Promise<void> {}

	async showOrHideModal(inputObject?: { contact?: IContactDto; vehicle?: IVehicleDto }) {
		let showModal = false;
		const messages: Message[] = [],
			messagesTitle = {
				title: "",
				note: ""
			};

		// if contact is provided and it has vehicle_ids and no vehicle is provided
		if (!inputObject.vehicle && inputObject.contact) {
			if (inputObject.contact) inputObject.vehicle = undefined;
			if (inputObject.contact.is_show_note_as_warning === 1) showModal = true;
			messagesTitle.title = `Relatie: ${inputObject.contact.v_display_name}`;
			messagesTitle.note = inputObject.contact.is_show_note_as_warning === 1 ? inputObject.contact.notes : "";

			if (inputObject.contact.vehicle_ids.length > 0) {
				const vehicleFilter = inputObject.contact.vehicle_ids
					.slice(0, 10)
					.map((vehicleId: string) => `-vehicle_id[eq]:${vehicleId}`)
					.join(",");
				const vehicles = await this.vehicleApiService.getVehicles(1, this.vehicleFields, vehicleFilter).toPromise();
				for (const vehicle of vehicles.data) {
					if (vehicle.is_show_note_as_warning === 1) {
						messages.push({ title: vehicle.license_plate, note: vehicle.notes_internal });
						showModal = true;
					}
				}
			}
		}

		// if contact not is provided and vehicle is provided
		if (!inputObject.contact && inputObject.vehicle) {
			if (inputObject.vehicle) inputObject.contact = undefined;
			if (inputObject.vehicle.is_show_note_as_warning === 1) showModal = true;
			messagesTitle.title = `Voertuig: ${inputObject.vehicle.license_plate}`;
			messagesTitle.note = inputObject.vehicle.is_show_note_as_warning === 1 ? inputObject.vehicle.notes_internal : "";

			const vehicleRoleLines = await this.vehicleRoleLineApiService
				.getVehicleRoleLines(
					1,
					[VehicleRoleLineField.VehicleRoleLineId, VehicleRoleLineField.ContactId, VehicleRoleLineField.VehicleId],
					`-vehicle_id[eq]:${inputObject.vehicle.vehicle_id}`
				)
				.toPromise();

			const contactIds = vehicleRoleLines.data.map((vehicleRoleLine) => vehicleRoleLine.contact_id),
				contactFilter = contactIds
					.slice(0, 10)
					.map((contactId: string) => `-contact_id[eq]:${contactId}`)
					.join(",");

			if (contactIds.length > 0) {
				const contacts = await this.contactApiService.getContacts(1, this.contactFields, contactFilter).toPromise();
				for (const contact of contacts.data) {
					if (contact.is_show_note_as_warning === 1) {
						messages.push({ title: contact.v_display_name, note: contact.notes });
						this.showModal = true;
					}
				}
			}
		}

		// if contact and vehicle is provided
		if (inputObject.contact && inputObject.vehicle) {
			if (inputObject.contact) {
				if (inputObject.contact.is_show_note_as_warning === 1) {
					showModal = true;
					messagesTitle.title = `Relatie: ${inputObject.vehicle.license_plate}`;
					messagesTitle.note = inputObject.contact.is_show_note_as_warning === 1 ? inputObject.contact.notes : "";
				}
			}

			if (inputObject.vehicle) {
				if (inputObject.vehicle.is_show_note_as_warning === 1) {
					showModal = true;
					if (messagesTitle.title.length === 0 || !messagesTitle.title) {
						messagesTitle.title = `Voertuig: ${inputObject.vehicle.license_plate}`;
						messagesTitle.note = inputObject.vehicle.is_show_note_as_warning === 1 ? inputObject.vehicle.notes_internal : "";
					} else messages.push({ title: inputObject.vehicle.license_plate, note: inputObject.vehicle.notes_internal });
				}
			}
		}

		return {
			inputObject,
			messages,
			messagesTitle,
			showModal
		};
	}

	dismiss() {
		this.modalController.dismiss();
	}
}
