import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { AlertController, LoadingController } from "@ionic/angular";
import { PictureApiService } from "src/app/core/api/picture-api.service";
import { IAddOrderPictureResponse } from "src/app/core/models/picture.model";
import { ActionSheetService } from "src/app/shared/components/action-sheet/action-sheet.service";
import { PicturesAddActionSheetComponent } from "src/app/shared/components/pictures-add-actionsheet/pictures-add-actionsheet.component";

import { ToastService } from "../../core/services/toast.service";

@Injectable()
export class OrderLinePictureService {
    constructor(
        private alertController: AlertController,
        private pictureApiService: PictureApiService,
        private toastService: ToastService,
        private actionSheetService: ActionSheetService,
        private loadingController: LoadingController,
		private router: Router
    ) {}

    addOrderPicture(orderId: string, orderLineId: string, orderPictureIds?: string[], componentName?: string): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const actionPhotoAdd = this.actionSheetService.create(PicturesAddActionSheetComponent, {module: "WorkorderLinesPictureAdd", orderPictureIds, componentName});
                actionPhotoAdd.subscribe(async (value: string) => {
                    switch (value) {
                        case "makePhoto":
                            const loadingMakePhoto = await this.loadingController.create({
                                spinner: "circles",
                                message: "Uploaden...",
                                translucent: true,
                                backdropDismiss: false
                            });
                            const makePhotoPictureIds = [];

                            const makePhoto = await Camera.getPhoto({
                                quality: 92,
                                saveToGallery: false,
                                source: CameraSource.Camera,
                                resultType: CameraResultType.Base64
                            });
                            if (makePhoto) {
                                const post = this.pictureApiService
                                    .addOrderPicture({
                                        content_type: "image/jpeg",
                                        order_id: orderId,
                                        order_line_id: orderLineId,
                                        order_picture_media: makePhoto.base64String
                                    })
                                    .toPromise();
                                await loadingMakePhoto.present();
                                await post.then(
                                    (addOrderPictureResponse: IAddOrderPictureResponse) => {
                                        makePhotoPictureIds.push(addOrderPictureResponse.data[0].order_picture_id);
                                        this.toastService.presentSuccessToast("Foto toegevoegd");
                                        resolve(makePhotoPictureIds);
                                    },
                                    (error) => {
                                        this.toastService.presentFailureToast(error.error.errorMessage);
                                        reject(error);
                                    }
                                );
                                await loadingMakePhoto.dismiss();
                            }

                            break;

                        case "selectPhoto":
                            const selectPhotoPictureIds = [];
                            const loadingSelectPhoto = await this.loadingController.create({
                                spinner: "circles",
                                message: "Uploaden...",
                                translucent: true,
                                backdropDismiss: false
                            });

                            const selectPhoto = await Camera.pickImages({
                                quality: 92
                            });
                            let count = 0;
                            await loadingSelectPhoto.present();
                            for (const photo of selectPhoto.photos) {
                                const post = this.pictureApiService
                                    .addOrderPicture({
                                        content_type: "image/jpeg",
                                        order_id: orderId,
                                        order_line_id: orderLineId,
                                        order_picture_media: await this.getBase64ImageFromBlobURL(photo.webPath)
                                    })
                                    .toPromise();

                                await post.then(
                                    (addOrderPictureResponse: IAddOrderPictureResponse) => {
                                        count += 1;
                                        selectPhotoPictureIds.push(addOrderPictureResponse.data[0].order_picture_id);
                                    },
                                    (error) => {
                                        this.toastService.presentFailureToast(error.error.errorMessage);
                                        reject(error);
                                    }
                                );
                            }
                            resolve(selectPhotoPictureIds);
                            await loadingSelectPhoto.dismiss();
                            this.toastService.presentSuccessToast(`Foto(s) toegevoegd: ${count}/${selectPhoto.photos.length}`);

                            break;

                        case "orderlinePhotos":
                            this.router.navigate([`/workorders/${orderId}/edit/orderlines/${orderLineId}/pictures`]);
                            break;

                        case "orderlinePhotosreorder":
                            this.router.navigate([`/workorders/${orderId}/edit/orderlines/${orderLineId}/pictures/reorder`]);
                            break;
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    getBase64ImageFromBlobURL(img: string): Promise<string> {
        return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result.toString().split(",")[1]);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", img);
            xhr.responseType = "blob";
            xhr.send();
        });
    }

    presentRemoveOrderPictureAlert(): Promise<boolean> {
        return new Promise(async (resolve) => {
            const alert = await this.alertController.create({
                header: "Foto verwijderen",
                message: "Weet je zeker dat je deze foto wilt verwijderen?",
                buttons: [
                    {
                        text: "Nee",
                        role: "cancel",
                        handler: () => {
                            resolve(false);
                        }
                    },
                    {
                        text: "Ja",
                        handler: () => {
                            resolve(true);
                        }
                    }
                ]
            });
            await alert.present();
        });
    }

    removeOrderPicture(orderPictureId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.pictureApiService.removeOrderPicture(orderPictureId).subscribe(
                () => {
                    this.toastService.presentSuccessToast("Foto verwijderd");
                    resolve(orderPictureId);
                },
                (error) => {
                    this.toastService.presentFailureToast(error.error.errorMessage);
                    reject(error);
                }
            );
        });
    }

    reorderOrderPictures(pictureIds: string[]) {
        this.pictureApiService.reorderPictures(pictureIds).subscribe(
            () => {
                this.toastService.presentSuccessToast("Foto's herordend");
            },
            (error) => {
                this.toastService.presentFailureToast(error.error.errorMessage);
            }
        );
    }
}
