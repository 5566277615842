import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import "moment/locale/nl"
import moment from "moment";

moment.locale("nl")

if (environment.production) enableProdMode();

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((error) => {
		console.log(error);
	});

defineCustomElements(window);
