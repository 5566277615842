import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IWorkorderDto } from "src/app/core/models/workorder.model";

@Injectable()
export class WorkorderDataService {
    workorder: IWorkorderDto = [];

    private dataSource = new BehaviorSubject(this.workorder);
    public currentData = this.dataSource.asObservable();

    constructor() {}

    changeData(data: any) {
        this.dataSource.next(data);
    }
}
