import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ContactField, IContactDto, IContactResponse, IContactsResponse } from "../models/contact.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
    providedIn: "root"
})
export class ContactApiService {
    constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

    addContact(contact: IContactDto) {
        return this.httpClient.post(`${this.environmentService.apiUrl}/contact`, contact);
    }

    checkAddress(address: { countryId: string; houseNumber: string; houseNumberAdd?: string; postalCode: string }): Observable<any> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("country_id", address.countryId);
        httpParams = httpParams.set("postalcode", address.postalCode);
        if (address.houseNumber) 
            httpParams = httpParams.set("house_number", address.houseNumber);
        
        if (address.houseNumberAdd) 
            httpParams = httpParams.set("house_number_add", address.houseNumberAdd);
        

        return this.httpClient.get(`${this.environmentService.apiUrl}/contact/checkaddress`, { params: httpParams });
    }

    editContact(id: string, contact: IContactDto) {
        return this.httpClient.put(`${this.environmentService.apiUrl}/contact/${id}`, contact);
    }

    getContact(id: string, fields: string[]): Observable<IContactResponse> {
        let httpParams = new HttpParams();
        if (fields.length) 
            httpParams = httpParams.set("fields", fields.join(","));
        

        return this.httpClient.get<IContactResponse>(`${this.environmentService.apiUrl}/contact/${id}`, { params: httpParams });
    }

    getContacts(page: number, fields: ContactField[], filter?: string, sort?: string, search?: string): Observable<IContactsResponse> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set("page", page + "");
        httpParams = httpParams.set("fields", fields.join(","));
        if (filter) 
            httpParams = httpParams.set("filter", filter);
        
        if (sort) 
            httpParams = httpParams.set("sort", sort);
        
        if (search) 
            httpParams = httpParams.set("search", search);
        

        return this.httpClient.get<IContactsResponse>(`${this.environmentService.apiUrl}/contact`, { params: httpParams });
    }

    removeContact(contactId: string) {
        return this.httpClient.delete(`${this.environmentService.apiUrl}/contact/${contactId}`);
    }
}
