import { Injectable } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { NotificationDetailModalComponent } from "src/app/shared/components/notification-detail-modal/notification-detail-modal.component";

@Injectable({
	providedIn: "root"
})
export class ToastService {
	constructor(
		private toastController: ToastController,
		private modalController: ModalController
	) {}

	async dismiss() {
		try {
			await this.toastController.dismiss();
		} catch (error) {}
	}

	private async presentNotificationDetailModal(replyThreadId: string) {
		const modal = await this.modalController.create({
			component: NotificationDetailModalComponent,
			componentProps: {
				replyThreadId
			}
		});
		if (modal)
			await modal.present();
	}

	async presentSuccessToast(message: string, showAboveTabs?: boolean) {
		await this.dismiss();
		const toast = await this.toastController.create({
			color: "success",
			duration: 3000,
			message,
			cssClass: showAboveTabs ? "toastAboveTabs" : ""
		});
		await toast.present();
	}

	async presentFailureToast(message: string, showAboveTabs?: boolean) {
		await this.dismiss();
		const toast = await this.toastController.create({
			buttons: [
				{
					side: "end",
					text: "Sluiten",
					role: "cancel"
				}
			],
			color: "danger",
			message,
			cssClass: showAboveTabs ? "toastAboveTabs" : ""
		});
		await toast.present();
	}

	async presentWarningToast(message: string, showAboveTabs?: boolean) {
		await this.dismiss();
		const toast = await this.toastController.create({
			buttons: [
				{
					side: "end",
					text: "Sluiten",
					role: "cancel"
				}
			],
			color: "warning",
			duration: 3000,
			message,
			cssClass: showAboveTabs ? "toastAboveTabs" : ""
		});
		await toast.present();
	}

	async presentNotificationToast(header: string, message: string, replyThreadId: string) {
		await this.dismiss();
		const toast = await this.toastController.create({
			color: "secondary",
			duration: 3000,
			message,
			header,
			buttons: [
				{
					text: "bekijk",
					handler: () => this.presentNotificationDetailModal(replyThreadId)
				}
			]
		});
		await toast.present();
	}
}
