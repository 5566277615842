import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IAccessDto, IFingerprintResponse, ILoginParams, ILoginResponse } from "../models/auth.model";
import { EnvironmentService } from "../services/environment.service";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root"
})
export class AuthApiService {
	constructor(private apiService: ApiService, private environmentService: EnvironmentService, private httpClient: HttpClient) {}

	addFingerprint(fingerprint: string): Observable<any> {
		return this.httpClient.post<any>(`${this.environmentService.apiUrl}/fingerprint`, { fingerprintid: fingerprint });
	}

	getFingerprint(): Observable<IFingerprintResponse> {
		return this.httpClient.get<IFingerprintResponse>(`${this.environmentService.apiUrl}/fingerprint`);
	}

	getModules(): Observable<any> {
		return this.httpClient.get<any>(`${this.environmentService.apiUrl}/authenticate/modules`);
	}

	login(loginParams: ILoginParams): Observable<any> {
		let httpHeaders = new HttpHeaders();
		let httpParams = new HttpParams();
		httpParams = httpParams.set("needsAuthentication", "false");

		httpParams = httpParams.set("api_key", this.apiService.apiKey);
		if (loginParams.organization) httpParams = httpParams.set("organization_name", loginParams.organization);

		if (loginParams.fingerprint) httpParams = httpParams.set("fingerprintid", loginParams.fingerprint);
		else httpHeaders = httpHeaders.set("authorization", `Basic ${btoa(`${loginParams.username}:${loginParams.password}`)}`);
		return this.httpClient.get<any>(`${this.environmentService.apiUrl}/authenticate`, { headers: httpHeaders, params: httpParams });
	}

	logout(token?: string): Observable<any> {
		let httpHeaders = new HttpHeaders(),
			httpParams = new HttpParams();
		httpParams = httpParams.set("needsAuthentication", "false");
		if (token) httpHeaders = httpHeaders.set("token", token);
		return this.httpClient.get<any>(`${this.environmentService.apiUrl}/authenticate/logout`, { params: httpParams, headers: httpHeaders });
	}

	removeFingerprint(): Observable<any> {
		return this.httpClient.delete<any>(`${this.environmentService.apiUrl}/fingerprint`);
	}

	resetPassword(userId: string, apiUrl?: string): Observable<any> {
		return this.httpClient.post<any>(`${apiUrl ?? this.apiService.apiUrl}/authenticate/forgotpassword`, { user_id: userId });
	}

	getAccess(): Observable<IAccessDto> {
		return this.httpClient.get<any>(`${this.environmentService.apiUrl}/authenticate/access`);
	}

	refreshToken(token: string, organizationId?: string): Observable<ILoginResponse> {
		let httpParams = new HttpParams();
		let httpHeaders = new HttpHeaders();
		httpParams = httpParams.set("needsAuthentication", "false");
		if (token) {
			httpHeaders = httpHeaders.set("token", token);
			httpParams = httpParams.set("token_id", token);
		}
		if (organizationId) httpParams = httpParams.set("organization_id", organizationId);
		return this.httpClient.get<ILoginResponse>(`${this.environmentService.apiUrl}/authenticate/refreshtoken`, { params: httpParams, headers: httpHeaders });
	}
}
