import { IEmployeeDto } from "./employee.model";
import { IRepairAdviceDto } from "./repair-advice.model";
import { IWorkorderDto } from "./workorder.model";

export enum NotificationField {
	NotificationType = "notification_type",
	OwnerId = "owner_id",
	Icon = "icon",
	NotificationId = "notification_id",
	ModifiedDate = "modified_date",
	CreatedBy = "created_by",
	NotificationText = "notification_text",
	/**
	 * @deprecated will be removed
	 * NOT the same as NotificationToUserField.IsRead
	 */
	IsRead = "is_read",
	/**
	 * @deprecated will be removed
	 */
	EmployeeId = "employee_id",
	OrganizationId = "organization_id",
	MotifiedBy = "modified_by",
	RelatedRecordId = "related_record_id",
	CreatedDate = "created_date",
	/**
	 * Only available in NotificationByUser (notification/user)
	 */
	NotificationToUserId = "notification_to_user_id",
	ReplyThreadId = "reply_thread_id"
}

export enum NotificationByUserField {
	NotificationType = "notification_type",
	OwnerId = "owner_id",
	Icon = "icon",
	NotificationId = "notification_id",
	ModifiedDate = "modified_date",
	CreatedBy = "created_by",
	NotificationText = "notification_text",
	OrganizationId = "organization_id",
	MotifiedBy = "modified_by",
	RelatedRecordId = "related_record_id",
	CreatedDate = "created_date",
	/**
	 * The notification_to_user_id's linked with this notification.
	 * 
	 * Not returned as the literal field, but returned as a nested object in a "notification_to_users" array field.
	 * e.g. `{"notification_to_users": [{"notification_to_user_id": "123"}]}`
	 * Grouped with other rm_notification_to_users_* fields.
	 */
	RmNotificationToUserId = "rm_notification_to_users_notification_to_user_id",
	/**
	 * For each notification_to_user linked with this notification: returns whether the notification is read.
	 * Returned as a int boolean (0 | 1).
	 * 
	 * Not returned as the literal field, but returned as a nested object in a "notification_to_users" array field.
	 * e.g. `{"notification_to_users": [{"is_read": 1}]}`.
	 * Grouped with other rm_notification_to_users_* fields.
	 */
	RmIsRead = "rm_notification_to_users_is_read",
	/**
	 * For each notification_to_user linked with this notification: returns the user_id for that record..
	 * 
	 * Not returned as the literal field, but returned as a nested object in a "notification_to_users" array field.
	 * e.g. `{"notification_to_users": [{"user_id": "123"}]}`
	 * Grouped with other rm_notification_to_users_* fields.
	 */
	RmUserId = "rm_notification_to_users_user_id",
	/**
	 * The thread that this notification is a part of.
	 * 
	 * TODO: refactor into a separate table.
	 */
	ReplyThreadId = "reply_thread_id",
	/**
	 * Added in getNotificationByUserList as a workaround
	 */
	IsRead = "is_read",
}

export enum NotificationToUserField {
	OwnerId = "owner_id",
	SendAt = "send_at",
	NotificationId = "notification_id",
	ModifiedDate = "modified_date",
	SendBy = "send_by",
	CreatedBy = "created_by",
	NotificationToUserId = "notification_to_user_id",
	/**
	 * Whether the notification is read. Returned as a int boolean (0 | 1).
	 */
	IsRead = "is_read",
	/**
	 * How many times the send should be retried
	 */
	SendRetry = "send_retry",
	/**
	 * If filled, notification needs to be sent on/before
	 */
	SendAfter = "send_after",
	UserId = "user_id",
	SendException = "send_exception",
	OrganizationId = "organization_id",
	ModifiedBy = "modified_by",
	CreatedDate = "created_date",
}

export enum NotificationType {
	System = 1,
	/**
	 * Custom: Created by the user
	 */
	Custom = 2,
	Helpdesk = 3,
	Orders = 4,
	RepairAdvices = 5,
	Users = 6
}

export enum PushPermissionStatus {
	Prompt = "prompt",
	PromptWithRationale = "prompt-with-rationale",
	Granted = "granted",
	Denied = "denied"
}

export enum NotificationFilterType {
	System = "SYSTEM",
	Custom = "CUSTOM",
	Helpdesk = "HELPDESK",
	Orders = "ORDERS",
	RepairAdvices = "REPAIR_ADVICES",
	Users = "USERS",
	All = "ALL"
}

export enum NotificationFilterReadType {
	All = "ALL",
	Read = "READ",
	Unread = "UNREAD"
}

export enum NotificationOrderType {
	Ascending,
	Descending
}

export enum NotificationSortType {
	CreatedDate = "created_date"
}

export interface INotificationAddRequest {
	[NotificationField.NotificationType]: NotificationType;
	[NotificationField.Icon]: string;
	[NotificationField.NotificationText]: string;
	[NotificationField.RelatedRecordId]?: string;
	[NotificationField.ReplyThreadId]: string
}

export interface INotificationToUserAddRequest {
	[NotificationToUserField.NotificationId]: string;
	[NotificationToUserField.UserId]: string;
}

export interface INotificationToUserUpdateRequest {
	[NotificationToUserField.IsRead]: number;
}

export interface ISendPushRequest {
	// Required for graphql-request
	[key: string]: string | any;
	data: {
		badge?: number;
		body?: string;
		/**
		 * Metadata, can only be a dict of strings keyed by strings!!
		 */
		data?: {[key: string]: string};
		imageUrl?: string;
		/**
		 * ID of the sender employee
		 */
		originId: string;
		originType: "employee";
		sound?: string;
		subtitle: string;
		threadId?: string;
		title: string;
		token: string;
	};
}

export type INotificationDto = {
	[key in NotificationField]: any;
};

export type INotificationByUserDto = {
	[key in NotificationByUserField]: any;
} & {
	notification_to_users: {
		notification_to_user_id: string;
		user_id: string;
		is_read: 0 | 1;
	}[];
};

export interface INotificationMeta {
	notification_id: string
	sender?: IEmployeeDto
	order?: IWorkorderDto
	repairAdvice?: IRepairAdviceDto
}

export type INotificationByUserWithMetaDto = INotificationByUserDto & {
	meta: INotificationMeta
};

export type INotificationToUserDto = {
	[key in NotificationToUserField]: any;
};
export interface INotificationListResponse {
	currentPage: number;
	data: INotificationDto[];
	maxPages: number;
}

export interface INotificationQuery {
	filter: NotificationFilterType;
	page: number;
	order: NotificationOrderType;
	search: string;
	sort: NotificationSortType;
	filterRead: NotificationFilterReadType;
	organization: string;
}

export interface INotificationByUserListResponse {
	currentPage: number;
	data: INotificationByUserDto[];
	maxPages: number;
}

export interface INotificationToUserListResponse {
	currentPage: number;
	data: INotificationToUserDto[];
	maxPages: number;
}

export interface ISendPushResponse {
	sendPush: ISendPushResponseError | ISendPushResponseSuccess;
}

export interface ISendPushResponseError {
	__typename: "Error";
	code: string;
	i18n: {
		params: string[];
		key: string;
	};
}

export interface ISendPushResponseSuccess {
	__typename: "SendPushSuccess";
	messageId: string;
}
