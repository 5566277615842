import { Component, Input } from "@angular/core";
import { ActionSheetService } from "src/app/shared/components/action-sheet/action-sheet.service";


@Component({
	selector: "af-pictures-add-actionsheet",
	templateUrl: "./pictures-add-actionsheet.component.html"
})
export class PicturesAddActionSheetComponent {
	@Input() module: string;
	@Input() orderPictureIds: string[];
	@Input() componentName: string;
	@Input() pictureId: string;

	constructor(private actionSheetService: ActionSheetService) {}

	dismiss(selection: string) {
		this.actionSheetService.dismiss(selection);
	}
}
