<af-action-sheet header="Foto's orderregel" *ngIf="module === 'WorkorderLinesPictureAdd'">
	<ion-list>
		<ion-item (click)="dismiss('makePhoto')">Foto maken</ion-item>
		<ion-item (click)="dismiss('selectPhoto')">Foto's selecteren</ion-item>
		<ion-item (click)="dismiss('orderlinePhotos')" *ngIf="this.orderPictureIds?.length > 0 && !this.componentName">Orderregel foto's</ion-item>
		<ion-item (click)="dismiss('orderlinePhotosreorder')" *ngIf="this.orderPictureIds?.length > 0 && !this.componentName" lines="none"
			>Orderregel foto's herordenen</ion-item
		>
	</ion-list>
</af-action-sheet>

<af-action-sheet header="Foto's voertuig" *ngIf="module === 'actionSheetVehiclePictureAdd'">
	<ion-list>
		<ion-item (click)="dismiss('makePhoto')">Foto maken</ion-item>
		<ion-item (click)="dismiss('selectPhoto')">Foto's selecteren</ion-item>
		<ion-item (click)="dismiss('vehiclePhotos')" *ngIf="pictureId">Voertuig foto's</ion-item>
		<ion-item (click)="dismiss('vehiclePhotosreorder')" *ngIf="pictureId" lines="none">Voertuig foto's herordenen</ion-item>
	</ion-list>
</af-action-sheet>
