import { Injectable } from "@angular/core";
import { EmployeeField } from "../../core/models/employee.model";
import { EmployeeApiService } from "../api/employee-api.service";
import { Platform } from "@ionic/angular";
import "@capacitor-community/firebase-analytics";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { Device } from "@capacitor/device";

import { EnvironmentService } from "./environment.service";

@Injectable({
	providedIn: "root"
})
export class AnalyticsService {
	screenName: string;

	constructor(private employeeApi: EmployeeApiService, private environmentService: EnvironmentService, private platform: Platform) {}

	// Set app instance id and set employee id as user id in google Analytics
	async initUserId() {
		Device.getInfo().then((info) => {
			if (info.platform !== "web") {
				this.employeeApi.getActiveEmployee([EmployeeField.EmployeeId, EmployeeField.OrganizationId]).subscribe((res) => {
					FirebaseAnalytics.getAppInstanceId();
					FirebaseAnalytics.setUserId({ userId: `${res.data[0].employee_id}` });
					FirebaseAnalytics.setUserProperty({
						name: "organization",
						value: `${res.data[0].organization_id}`
					});
					FirebaseAnalytics.setUserProperty({
						name: "environment",
						value: `${this.environmentService.environment}`
					});
				});
				FirebaseAnalytics.setCollectionEnabled({
					enabled: true
				});
			}
		});
	}

	// Get the router url and add it as screenname to googleAnalytics
	async getScreenRouter(url: string) {
		Device.getInfo().then((info) => {
			if (info.platform !== "web") FirebaseAnalytics.setScreenName({ screenName: url, nameOverride: url });
		});
	}

	// Get the router url and add it as screenname to googleAnalytics
	async RoutingEvent(url: string) {
		const regexUUID = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/gi;
		const pageName = url.replace(regexUUID, "detail");

		Device.getInfo().then((info) => {
			if (info.platform !== "web") {
				FirebaseAnalytics.logEvent({
					name: "page_view_event",
					params: {
						name: pageName
					}
				});
			}
		});
	}
}
