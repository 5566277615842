import { Injectable } from "@angular/core";
import { keys, groupBy } from "lodash-es"
import { forkJoin } from "rxjs";

import {
    INotificationMeta
} from "../models/notification.model"
import { EmployeeApiService } from "./employee-api.service";
import { IEmployeeDto, EmployeeField } from "../models/employee.model";

interface NotificationPartial {
	notification_id: string
	created_by: string
}

@Injectable({
    providedIn: "root"
})
export class NotificationMetaService {
    private employeeFields: EmployeeField[] = [
		EmployeeField.Emailaddress,
		EmployeeField.EmployeeId,
		EmployeeField.Fullname,
		EmployeeField.Firstname,
		EmployeeField.Middlename,
		EmployeeField.Lastname,
		EmployeeField.OrganizationId,
		EmployeeField.SvyUserId
	];

    constructor(
        private employeeApiService: EmployeeApiService
    ) {}

    /**
     * Batch-fetch meta for a list of notifications.
     */
    async fetchMetaForNotification(notifications: NotificationPartial[]): Promise<INotificationMeta[]> {
		if (!notifications.length) return [];

		// retrieve unique created_by id's
		const employeeIds = keys(groupBy(notifications, "created_by"));

		const employeeObservableMap = employeeIds.map((employeeId) => this.employeeApiService.getEmployee(employeeId, this.employeeFields));

		const employees = await new Promise<IEmployeeDto[]>((resolve) =>
			forkJoin(employeeObservableMap).subscribe((responses) => {
				resolve(responses.flatMap((response) => response.data));
			})
		);
		return notifications.map((notification) => {
			const result: INotificationMeta = {
				notification_id: notification.notification_id,
				sender: employees.find((employee) => employee[EmployeeField.EmployeeId] === notification.created_by)
			}

            return result
        });
	}
}
