export enum UserField {
    UserId = "user_id",
    OwnerId = "owner_id",
    LastUserOrganizationId = "last_used_organization_id",
    AdminLevel = "admin_level",
    ComEmail = "com_email",
    NameLast = "name_last",
    UserName = "user_name"
}

export enum UserDeviceField {
    DeviceKey = "device_key",
    OwnerId = "owner_id",
    Icon = "icon",
    Description = "description",
    DeviceType = "device_type",
    DeviceUserAgent = "device_user_agent",
    ModifiedDate = "modified_date",
    CreatedBy = "created_by",
    UserId = "user_id",
    OrganizationId = "organization_id",
    UserDeviceId = "user_device_id",
    ModifiedBy = "modified_by",
    CreatedDate = "created_date",
    PushToken = "push_token"
}

export enum DeviceType {
    Web = 1,
    Android = 2,
    IOS = 3,
}

export interface User {
    user_id?: string;
    owner_id?: string;
    last_used_organization_id?: string;
}

export interface UserDevice {
    [UserDeviceField.Description]: string
    [UserDeviceField.DeviceKey]: string
    [UserDeviceField.DeviceType]: DeviceType
    [UserDeviceField.DeviceUserAgent]: string
    [UserDeviceField.UserId]: string
    [UserDeviceField.UserDeviceId]: string
    [UserDeviceField.PushToken]: string
}

export interface IUserDeviceAddRequest {
    [UserDeviceField.Description]: string
    [UserDeviceField.DeviceKey]: string
    [UserDeviceField.DeviceType]: DeviceType
    [UserDeviceField.DeviceUserAgent]: string
    [UserDeviceField.UserId]: string
    [UserDeviceField.PushToken]: string
}

export type IUserDeviceDto = {
	[key in UserDeviceField]: any;
}

export interface IUserRepsonse {
    data: User[];
}

export interface IUsersResponse {
    currentPage: number;
    data: User[];
    maxPages: number;
}

export interface IUserDeviceResponse {
    currentPage: number;
    data: IUserDeviceDto[];
    maxPages: number;
    nextpage: boolean;
    count: number;
}

interface UserAPIRequired {
    admin_level: string;
    com_email: string;
    NameLast: string;
    user_name: string;
}

export type IUserDto = User;
export type IUserApi = UserAPIRequired & Partial<User>;
