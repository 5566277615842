import { Component } from "@angular/core";
import { NotificationApiService } from "src/app/core/api/notification-api.service"

@Component({
	selector: "af-notification-badge",
	templateUrl: "./notification-badge.component.html",
	styleUrls: ["./notification-badge.component.scss"]
})
export class NotificationBadgeComponent {
	constructor(
		public notificationApiService: NotificationApiService
	){}
}
