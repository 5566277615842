import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonContent, ModalController } from "@ionic/angular";
import { size, get, first, uniq } from "lodash-es";

import { NotificationApiService } from "src/app/core/api/notification-api.service";
import { INotificationDto, INotificationMeta, NotificationField, NotificationToUserField } from "src/app/core/models/notification.model";
import { NotificationMetaService } from "src/app/core/api/notification-meta.service";
import { AuthService } from "src/app/core/services/auth.service";
import { EmployeeField } from "src/app/core/models/employee.model";
import { EmployeeApiService } from "src/app/core/api/employee-api.service";
import { forkJoin } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "src/app/core/services/toast.service";
import { NotificationAddService } from "src/app/core/api/notification-add.service";

type NotificationWithMeta = INotificationDto & {
	meta: INotificationMeta;
};

interface ReceiverEmployee {
	v_display_name?: string;
	organization_id?: string;
	svy_user_id?: string;
	employee_id?: string;
	is_read: string;
}

@Component({
	selector: "af-notification-detail-modal",
	templateUrl: "./notification-detail-modal.component.html",
	styleUrls: ["./notification-detail-modal.component.scss"]
})
export class NotificationDetailModalComponent implements OnInit {
	replyForm: FormGroup;
	isSubmitting = false;
	isLoading = false;

	@Input() replyThreadId: string;

	@ViewChild(IonContent) content: IonContent;

	threadNotifications: NotificationWithMeta[];
	receiverEmployees: ReceiverEmployee[] = [];

	/**
	 * Used to communicate to notification-list that the notification has been automatically marked as read
	 */
	isMarkedAsRead = false;

	private notificationFields: NotificationField[] = [
		NotificationField.NotificationId,
		NotificationField.CreatedBy,
		NotificationField.NotificationText,
		NotificationField.CreatedDate,
		NotificationField.ReplyThreadId
	];
	private notificationToUserFields: NotificationToUserField[] = [
		NotificationToUserField.IsRead,
		NotificationToUserField.NotificationToUserId,
		NotificationToUserField.UserId
	];
	private employeeFields: EmployeeField[] = [
		EmployeeField.Emailaddress,
		EmployeeField.EmployeeId,
		EmployeeField.Fullname,
		EmployeeField.Firstname,
		EmployeeField.Middlename,
		EmployeeField.Lastname,
		EmployeeField.OrganizationId,
		EmployeeField.SvyUserId
	];

	constructor(
		private modalController: ModalController,
		private authService: AuthService,
		private notificationApiService: NotificationApiService,
		private notificationAddService: NotificationAddService,
		private notificationMetaService: NotificationMetaService,
		private employeeApiService: EmployeeApiService,
		private toastService: ToastService
	) {}

	async ngOnInit() {
		this.fetchNotificationsByThread(this.replyThreadId);

		this.replyForm = new FormGroup({
			notification_body: new FormControl("", Validators.required)
		});
	}

	dismiss(shouldRefresh?: boolean) {
		this.modalController.dismiss({ shouldRefresh, isMarkedAsRead: this.isMarkedAsRead });
	}

	async fetchNotificationsByThread(replyThreadId: string) {
		const userId = await this.authService.getUserId();

		await this.notificationApiService
			.getNotificationList(1, this.notificationFields, `reply_thread_id[eq]:"${replyThreadId}"`, "created_date")
			.then(async (response) => {
				const notificationResponseData = response.data;
				// this.threadNotifications = notificationResponseData

				// Use the latest notification to decide the thread receivers and the related record
				const lastNotification = first(notificationResponseData);
				if (lastNotification.notification_id) {
					this.notificationApiService
						.getNotificationToUserList(1, this.notificationToUserFields, `notification_id[eq]:${lastNotification.notification_id}`)
						.subscribe((notificationToUserResult) => {
							// Fetch all the receivers
							const receiverUserIds = notificationToUserResult.data.map((notificationToUser) => notificationToUser.user_id) as string[];
							const receiverObservable = receiverUserIds.map((employeeUserId) =>
								this.employeeApiService.getEmployees(1, this.employeeFields, `svy_user_id[eq]:${employeeUserId}`)
							);

							forkJoin(receiverObservable).subscribe((responseMap) => {
								this.receiverEmployees = responseMap
									.map((employeeResponse) => employeeResponse.data[0])
									.map((receiver) => ({
										...receiver,
										// Append the notificationToUser.is_read state to employee
										is_read: get(
											notificationToUserResult.data.find((nto) => nto.user_id === receiver[EmployeeField.SvyUserId]),
											"is_read"
										)
									}));
							});
						});
				}

				// Fetch all unread notificationsToUser for the logged in user and mark them as read
				if (size(notificationResponseData) > 0) {
					this.notificationApiService
						.getNotificationToUserList(
							1,
							this.notificationToUserFields,
							`notification_id[in]:${notificationResponseData.map((data) => data.notification_id).join(";")},user_id[eq]:${userId},is_read[eq]:0`
						)
						.subscribe((notificationToUserResult) => {
							const unreadNotificationToUserIds = notificationToUserResult.data.map((data) => data.notification_to_user_id);
							if (size(unreadNotificationToUserIds) > 0) {
								this.notificationApiService
									.batchUpdateNotificationToUserIsRead(userId, unreadNotificationToUserIds, true)
									.then(() => (this.isMarkedAsRead = true));
							}
						});
				}

				// Await this
				await this.notificationMetaService.fetchMetaForNotification(notificationResponseData).then((notificationMeta) => {
					const result: NotificationWithMeta[] = notificationResponseData.map((notification) => ({
						...notification,
						meta: notificationMeta.find((meta) => meta.notification_id === notification.notification_id)
					}));

					this.threadNotifications = result;
					return;
				});
			});
	}

	async onSendReply() {
		if (this.replyForm.invalid) return;
		this.isSubmitting = true;

		const userId = await this.authService.getUserId();

		const selectedEmployees = this.receiverEmployees.map((eployee) => eployee[EmployeeField.SvyUserId]);
		const notificationText = this.replyForm.value.notification_body;
		// Always append self to receivers, or the user won't be able to see their sent notifications
		const notificationReceivers = uniq([...selectedEmployees, userId]);

		try {
			await this.notificationAddService.sendNotifications(notificationReceivers, notificationText, this.replyThreadId);

			// Refresh notification thread
			await this.fetchNotificationsByThread(this.replyThreadId);
		} catch (e) {
			this.toastService.presentFailureToast("Er ging iets mis bij het verzenden van het bericht");
			console.error(`sendNotifications: ${e}`);

			return;
		} finally {
			this.isSubmitting = false;
		}

		// Only scroll to bottom once fetchNotificationsByThread is done loading
		// Timeout is somehow needed or it refuses to scroll
		setTimeout(() => {
			this.replyForm.reset();
			this.scrollToBottom();
		}, 500);
	}

	scrollToBottom() {
		this.content.scrollToBottom(300);
	}
}
