<af-action-sheet header="{{scannedBarcode}}" action="{{action}}">
  
    <ion-list *ngIf="action === 'aanmaken'">
        <ion-item (click)="createArticleToWorkOrderLines()" button="true"> Aanmaken & Openen </ion-item>
        <ion-item (click)="createArticleToWorkOrderLinesAndContinue()" button="true" lines="none"> Aanmaken & Doorgaan </ion-item>
    </ion-list>

    <ion-list *ngIf="action === 'toevoegen'">
        <ion-item (click)="addArticleToWorkOrderLines()" button="true"> Toevoegen & Openen </ion-item>
        <ion-item (click)="addArticleToWorkOrderLinesAndContinue()" button="true" lines="none"> Toevoegen & Doorgaan </ion-item>
    </ion-list>
</af-action-sheet>
