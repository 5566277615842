import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

import { StorageService } from "src/app/core/services/storage.service";

@Component({
	selector: "af-avatar",
	templateUrl: "./avatar.component.html",
	styleUrls: ["./avatar.component.scss"]
})
export class AvatarComponent implements OnInit {
	@Input() alt = "";

	@Input() height: string;
	@Input() employeeId: string;
	@Input() width: string;
	avatar: SafeUrl;

	constructor(private domSanitizer: DomSanitizer, private storageService: StorageService) {}

	async ngOnInit() {
		const avatars: [{ employee_id: string; avatar: string; employee_fullname: string }] = await this.storageService.get("employeeAvatars");
		if (avatars) {
			if (avatars.find((a) => a.employee_id === this.employeeId)?.avatar) {
				const img = avatars.find((a) => a.employee_id === this.employeeId)?.avatar;
				this.avatar = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + img);
			}
		}
	}
}
