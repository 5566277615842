import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { INotificationDto } from "../models/notification.model";

@Injectable()
export class NotificationDataService {
    private dataSource = new BehaviorSubject(undefined);
    currentData = this.dataSource.asObservable();

    constructor() {}

    changeData(data: INotificationDto) {
        this.dataSource.next(data);
    }
}
