<ion-item
	class="item-wrapper ion-no-margin ion-no-padding"
	lines="full"
	data-test="notification-select-employee-item"
	[button]="!isSelected"
	(click)="onSelect($event)"
>
	<af-avatar class="avatar" *ngIf="employee.employee_id" [employeeId]="employee.employee_id"></af-avatar>
	<ion-label class="af-small-vertical-padding" [class]="organization.name ? 'name-label' : ''"> {{ employee.v_display_name }} </ion-label>
	<ion-label class="af-small-vertical-padding organization-label" *ngIf="organization.name && this.modules.includes('Multi-vestiging')">
		{{ organization.name }}
	</ion-label>
	<fa-icon
		*ngIf="isSelected"
		[fixedWidth]="true"
		[icon]="['fal', 'trash-alt']"
		class="trash-icon"
		color="danger"
		data-test="unlink-item"
		(click)="onDelete($event)"
	></fa-icon>
</ion-item>
