import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Device } from "@capacitor/device";
import { ModalController } from "@ionic/angular";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import packageInfo from "package.json";
import { Subject } from "rxjs";

import { ToastService } from "../../../core/services/toast.service";

@Component({
    selector: "af-update-modal",
    templateUrl: "update-modal.component.html",
    styleUrls: ["./update-modal.component.scss"]
})
export class UpdateModalComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    @Input() currentVersion: string;
    @Input() availableVersion: string;
    @Input() updateAvailability: number;
    currentDevicePlatform: string;
    goToStoreString = "Ga naar de $store";

    constructor(private modalController: ModalController, private toastService: ToastService) {}

    ngOnInit() {
        Device.getInfo().then((info) => {
            if (info.platform !== "web") {
                this.currentDevicePlatform = info.platform;
                if (this.currentDevicePlatform === "ios") 
                    this.goToStoreString = this.goToStoreString.replace("$store", "App Store");
                 else if (this.currentDevicePlatform === "android") {
                    this.currentVersion = packageInfo.version;
                    this.goToStoreString = this.goToStoreString.replace("$store", "Play Store");
                }
            }
        });
    }

    openAppStore = async () => {
        await AppUpdate.openAppStore();
    };

    ngOnDestroy() {
        this.unsubscribe$.next(undefined);
        this.unsubscribe$.complete();
    }

    dismiss() {
        this.modalController.dismiss();
    }
}
