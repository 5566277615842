import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DataService {
    data: any;

    private dataSource = new BehaviorSubject(undefined);
    currentData = this.dataSource.asObservable();

    constructor() {}

    changeData(data: any) {
        this.dataSource.next(data);
    }
}
