import { Component, Input } from "@angular/core";

import { ActionSheetService } from "./action-sheet.service";

@Component({
    selector: "af-action-sheet",
    templateUrl: "./action-sheet.component.html",
    styleUrls: ["./action-sheet.component.scss"]
})
export class ActionSheetComponent {
    @Input() cancelText = "Annuleren";
    @Input() header: string;
    @Input() action: string;
    @Input() orderId: string;

    constructor(private actionSheetService: ActionSheetService) {}

    dismiss() {
        this.actionSheetService.dismiss();

    }

    dismissAll() {
        this.actionSheetService.dismissAll();
    }
}
