import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IOrganizationResponse, IUserOrganizationResponse, OrganizationField, UserOrganizationField } from "../models/organization.model";
import { EnvironmentService } from "../services/environment.service";

@Injectable({
	providedIn: "root"
})
export class OrganizationApiService {
	constructor(private environmentService: EnvironmentService, private httpClient: HttpClient) {}

	getOrganization(id: string, fields: string[]): Observable<IOrganizationResponse> {
		let httpParams = new HttpParams();
		if (fields.length) httpParams = httpParams.set("fields", fields.join(","));

		return this.httpClient.get<IOrganizationResponse>(`${this.environmentService.apiUrl}/organization/${id}`, { params: httpParams });
	}

	getOrganizations(page: number, fields: OrganizationField[], filter?: string, sort?: string): Observable<IOrganizationResponse> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("page", page + "");
		httpParams = httpParams.set("fields", fields.join(","));
		if (filter) httpParams = httpParams.set("filter", filter);

		if (sort) httpParams = httpParams.set("sort", sort);

		return this.httpClient.get<IOrganizationResponse>(`${this.environmentService.apiUrl}/organization`, { params: httpParams });
	}

	getUserOrganization(id: string, fields: string[]): Observable<IUserOrganizationResponse> {
		let httpParams = new HttpParams();
		if (fields.length) httpParams = httpParams.set("fields", fields.join(","));

		return this.httpClient.get<IUserOrganizationResponse>(`${this.environmentService.apiUrl}/userorganization/${id}`, { params: httpParams });
	}

	getUserOrganizations(page: number, fields: UserOrganizationField[], filter?: string, sort?: string): Observable<IUserOrganizationResponse> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set("page", page + "");
		httpParams = httpParams.set("fields", fields.join(","));
		if (filter) httpParams = httpParams.set("filter", filter);

		if (sort) httpParams = httpParams.set("sort", sort);

		httpParams = httpParams.set("is_system", true);

		return this.httpClient.get<IUserOrganizationResponse>(`${this.environmentService.apiUrl}/userorganization`, { params: httpParams });
	}
}
