<ion-list class="ion-no-margin ion-no-padding" *ngIf="!openModalDocs">
	<ion-item [disabled]="isCapturing" (click)="addFeedback()" button data-test="addfeedback-button">
		<ion-label>
			<fa-icon [fixedWidth]="true" [icon]="['fal', 'bullhorn']"></fa-icon>
			Feedback
		</ion-label>
		<ion-spinner *ngIf="isCapturing" slot="end" name="lines"></ion-spinner>
	</ion-item>
	<ion-item
		*ngIf="componentName !== 'HeaderPopoverComponent'"
		button
		afLongPress
		(mouseLongPress)="setDocsPageSettingModalOpen(true)"
		(click)="getDocsPage(true)"
		data-test="gotodocspage-button"
	>
		<ion-label>
			<fa-icon [fixedWidth]="true" [icon]="['fas', 'question']"></fa-icon>
			Help / Documentatie
		</ion-label>
	</ion-item>
	<!-- NOTIFICATION FUNCTIONALITY -->
	<ion-item
		*ngIf="ionicEnvironment.notificationsEnabled && !disableNotificationButton"
		[disabled]="isCapturing"
		(click)="goToNotifications()"
		button
		data-test="navigateToNotification-button"
	>
		<ion-label>
			<fa-icon [fixedWidth]="true" [icon]="['fal', 'bell']"></fa-icon>
			Berichten
		</ion-label>
		<af-notification-badge></af-notification-badge>
		<ion-spinner *ngIf="isCapturing" slot="end" name="lines"></ion-spinner>
	</ion-item>
	<!-- NOTIFICATION FUNCTIONALITY -->
	<ion-item
		*ngIf="ionicEnvironment.notificationsEnabled && !disableNotificationButton"
		[disabled]="isCapturing"
		(click)="presentNotificationAddModal()"
		button
		data-test="presentNotifications-button"
	>
		<ion-label>
			<fa-icon [fixedWidth]="true" [icon]="['fal', 'bell-plus']"></fa-icon>
			Bericht versturen
		</ion-label>
		<ion-spinner *ngIf="isCapturing" slot="end" name="lines"></ion-spinner>
	</ion-item>
</ion-list>

<!-- open docs page -->
<ion-modal [isOpen]="openDocsPage">
	<ng-template>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-title>Help / Documentatie</ion-title>
				<ion-buttons slot="start">
					<ion-button (click)="getDocsPage(false)"><fa-icon [fixedWidth]="true" [icon]="['fal', 'times']"></fa-icon></ion-button>
				</ion-buttons>
				<ion-buttons slot="end">
					<ion-button (click)="goToPageUrl(this.docShortlinkUrl)"><fa-icon [fixedWidth]="true" [icon]="['fas', 'external-link-alt']"></fa-icon></ion-button>
					<af-header-popover-button [componentName]="this.constructor.name"></af-header-popover-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			<!-- <object [data]="url" id="frameObject" width="100%" height="100%" style="background-color: #ffffff" *ngIf="openDocsPage">
				<embed [src]="url" id="frameObject" width="100%" height="100%" style="background-color: #ffffff" *ngIf="openDocsPage" />
				Error: Embedded data could not be displayed.
			</object> -->
			<iframe
				[src]="url"
				id="docsPageIframe"
				width="100%"
				height="100%"
				style="background-color: #ffffff; border: none"
				*ngIf="openDocsPage"
				allow="clipboard-read; clipboard-write"
			></iframe>
		</ion-content>
		<ion-footer>
			<ion-grid>
				<ion-row>
					<ion-col>
						<ion-button href="tel:+31341375180" expand="block">
							<fa-icon [fixedWidth]="true" [icon]="['fas', 'phone']" class="ion-margin-end"></fa-icon>
							0341 375180
						</ion-button>
					</ion-col>
					<ion-col>
						<ion-button href="mailto:servicedesk@autoflex.nl" expand="block">
							<fa-icon [fixedWidth]="true" [icon]="['fas', 'envelope']" class="ion-margin-end"></fa-icon>
							Servicedesk
						</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-footer>
	</ng-template>
</ion-modal>

<!-- open docs settings form -->
<ion-modal [isOpen]="isDocsPageSettingsModalOpen">
	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-title>Help / Documentatie toevoegen</ion-title>
				<ion-buttons slot="start">
					<ion-button (click)="setDocsPageSettingModalOpen(false)"><fa-icon [fixedWidth]="true" [icon]="['fal', 'times']"></fa-icon></ion-button>
				</ion-buttons>
				<ion-buttons slot="end">
					<ion-button (click)="goToPageUrl(this.docShortlinkUrl)"><fa-icon [fixedWidth]="true" [icon]="['fas', 'external-link-alt']"></fa-icon></ion-button>
					<af-header-popover-button [componentName]="this.constructor.name"></af-header-popover-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			<form *ngIf="form" [formGroup]="form">
				<ion-item>
					<ion-input label="Short link ID" [value]="this.form.controls.shortLinkId.value" formControlName="shortLinkId"></ion-input>
				</ion-item>
				<ion-item>
					<ion-label>Form path</ion-label>
					<ion-textarea [value]="this.form.controls.formPath.value" formControlName="formPath"></ion-textarea>
				</ion-item>

				<ion-text
					lines="none"
					style="display: block; padding-top: 10px; color: var(--ion-color-warning)"
					*ngIf="this.formPath && this.form.controls.formPath.value !== this.formPath"
				>
					<ion-label>
						Er zit een verschil tussen de opgeslagen form_path en de huidige form_path. Door op opslaan te klikken update je de form_path met de huidige
						gegenereerde form_path. ({{ this.form.controls.formPath.value }})
					</ion-label>
				</ion-text>
			</form>
		</ion-content>
		<ion-button *ngIf="!newHelp; else addButton" [disabled]="!formValid" (click)="submit('edit')">Opslaan</ion-button>
		<ng-template #addButton>
			<ion-button [disabled]="!formValid" (click)="submit('add')">Voeg toe</ion-button>
		</ng-template>
	</ng-template>
</ion-modal>
