import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import moment from "moment";

import { INotificationMeta } from "../../../core/models/notification.model";

interface NotificationPartial {
	notification_id: string
	created_date: string
	notification_text: string
	meta: INotificationMeta
	reply_thread_id: string
}

export enum NotificationItemUsedInView {
	LIST = "list",
	DETAIL = "detail",
	PREVIEW = "preview"
}

@Component({
	selector: "af-notification-item",
	templateUrl: "./notification-item.component.html",
	styleUrls: ["./notification-item.component.scss"]
})
export class NotificationItemComponent implements OnChanges, OnDestroy {
	@Input() notification: NotificationPartial;
	@Input() clickable: boolean;
	@Input() isRead: boolean
	@Input() usedInView: NotificationItemUsedInView = NotificationItemUsedInView.LIST;
	@Output() select = new EventEmitter<{ notificationId: string }>();

	dateRefreshTimerId: ReturnType<typeof setTimeout>
	formattedDate = ""
	loggedInUserId: string

	constructor() { }

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.notification) {
			const value = changes.notification.currentValue as NotificationPartial
			if (value) {
				this.formattedDate = this.formatDate(value.created_date)
				this.startRefreshTimer(value.created_date)
			}
		}
	}

	onSelect(e: Event) {
		e.stopPropagation();

		this.select.emit({ notificationId: this.notification.notification_id })
	}

	/**
	 * Updates the formattedDate property by a set interval if the notification is relatively new.
	 */
	startRefreshTimer(dateString: string) {
		const date = moment(dateString)
		const minuteDiff = moment().diff(date, "minutes")

		if (minuteDiff > 120)
			// Notification is more than 2 hours old
			return

		// Every 5 seconds
		let interval = 5000
		if (minuteDiff > 2)
			// Every minute
			interval = 60000

		this.dateRefreshTimerId = setInterval(() => {
			this.formattedDate = this.formatDate(dateString)
		}, interval)
	}

	/**
	 * Format a datestring to a localized relative date text.
	 * 
	 * Pretty verbose but that makes it easier to customize.
	 */
	formatDate(dateString: string) {
		const date = moment(dateString)
		const now = moment()

		const monthDiff = now.diff(date, "months")
		if (monthDiff > 2)
			return date.fromNow(true)
		if (monthDiff > 1)
			return `${monthDiff} maanden`
		if (monthDiff === 1)
			return "1 maand"

		const weekDiff = now.diff(date, "weeks")
		const dayDiff = now.diff(date, "days")
		if (weekDiff > 1)
			return `${weekDiff} weken`
		if (weekDiff === 1) {
			if (dayDiff > 8)
				return `1 week ${dayDiff - 7} dagen`
			if (dayDiff === 8)
				return "1 week 1 dag"

			return "1 week"
		}

		const hourDiff = now.diff(date, "hours")
		if (dayDiff > 1)
			return `${dayDiff} dagen`
		if (dayDiff === 1) {
			if (hourDiff === 24)
				return "1 dag"

			return `1 dag ${hourDiff - 24} uur`
		}

		const minuteDiff = now.diff(date, "minutes")
		if (hourDiff > 1)
			return `${hourDiff} uur`
		if (hourDiff === 1) {
			if (minuteDiff === 60)
				return "1 uur"

			return `1 uur ${minuteDiff - 60} minuten`
		}

		const secondDiff = now.diff(date, "seconds")
		if (minuteDiff > 1)
			return `${minuteDiff} minuten`
		if (minuteDiff === 1) {
			if (secondDiff === 60)
				return "1 minuut"

			return `1 minuut ${secondDiff - 60} seconden`
		}

		return `${secondDiff} seconden`
	}

	splitByNewline(notificationText: string): string[] {
		return notificationText.split("\n")
	}

	ngOnDestroy() {
		clearInterval(this.dateRefreshTimerId);
	}
}
